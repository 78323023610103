import React from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Typography } from '@mui/material';

export const Watermark = () => {
	const watermarkStyle = {
		position: 'fixed', // Fixed position to keep it in view
		right: '10px', // Distance from the right edge of the viewport
		bottom: '10px', // Distance from the bottom edge of the viewport
		// opacity: 0.75, // Optional: make it semi-transparent
		zIndex: 1000, // Ensure it's above other content
		fontSize: '12px', // Adjust size as needed
		color: '#000', // Watermark text color
		padding: '0.5rem',
		borderRadius: '1rem',
		// backgroundColor: '#4158D0',
		background: '#fff',
		backgroundOpacity: 0.2,

		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Box shadow for 3D effect
		display: 'flex',
		alignItems: 'center',
		gap: '0.5rem',
		paddingLeft: '0.75rem',
		paddingRight: '0.75rem',
	};

	return (
		<div style={watermarkStyle}>
			<AutoAwesomeIcon
				sx={{
					color: '#FEBE10', // Cool gradient background
				}}
			/>
			<Typography variant='p' fontWeight={600} sx={{ color: '#000' }}>
				Powered by AI
			</Typography>
		</div>
	);
};
