import axios from 'axios';

export const getFoodRecycledByDateRange = async (orgID, year, yearWeek) => {
	const url = `/.netlify/functions/getFoodRecycledByDateRange?orgID=${orgID}&startDate=${year}&endDate=${yearWeek}`;

	var foodRecycled = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				foodRecycled = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return foodRecycled;
};
