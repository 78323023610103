import React, { useState } from 'react';
import FinalizeAttendees from './FinalizeComponents/FinalizeAttendees';
import FinalizeBreakfasts from './FinalizeComponents/FinalizeBreakfasts';
import FinalizeLunches from './FinalizeComponents/FinalizeLunches';
import FinalizeDinners from './FinalizeComponents/FinalizeDinners';
import { Button, FormControl, Paper, TextField, Typography, useTheme } from '@mui/material';
import { finalizeEvent } from '../../../../functions/finalizeEvent';

const FinalizeEventForm = ({
	attendees: initialAttendees,
	breakfasts: initialBreakfasts,
	lunches: initialLunches,
	dinners: initialDinners,
	eventID,
	setSubmitClicked,
	clearSelection,
	eventName,
}) => {
	const theme = useTheme();
	const [attendees, setAttendees] = useState(initialAttendees);
	const [breakfasts, setBreakfasts] = useState(initialBreakfasts);
	const [lunches, setLunches] = useState(initialLunches);
	const [dinners, setDinners] = useState(initialDinners);

	// Ensure all values are parsed as integers and calculate Total Covers dynamically
	const totalCovers =
		parseInt(attendees || 0) *
		(parseInt(breakfasts || 0) + parseInt(lunches || 0) + parseInt(dinners || 0));

	const handleSubmit = async () => {
		const finAttendees = parseInt(attendees || 0);
		const finBreakfastsServed = parseInt(breakfasts || 0);
		const finLunchesServed = parseInt(lunches || 0);
		const finDinnersServed = parseInt(dinners || 0);
		const finTotalCovers = totalCovers;

		const result = await finalizeEvent(
			eventID,
			finAttendees,
			finBreakfastsServed,
			finLunchesServed,
			finDinnersServed,
			finTotalCovers
		);

		setSubmitClicked(true);
		clearSelection();
	};

	return (
		<Paper className='column aic jcse' style={{ padding: theme.spacing(3) }}>
			<div style={{ width: '100%', marginBottom: theme.spacing(2) }}>
				<Typography variant='h5' fontWeight={600}>
					Confirm Event Details
				</Typography>
				<Typography variant='h6' style={{ marginTop: theme.spacing(1) }}>
					Event Name: {eventName}
				</Typography>
			</div>

			{/* Pass props and handlers to child components */}
			<div className='row jcse aic' style={{ margin: '1rem', width: '100%' }}>
				<FinalizeAttendees attendees={attendees} setAttendees={setAttendees} />
				<Typography variant='h4'>{` * `}</Typography>
				<Typography variant='h4'>{` ( `}</Typography>
				<FinalizeBreakfasts breakfasts={breakfasts} setBreakfasts={setBreakfasts} />
				<Typography variant='h4'>{` + `}</Typography>
				<FinalizeLunches lunches={lunches} setLunches={setLunches} />
				<Typography variant='h4'>{` + `}</Typography>
				<FinalizeDinners dinners={dinners} setDinners={setDinners} />
				<Typography variant='h4'>{` ) `}</Typography>
				<Typography variant='h4'>{` = `}</Typography>
				{/* Display dynamically calculated Total Covers */}
				<FormControl width='20%'>
					<TextField
						label='Total Covers'
						value={totalCovers}
						InputProps={{
							readOnly: true,
						}}
						variant='outlined'
						disabled // Grey out the field to indicate it is not editable
						style={{ backgroundColor: theme.palette.action.disabledBackground }} // Further style to ensure it's clearly non-interactive
					/>
				</FormControl>
			</div>
			<Button variant='contained' onClick={handleSubmit}>
				Submit Actual Details & Report
			</Button>
		</Paper>
	);
};

export default FinalizeEventForm;
