import { Button } from '@mui/material';
import React from 'react';

const NextButton = ({ step, nextStep }) => {
	return (
		<Button
			variant='contained'
			onClick={() => {
				nextStep(step);
			}}
			sx={{
				width: '10vw',
				// height: '6vh',
				backgroundColor: '#84be05',
				'&:hover': {
					backgroundColor: 'rgba(132, 190, 5, 0.9)',
				},
			}}>
			Next
		</Button>
	);
};

export default NextButton;
