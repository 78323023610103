import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const DemographicSelector = ({ demographic, setDemographic }) => {
	return (
		<div>
			{/* <label>
				Demographic M/F *
				<select value={demographic} onChange={(e) => setDemographic(e.target.value)} required>
					<option value=''>50% Male, 50% Female</option>
				</select>
			</label> */}
			<FormControl fullWidth={true}>
				<InputLabel id='demo-simple-select-label' required>
					Demographic M/F
				</InputLabel>
				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={demographic}
					label='Demographic'
					onChange={(e) => setDemographic(e.target.value)}>
					<MenuItem value={'0% Male, 100% Female'}>0% Male, 100% Female</MenuItem>
					<MenuItem value={'25% Male, 75% Female'}>25% Male, 75% Female</MenuItem>
					<MenuItem value={'50% Male, 50% Female'}>50% Male, 50% Female</MenuItem>
					<MenuItem value={'75% Male, 25% Female'}>75% Male, 25% Female</MenuItem>
					<MenuItem value={'100% Male, 0% Female'}>100% Male, 0% Female</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
};

export default DemographicSelector;
