const styles = (theme) => ({
	upcomingEventsCard: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between', // This will spread out the children to start, center, and end
		alignItems: 'center',
		width: '100%',
		height: '100%', // Make the card take full height
		borderRadius: '10px',
		backgroundColor: theme.palette.foreground.main,
		border: '1px ' + theme.palette.border.main + ' solid',
	  },
	  cardTitle: {
        display: 'flex',
        flexDirection: 'column', // Orient the text top to bottom
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Take up full container height
        width: 'auto', // Set the width as required, could be fixed or auto
        padding: '0 1rem', // Add padding as needed
        backgroundColor: theme.palette.paleGreen.main,
    },
	progressCircle: {
		margin: '1rem',
		height: '10rem',
		width: '10rem',
		background: theme.palette.paleGreen,
	},
	linearProgressBackground: {
		width: '90%', 
		height: '20px', 
		backgroundColor: theme.palette.grey[200], 
		borderRadius: '10px', 
		overflow: 'hidden', 
		margin: '0 1rem',

	  },
	  linearProgressBar: {
		height: '100%',
		backgroundColor: theme.palette.lightGreen.main, 
	  },
	statusMessage: {
		fontSize: '4vh',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	digWeightStyle: {
		fontSize: '3vh',
		fontWeight: 'bold',
		width: '90%',
		margin: '1rem',
		textAlign: 'center',
		padding: '1rem',
		borderRadius: '1rem',
		backgroundColor: theme.palette.orange.main,
		color: theme.palette.primary.main,
	},
});

export default styles;