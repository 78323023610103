import React, { createContext, useState, useContext, useEffect } from 'react';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
	// Function to load the state from local storage
	const loadState = () => {
		try {
			const savedState = localStorage.getItem('organization');
			return savedState ? JSON.parse(savedState) : null;
		} catch (error) {
			console.error('Error loading state:', error);
			return {};
		}
	};

	const [organization, setOrganization] = useState(loadState());
	// const [organization, setOrganization] = useState(null);

	// Effect to save state to local storage when it changes
	useEffect(() => {
		localStorage.setItem('organization', JSON.stringify(organization || null));
	}, [organization]);

	return (
		<OrganizationContext.Provider value={{ organization, setOrganization }}>
			{children}
		</OrganizationContext.Provider>
	);
};

export const useOrganization = () => {
	return useContext(OrganizationContext);
};
