/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paper, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon for the clear button
import { cloudIcon, iconButton, imageStyle, imageContainer, paper } from './styles';
import { ZFWEReportPreferencesContext } from '../../../../../../../../../../../../context/ZFWEReportPreferencesContext';
import { getImageFromS3 } from '../../../../../../../../../../functions/getImageFromS3';

async function fetchImageAsBlob(imageUrl) {
	try {
		const response = await fetch(imageUrl);
		if (!response.ok) {
			throw new Error(`Network response was not ok: ${response.statusText}`);
		}
		const imageBlob = await response.blob();
		return imageBlob;
	} catch (error) {
		console.error('Failed to fetch image:', error);
		throw error;
	}
}

function createLocalImageUrl(imageBlob) {
	const localImageUrl = URL.createObjectURL(imageBlob);
	return localImageUrl;
}

const ImageSelector = () => {
	const { image, setImage, event } = useContext(ZFWEReportPreferencesContext);
	const [previewUrl, setPreviewUrl] = useState();
	const [gotImageFromCloud, setGotImageFromCloud] = useState(false);

	// Function to clear the image
	const clearImage = () => {
		setImage(null); // Reset the image state
		setPreviewUrl(null);
		setGotImageFromCloud(false);
		URL.revokeObjectURL(image); // Release the object URL
	};

	useEffect(() => {
		if (event.eventLogo === undefined) {
			clearImage();
		}
		async function fetchData() {
			try {
				const response = await getImageFromS3(event.eventLogo);
				setImage(response.imageUrl);
				setGotImageFromCloud(true);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
		if (event.eventLogo !== undefined) {
			fetchData();
		}
		// get globalZWE matching eventId
	}, [event]);

	useEffect(() => {
		if (image && gotImageFromCloud) {
			fetchImageAsBlob(image)
				.then(createLocalImageUrl)
				.then((url) => {
					setPreviewUrl(url);
				})
				.catch(console.error);
		}
	}, [event, image, gotImageFromCloud]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				const file = acceptedFiles[0];
				setImage(file);
				setPreviewUrl(URL.createObjectURL(file));
			}
		},
		[setImage]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
	});

	return (
		<Paper {...getRootProps()} sx={paper}>
			<input {...getInputProps()} />
			<CloudUploadIcon sx={cloudIcon} />
			{previewUrl ? (
				<div style={imageContainer}>
					<img src={previewUrl} alt='Dropped Image' style={imageStyle} />
					<IconButton
						onClick={(event) => {
							event.stopPropagation(); // Prevent event from propagating to parent elements
							clearImage();
						}}
						style={iconButton}>
						<CloseIcon />
					</IconButton>
				</div>
			) : (
				<Typography>
					{isDragActive
						? 'Drop the file here ...'
						: 'Drag & drop a file here, or click to select a file'}
				</Typography>
			)}
		</Paper>
	);
};

export default ImageSelector;
