import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const CategorySelector = ({ category, setCategory }) => {
	return (
		<div>
			<FormControl fullWidth={true}>
				{/* <label>
				Category *
				<select value={category} onChange={(e) => setCategory(e.target.value)} required>
					<option value=''>Select an option</option>
					<option value='conference'>Conference</option>
				</select>
			</label> */}
				<InputLabel id='demo-simple-select-label' required>
					Category
				</InputLabel>

				<Select
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					value={category}
					label='Category'
					onChange={(e) => setCategory(e.target.value)}>
					<MenuItem value={'Charity'}>Charity</MenuItem>
					<MenuItem value={'Conference'}>Conference</MenuItem>
					<MenuItem value={'Corporate'}>Corporate</MenuItem>
					<MenuItem value={'Family'}>Family</MenuItem>
					<MenuItem value={'Social'}>Social</MenuItem>
					<MenuItem value={'Wedding'}>Wedding</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
};

export default CategorySelector;
