import { Paper, Typography, useTheme } from '@mui/material';

export const Header = () => {
	const theme = useTheme();

	return (
		<div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: theme.palette.cardHeader.background,
					borderRadius: '1rem 1rem 0rem 0rem',
					padding: '0.5rem 1rem 0.5rem 1rem ',
				}}>
				<Typography variant='h4' fontWeight='bold' sx={{ textAlign: 'center' }}>
					Zero-Food-Waste Event Report Generator
				</Typography>
			</div>

			<div style={{ margin: '2vh', display: 'flex', flexDirection: 'column' }}>
				<Typography fontSize='12px' fontWeight={600}>
					Welcome to the ZFWE Report Generator!
				</Typography>
				<Typography fontSize='12px' fontWeight={600}>
					Please make sure that the event details below match with the report you are intending
					to produce. Once the report PDF has been successfully created, simply close this tab
					and proceed back to your dashboard.
				</Typography>
				<Typography fontSize='12px' fontWeight={600}>
					The PDF file should download to your browser. If you experience any issues with
					creating/retrieving the PDF, please check your browser settings to make sure it is
					not blocking pop-ups or downloads from this website before retrying.
				</Typography>
			</div>
		</div>
	);
};
