import { TextField, InputAdornment } from '@mui/material';
import React, { useState } from 'react';

const Revenue = () => {
	const [revenue, setRevenue] = useState('');

	const handleChange = (event) => {
		setRevenue(event.target.value);
	};

	const handleSubmit = () => {
		console.log(`Event space: ${revenue}`);
	};

	return (
		<TextField
			label='Food & Beverage Revenue (optional)'
			variant='outlined'
			type='number'
			fullWidth
			value={revenue}
			onChange={handleChange}
			InputProps={{
				startAdornment: revenue ? <InputAdornment position='start'>$</InputAdornment> : null,
			}}
		/>
	);
};

export default Revenue;
