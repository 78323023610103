import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { formatDateToReadable } from '../../../../../../../utils/formatDateToReadable';

const WeeklySelector = ({ weeklies, loading, selectedWeekly, setSelectedWeekly }) => {
	const handleChange = (event) => {
		setSelectedWeekly(event.target.value);
	};

	const menuProps = {
		PaperProps: {
			style: {
				maxHeight: 200, // Set the maximum height for the menu
			},
		},
	};

	return (
		<FormControl sx={{ justifySelf: 'flex-start' }}>
			<InputLabel id='weekly-dropdown-label'>Choose a weekly option</InputLabel>
			<Select
				labelId='weekly-dropdown-label'
				id='weekly-dropdown'
				value={selectedWeekly}
				label='Choose a weekly option'
				MenuProps={menuProps}
				onChange={handleChange}
				sx={{ minWidth: '220px' }}>
				{weeklies?.map((weekly, index) => (
					<MenuItem value={weekly} key={index}>
						{formatDateToReadable(weekly.weekStart)} - {formatDateToReadable(weekly.weekEnd)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default WeeklySelector;
