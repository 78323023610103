export const getStyles = (
	theme,
	headerBackgroundColor,
	headerTextColor,
	statisticTextColor,
	fontColor
) => ({
	reportBody: {
		display: 'flex',
		color: '#000',
		marginBottom: '0rem',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	reportThankYouHeader: {
		display: 'flex',
		backgroundColor: headerBackgroundColor,
		width: '100%',
		marginBottom: '0rem',
		marginTop: '0rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderTopLeftRadius: '1rem',
		borderTopRightRadius: '1rem',
		borderBottomLeftRadius: '0rem',
		borderBottomRightRadius: '0rem',
		paddingTop: '0.25rem',
		paddingBottom: '0.25rem',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	reportIntroTextBodyFormat: {
		display: 'flex',
		color: '#000',
		width: '100%',
		marginBottom: '0rem',
		marginTop: '0rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderBottom: 'none',
		paddingTop: '0.25rem',
		paddingBottom: '0.25rem',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	reportAlltimeStatsFormat: {
		display: 'flex',
		color: '',
		marginBottom: '0rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderBottom: 'none',
		borderTop: 'none',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		width: '100%',
		height: '100%',
	},
	carbonEquivalentsHeader: {
		display: 'flex',
		backgroundColor: headerBackgroundColor,
		width: '80%',
		marginBottom: '0rem',
		marginTop: '0rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '1rem',
		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	reportCarbonStatsFormat: {
		display: 'flex',
		color: '#000',
		marginBottom: '0rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderBottom: 'none',
		borderTop: 'none',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		width: '100%',
		height: '100%',
	},
	reportOutroTextBodyFormat: {
		display: 'flex',
		color: '#000',
		width: '100%',
		marginBottom: '0rem',
		marginTop: '0rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderTop: 'none',
		borderBottomLeftRadius: '1rem',
		borderBottomRightRadius: '1rem',
		paddingTop: '0.25rem',
		paddingBottom: '0.25rem',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
});
