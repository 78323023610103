import React from 'react';
import { signInRight } from './styles';

const Right = () => {
	return (
		<div
			sx={{
				display: 'flex',
				width: '50vw',
				height: '100vh',
				justifyContent: 'center',
			}}>
			<div className={'signinRight'} style={signInRight}>
				<div style={{ width: '50%' }}>
					<h1 style={{ fontSize: '3rem' }}>Welcome Back</h1>
					<h3 style={{ fontSize: '1.25rem', color: '#f0f0f0' }}>
						Please login to your account to access all of your admin cloud tools.
					</h3>
				</div>
			</div>
		</div>
	);
};

export default Right;
