import React from 'react';
import { Typography } from '@mui/material';
import { rowElement } from './styles';

const KPI = ({ label, value, unit }) => {
	return (
		<div style={rowElement}>
			<Typography variant='p' fontWeight='500'>
				{label}
			</Typography>
			<Typography variant='h4' fontWeight='600'>
				{value !== 'NaN' ? value : 0}
			</Typography>
			<Typography variant='p2' fontWeight='300'>
				{unit}
			</Typography>
		</div>
	);
};

export default KPI;
