import React, { createContext, useState, useContext } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';

// Define light and dark theme colors and typography
const themes = {
	light: {
		palette: {
			// primary: { main: '#85a885' },
			// secondary: { main: '#f50057' },
			accent: { main: '#e8f1e8' },
			gray: { 100: '#e3e3e3' },
			card: { border: '#e0e0e0', title: { green: '#349f62' } },
			cardHeader: { background: '#e8f1e8' },

			primary: {
				main: '#000',
			},
			secondary: {
				main: '#272727',
			},
			lightGreen: {
				main: '#7eaa91',
			},
			lighterGreen: {
				main: '#9ed2be',
			},
			paleGreen: {
				main: '#e8f1e8',
			},
			orange: {
				main: '#d17822',
			},

			foreground: { main: '#ffffff' },
			border: {
				main: '#dcdee1',
			},
			buttonLink: { main: '#86b28a' },

			background: {
				paper: '#ffffff',
				main: '#f7f7f8',
			},
		},
		typography: {
			h1: {
				fontSize: '6rem',
				fontWeight: 300,
				color: '#272727',
			},
			h2: {
				fontSize: '3.75rem',
				fontWeight: 500,
				color: '#272727',
			},
			h3: {
				fontSize: '3rem',
				fontWeight: 400,
				color: '#272727',
			},
			h4: {
				fontSize: '2.125rem',
				fontWeight: 400,
				color: '#272727',
			},
			h5: {
				fontSize: '1.5rem',
				fontWeight: 500,
				color: '#272727',
			},
			h6: {
				fontSize: '1.25rem',
				fontWeight: 400,
				color: '#272727',
			},
			p: {
				fontSize: '0.875rem',
				fontWeight: 300,
				color: '#343434',
			},
			p2: {
				fontSize: '0.7rem',
				fontWeight: 400,
				color: '#3c3c3c',
			},
			cardTitleGreen: {
				fontSize: '2rem',
				fontWeight: 700,
				color: '#349f62',
			},
		},
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						border: '1px solid rgba(0, 0, 0, 0.1)',
						boxShadow: 'none',
						// boxShadow: '2px 4px 8px rgba(0, 0, 0, 0.1)', // Custom box shadow for light mode
					},
				},
			},
		},
	},
	dark: {
		palette: {
			// primary: { main: '#6573c3' },
			// secondary: { main: '#ff4081' },
			accent: { main: '#575a5b' },
			card: { border: '#424242', title: { green: '#349f62' } },
			buttonLink: { main: '#86b28a' },
			gray: { 1: '#bdbdbd', 2: '#424242' },
			primary: {
				main: '#f7f8fa',
			},
			secondary: {
				main: '#272727',
			},
			lightGreen: {
				main: '#7eaa91',
			},
			lighterGreen: {
				main: '#9ed2be',
			},
			paleGreen: {
				main: '#e8f1e8',
			},
			orange: {
				main: '#d17822',
			},

			foreground: { main: '#ffffff' },
			border: {
				main: '#3f3f3f',
			},
			cardHeader: { background: '#272b2c' },

			// lighterGreen: {
			// 	main: '#7eaa91',
			// },

			background: {
				paper: '#111415',
				main: '#000',
			},
		},
		typography: {
			h1: {
				fontSize: '6rem',
				fontWeight: 600,
				color: '#bdbdbd',
				// color: '#9e9e9e',
			},
			h2: {
				fontSize: '3.75rem',
				fontWeight: 500,
				color: '#bdbdbd',
			},
			h3: {
				fontSize: '3rem',
				fontWeight: 400,
				color: '#9e9e9e',
			},
			h4: {
				fontSize: '2.125rem',
				fontWeight: 300,
				color: '#bdbdbd',
			},
			h5: {
				fontSize: '1.5rem',
				fontWeight: 400,
				color: '#b7b8b8',
			},
			h6: {
				fontSize: '1.25rem',
				fontWeight: 400,
				color: '#bdbdbd',
			},
			p: {
				fontSize: '0.75vw',
				fontWeight: 300,
				color: '#f7f7f7',
			},
			p2: {
				fontSize: '0.875rem',
				fontWeight: 300,
				color: '#f7f7f7',
			},
			cardTitleGreen: {
				fontSize: '2rem',
				fontWeight: 600,
				color: '#349f62',
			},
			signInButton: {
				fontSize: '1.5rem',
				fontWeight: 600,
				color: '#f7f7f7',
			},
		},
	},
};
// Function to get theme based on mode
const getTheme = (mode) => {
	let theme = createTheme({
		...themes[mode],
		palette: {
			...themes[mode].palette,
			mode: mode, // explicitly set the mode here
		},
	});
	theme = responsiveFontSizes(theme);
	return theme;
};

// Create context with default values
const ThemeContext = createContext({
	mode: 'light',
	theme: getTheme('light'), // Default theme
	toggleThemeMode: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children }) => {
	const [mode, setMode] = useState('light');
	const theme = getTheme(mode);

	const toggleThemeMode = () => {
		setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
	};

	return (
		<ThemeContext.Provider value={{ mode, theme, toggleThemeMode }}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};
