import { Stack, Typography } from '@mui/material';

import { getCurrentDate } from '../../../../../../../utils/getCurrentDate';
import { formatDateToReadable } from '../../../../../../../utils/formatDateToReadable';

import FoodRecycledBarChart from './charts/FoodRecycledBarChart';
import FoodRecycled from './metrics/FoodRecycled';
import ActualCovers from './metrics/ActualCovers';
import ProjectedCovers from './metrics/ProjectedCovers';
import FoodRecycledPerCoverMonthlyAvg from './metrics/FoodRecycledPerCoverMonthlyAvg';
import TopUsersBarChart from './charts/TopUsersBarChart';
import FoodRecycledPerCoverYTDAvg from './metrics/FoodRecycledPerCoverYTDAvg';
import FoodRecycledYTD from './metrics/FoodRecycledYTD';
import FoodRecycledPerCoverThisTimeLastYear from './metrics/FoodRecycledPerCoverThisTimeLastYear';
import FoodRecycledPerCoverYTDAvgThisTimeLastYear from './metrics/FoodRecycledPerCoverYTDAvgThisTimeLastYear';
import Uptime from './metrics/Uptime';
import DailyWeight from './metrics/DailyWeight';
import NumFeedsDailyAvg from './metrics/NumFeedsDailyAvg';
import { useContext } from 'react';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';

const Report = ({ selectedMonthYear }) => {
	const { organization } = useContext(OrganizationContext);

	return (
		<Stack direction='column' sx={{ width: '100%', gap: '1rem', alignItems: 'center' }}>
			<Stack direction='row' sx={{ gap: '2rem' }}>
				<Typography>
					<strong>REPORT GENERATED:</strong> {getCurrentDate()}
				</Typography>
				<Typography>
					<strong>SELECTED DATE RANGE:</strong>{' '}
					{formatDateToReadable(selectedMonthYear?.weekStart)} -{' '}
					{formatDateToReadable(selectedMonthYear?.weekEnd)}
				</Typography>
			</Stack>
			{/* HAS ISSUES WHEN WE CHANGE FROM 2024 TO 2023 AND THE MONTH CHANGES, THINK ITS BECAUSE FIRST REQ GETS CANCELLED */}
			<FoodRecycledBarChart
				startDate={selectedMonthYear?.weekStart}
				endDate={selectedMonthYear?.weekEnd}
			/>
			{/* TRENDS */}
			<Stack direction='column' gap='1rem' width='100%'>
				<Stack direction='row' gap='1rem' width='100%'>
					<FoodRecycled
						startDate={selectedMonthYear?.weekStart}
						endDate={selectedMonthYear?.weekEnd}
					/>
					<FoodRecycledYTD
						year={selectedMonthYear?.year}
						endDate={selectedMonthYear?.weekEnd}
					/>
					{organization.hasCovers && <ActualCovers selectedMonthYear={selectedMonthYear} />}
					{organization.hasCovers && (
						<FoodRecycledPerCoverMonthlyAvg selectedMonthYear={selectedMonthYear} />
					)}
				</Stack>
				<Stack direction='row' gap='1rem' width='100%'>
					<Uptime selectedMonthYear={selectedMonthYear} />
					<DailyWeight selectedMonthYear={selectedMonthYear} />
					<NumFeedsDailyAvg selectedMonthYear={selectedMonthYear} />
					{organization.hasCovers && (
						<FoodRecycledPerCoverYTDAvg selectedMonthYear={selectedMonthYear} />
					)}
					{/* <ProjectedCovers
						startDate={selectedMonthYear?.weekStart}
						endDate={selectedMonthYear?.weekEnd}
					/> */}
					{/* <FoodRecycledPerCoverThisTimeLastYear
						year={selectedMonthYear?.year}
						startYearWeek={selectedMonthYear?.startYearWeek}
						endYearWeek={selectedMonthYear?.endYearWeek}
					/> */}
					{/* <FoodRecycledPerCoverYTDAvgThisTimeLastYear
						year={selectedMonthYear?.year}
						endYearWeek={selectedMonthYear?.endYearWeek}
					/> */}
				</Stack>
			</Stack>
			<TopUsersBarChart
				startDate={selectedMonthYear?.weekStart}
				endDate={selectedMonthYear?.weekEnd}
			/>
		</Stack>
	);
};

export default Report;
