import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getAvgFeedWeightLast30Days } from '../../../../../../../../functions/getAvgFeedWeightLast30Days';

import KPI from '../KPI';

const AvgFeedWeightLast30Days = () => {
	const [value, setValue] = useState(0);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAvgFeedWeightLast30Days(organization._id);
			if (response !== 'NaN') {
				setValue(response);
			}
		}
		fetchData();
	}, [organization]);

	return <KPI label='Avg Feed Weight' value={value + ' Lbs'} description='Last 30 Days' />;
};

export default AvgFeedWeightLast30Days;
