export const styles = (theme) => ({
	paper: {
		gap: '1rem',
		height: '40vh',
		borderRadius: '1rem',
		border: `1px solid ${theme.palette.border.main}`,
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '1rem',
	},
	body: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: theme.palette.cardHeader.background,
		borderRadius: '1rem 1rem 0rem 0rem',

		padding: '0.5rem 1rem 0.5rem 1rem ',
	},
	bottomRow: {
		marginBottom: '1rem',
	},

	projectedSection: {
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
