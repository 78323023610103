import { TextField } from '@mui/material';
import React from 'react';

const NameTheMachine = () => {
	return (
		<TextField
			// label='Name the machine'
			label="Name your machine (ie. 'Seymour', 'The Machine', 'Bill Russell', 'Mike Tyson')"
			fullWidth
			sx={{ mt: 2 }}></TextField>
	);
};

export default NameTheMachine;
