import React, { useState, useContext, useEffect } from 'react';
import { Typography, useTheme, Box } from '@mui/material';
import styles from './styles';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getActiveCelebrations } from '../../functions/getActiveCelebrations';
import CelebrationIcon from '../../../../assets/icons/celly icon.png';
import { useTranslation } from 'react-i18next';

const Celebrations = () => {
	const theme = useTheme();
	const classes = styles(theme);

	const [celebrations, setCelebrations] = useState([]);
	const [currentCelebrationIndex, setCurrentCelebrationIndex] = useState(0);

	const { organization } = useContext(OrganizationContext);
	const { t } = useTranslation();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getActiveCelebrations(organization._id);
				if (Array.isArray(response)) {
					setCelebrations(response);
				} else {
					setCelebrations([]);
				}
			} catch (error) {
				console.error('Error fetching celebrations:', error);
				setCelebrations([]);
			}
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, [organization]);

	useEffect(() => {
		if (celebrations.length > 0) {
			const interval = setInterval(() => {
				setCurrentCelebrationIndex((prevIndex) => (prevIndex + 1) % celebrations.length);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [celebrations]);

	return (
		<div style={classes.digestionTankStatusCard}>
			<div style={classes.cardTitle}>
				<Typography variant='h5' fontWeight={'bold'}>
					{t('Celebrations')}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					margin: '1rem',
				}}>
				<Box style={classes.iconImageContainer}>
					<img src={CelebrationIcon} alt='Celebration' style={classes.iconImage} />
				</Box>
				<div style={classes.textContainer}>
					{celebrations.length > 0 ? (
						<>
							<Typography variant='h6' fontWeight='bold' textAlign='center'>
								{t(celebrations[currentCelebrationIndex].celebrating)}
							</Typography>
							<Typography variant='h6' textAlign='center'>
								{t(celebrations[currentCelebrationIndex].message)}
							</Typography>
							<Typography variant='h6' textAlign='center'>
								{t(celebrations[currentCelebrationIndex].subMessage)}
							</Typography>
						</>
					) : (
						<Typography variant='h6' textAlign='center'>
							{t('No Active Celebrations')}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
};

export default Celebrations;
