import { FormControl, TextField } from "@mui/material";
import React from "react";

const FinalizeDinners = ({ dinners, setDinners }) => {
  return (
    <div>
      {/* <label>
				Dinners  *
				<input
					type='number'
					value={dinners}
					onChange={(e) => setDinners(e.target.value)}
					required
				/>
			</label> */}
      <FormControl fullWidth={true}>
        <TextField
          type="number"
          label="Dinners Served"
          value={dinners}
          onChange={(e) => setDinners(e.target.value)}
          required
        ></TextField>
      </FormControl>
    </div>
  );
};

export default FinalizeDinners;
