import React, { useState, useEffect, useContext } from 'react';
import {
	Button,
	TextField,
	Typography,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Grid,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { updateUser } from '../../functions/updateUser';
import { addUser } from '../../functions/addUser';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../context/UserLocationContext';
import { deleteUser } from '../../functions/deleteUser';
import { inviteUserToCloud } from '../../functions/inviteUserToCloud';
import { UserContext } from '../../../../context/UserContext';
import { getDepartments } from '../../functions/getDepartments';

const AddUpdateUser = ({ selectedUser, setSelectedUser, refreshUsers }) => {
	return (
		<div>
			<UserForm
				selectedUser={selectedUser}
				setSelectedUser={setSelectedUser}
				refreshUsers={refreshUsers}
			/>
		</div>
	);
};

export default AddUpdateUser;

const UserForm = ({ selectedUser, setSelectedUser, refreshUsers }) => {
	const { organization } = useContext(OrganizationContext);
	const { userLocation } = useContext(UserLocationContext);
	const { user } = useContext(UserContext);
	const [departmentOptions, setDepartmentOptions] = useState([]);

	const [error, setError] = useState('');

	const [formData, setFormData] = useState({
		userId: '',
		FIRST: '',
		LAST: '',
		title: '',
		cell: '',
		email: '',
		AVATAR: '',
		LANGUAGE: 'en',
		DEPARTMENT: '',
		cloudAccess: false,
		siteChampion: false,
		coversChampion: false,
		FEEDS: null,
		userLocation: userLocation.locationName,
		CODE: '',
	});

	useEffect(() => {
		const fetch = async () => {
			const departments = await getDepartments();
			setDepartmentOptions(departments);
		};

		fetch();
	}, []);

	useEffect(() => {
		// Populate form data if selectedUser is provided
		if (selectedUser) {
			setFormData({
				userId: selectedUser._id,
				FIRST: selectedUser.FIRST || '',
				LAST: selectedUser.LAST || '',
				title: selectedUser.title || '',
				cell: selectedUser.cell || '',
				email: selectedUser.email || '',
				AVATAR: selectedUser.AVATAR || '',
				LANGUAGE: selectedUser.LANGUAGE || 'en',
				userDept: selectedUser.userDept || '',
				cloudAccess: selectedUser.cloudAccess,
				siteChampion: selectedUser.siteChampion,
				coversChampion: selectedUser.coversChampion,
				FEEDS: selectedUser.FEEDS,
				userLocation: selectedUser.userLocation,
				CODE: selectedUser.CODE || '',
			});
		}
	}, [selectedUser]);

	// clears the selectedUser and the formData state variables
	const handleClear = () => {
		setFormData({
			userId: '',
			FIRST: '',
			LAST: '',
			title: '',
			cell: '',
			email: '',
			AVATAR: '',
			LANGUAGE: 'en',
			userDept: '',
			cloudAccess: false,
			siteChampion: false,
			coversChampion: false,
			FEEDS: null,
			userLocation: userLocation.locationName,
			CODE: '',
		});
		setSelectedUser(null); // Clear selected user
		setError(''); // Clear error message
	};

	// updates the formData state variable
	// prevents nonnumber characters from being entered in cell / CODE fields
	const handleChange = (e) => {
		const { name, value } = e.target;

		setError(''); // Clear error message

		if ((name === 'cell' || name === 'code') && value && !/^\d+$/.test(value)) {
			return;
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const _updateUser = async (e) => {
		e.preventDefault();
		const response = await updateUser(formData);
		if (response.error) {
			setError(response.error); // Set the error message
		} else {
			setError(''); // Clear error message if operation is successful
			refreshUsers();
			handleClear();
			// Handle success (e.g., clear form, show success message)
		}
	};

	const _addUser = async (e) => {
		e.preventDefault();
		const response = await addUser(formData, organization);
		if (response.error) {
			setError(response.error); // Set the error message
		} else {
			setError(''); // Clear error message if operation is successful
			refreshUsers();
			handleClear();
			// Handle success (e.g., clear form, show success message)
		}
	};

	const handleSendCloudInvite = async (e) => {
		e.preventDefault();
		const response = await inviteUserToCloud(selectedUser.email);
		if (response.error) {
			setError(response.error); // Set the error message
		} else {
			setError(''); // Clear error message if operation is successful
			handleClear();
			// Handle success (e.g., clear form, show success message)
		}
	};

	const handleDelete = async (e) => {
		e.preventDefault();
		const response = await deleteUser(selectedUser._id);
		if (response.error) {
			setError(response.error); // Set the error message
		} else {
			setError(''); // Clear error message if operation is successful
			refreshUsers();
			handleClear();
			// Handle success (e.g., clear form, show success message)
		}
	};

	const handleChangeCheckbox = (event) => {
		const { name, checked } = event.target;

		setFormData((prevFormData) => {
			// If feedPermissions is checked, set FEEDS to { trainer: '', date: '' }
			if (name === 'feedPermissions' && checked) {
				return {
					...prevFormData,
					[name]: checked,
					FEEDS: { trainer: '', date: '' },
				};
			}
			// If feedPermissions is unchecked, set FEEDS to null
			if (name === 'feedPermissions' && !checked) {
				return {
					...prevFormData,
					[name]: checked,
					FEEDS: null,
				};
			}
			// For other checkboxes, just update the value normally
			return {
				...prevFormData,
				[name]: checked,
			};
		});
	};

	return (
		<form onSubmit={_updateUser}>
			<Typography variant='h6' textAlign={'center'}>
				If you have a feed PIN, select your existing user entry in the table to make changes/add
				cloud access
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TextField
						label='First Name'
						variant='outlined'
						name='FIRST'
						value={formData.FIRST}
						onChange={handleChange}
						fullWidth
						margin='normal'
						autoComplete='new-password'
					/>
					<TextField
						label='Last (Initial)'
						variant='outlined'
						name='LAST'
						value={formData.LAST}
						onChange={handleChange}
						fullWidth
						margin='normal'
						autoComplete='new-password'
					/>
					{user?.cloudPermissions?.viewPins ||
						(user?.organization === 'BioGreen360' && (
							<TextField
								label='Code'
								variant='outlined'
								name='CODE'
								value={formData.CODE}
								disabled={
									!user?.cloudPermissions?.editPins &&
									!user?.organization === 'BioGreen360'
								}
								onChange={handleChange}
								fullWidth
								margin='normal'
							/>
						))}
					<TextField
						label='Avatar'
						variant='outlined'
						name='AVATAR'
						value={formData.AVATAR}
						onChange={handleChange}
						fullWidth
						margin='normal'
					/>
					<FormControl fullWidth margin='normal'>
						<InputLabel id='preferred-language-label'>Preferred Language</InputLabel>
						<Select
							labelId='preferred-language-label'
							label='Preferred Language'
							name='LANGUAGE'
							value={formData.LANGUAGE}
							onChange={handleChange}>
							<MenuItem value='en'>English</MenuItem>
							<MenuItem value='es'>Spanish</MenuItem>
							<MenuItem value='pt'>Portuguese</MenuItem>
							<MenuItem value='vi'>Vietnamese</MenuItem>
							<MenuItem value='ar'>Arabic</MenuItem>
							<MenuItem value='fr'>French</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormControl fullWidth margin='normal'>
						<InputLabel id='department-label'>Department</InputLabel>
						<Select
							labelId='department-label'
							label='Department'
							name='userDept'
							value={formData.userDept || ''}
							onChange={handleChange}>
							{departmentOptions?.map((department, index) => (
								<MenuItem key={index} value={department.dept}>
									{department.dept}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						label='Job Title'
						variant='outlined'
						name='title'
						value={formData.title}
						onChange={handleChange}
						fullWidth
						margin='normal'
					/>
					<TextField
						label='Cell #'
						variant='outlined'
						name='cell'
						value={formData.cell}
						onChange={handleChange}
						fullWidth
						margin='normal'
						inputProps={{ maxLength: 12, pattern: '[0-9]*' }}
					/>
					<TextField
						label='Email Address'
						variant='outlined'
						name='email'
						value={formData.email}
						onChange={handleChange}
						fullWidth
						margin='normal'
						autoComplete='nope'
					/>
					{user?.cloudPermissions?.appointSiteChampions ||
						(user?.organization === 'BioGreen360' && (
							<FormControlLabel
								control={
									<Checkbox
										checked={formData.siteChampion || false}
										onChange={handleChangeCheckbox}
										name='siteChampion'
										color='primary'
									/>
								}
								label='Site Champion'
							/>
						))}
					{user?.cloudPermissions?.appointCoversChampions ||
						(user?.organization === 'BioGreen360' && (
							<FormControlLabel
								control={
									<Checkbox
										checked={formData.coversChampion || false}
										onChange={handleChangeCheckbox}
										name='coversChampion'
										color='primary'
									/>
								}
								label='Covers Champion'
							/>
						))}
					{user?.organization === 'BioGreen360' && (
						<FormControlLabel
							control={
								<Checkbox
									checked={formData.FEEDS || false}
									onChange={handleChangeCheckbox}
									name='feedPermissions'
									color='primary'
								/>
							}
							label='Feed Permissions'
						/>
					)}

					{!selectedUser && (
						<FormControlLabel
							control={
								<Checkbox
									checked={formData.cloudAccess || false}
									onChange={handleChangeCheckbox}
									name='cloudAccess'
									color='primary'
								/>
							}
							label='Send Cloud Invite Link'
						/>
					)}
				</Grid>
			</Grid>
			{error && (
				<Typography
					variant='h6'
					textAlign='center'
					style={{ color: 'red', marginTop: '10px', width: '100%' }}>
					{error}
				</Typography>
			)}

			<div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', gap: '1rem' }}>
				<Button
					variant='contained'
					color='primary'
					fullWidth
					margin='normal'
					onClick={selectedUser ? _updateUser : _addUser}>
					{selectedUser ? 'Update User Info' : 'Add New User'}
				</Button>
				{selectedUser && (
					<Button
						onClick={handleSendCloudInvite}
						variant='contained'
						color='primary'
						fullWidth
						margin='normal'>
						Send Cloud Invite
					</Button>
				)}
				{selectedUser && (
					<Button
						onClick={handleClear}
						variant='contained'
						color='primary'
						fullWidth
						margin='normal'>
						Clear Selected to Add New User
					</Button>
				)}
				{selectedUser && (
					<Button
						onClick={handleDelete}
						variant='contained'
						color='primary'
						fullWidth
						margin='normal'>
						Delete User
					</Button>
				)}
			</div>
		</form>
	);
};
