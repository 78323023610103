import axios from 'axios';

export const getOperatorCalendar = async (year, month) => {
	const url = `/.netlify/functions/getOperatorCalendar?year=${year}&month=${month}`;
	var data = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
