import { isValidDate } from './isValidDate';

export const showPreLaunch = (organization) => {
	if (isValidDate(organization.goLive)) {
		const goLiveTimestamp = new Date(organization.goLive).getTime();
		const currentTimestamp = Date.now();
		return goLiveTimestamp > currentTimestamp;
	} else {
		return false;
	}
};
