import axios from 'axios';

export const getAirExhaust = async (userLocationID) => {
	const url = `/.netlify/functions/getAirExhaust?userLocationID=${userLocationID}`;

	var data = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
