import axios from 'axios';

export const addNewOutlet = async (outletName, notes, orgID) => {
	const url = `/.netlify/functions/addNewOutlet`;

	try {
		const res = await axios.post(url, { outletName, notes, orgID });
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};
