import { useContext, useEffect, useState } from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';

import { get30DaysAggregateFeeds } from '../../../../functions/get30DaysAggregateFeeds';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getStyles } from '../styles';
import { getTimezoneOffsetInMinutes } from '../../../../../../utils/getTimezoneOffsetInMinutes';

const RecycledFoodTrends = () => {
	const theme = useTheme();
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({});
	const styles = getStyles(theme);

	// function to transform feedsData into proper format
	const processChartData = (feedsData) => {
		if (!feedsData) {
			console.error('feedsData is undefined');
			return;
		}
		const labels = [
			...new Set(Object.entries(feedsData).flatMap(([_, weights]) => Object.keys(weights))),
		].sort();

		const datasets = Object.entries(feedsData).map(([devicelabel, weights]) => {
			const data = labels.map((date) => weights[date] || 0);

			return {
				label: devicelabel,
				data,
			};
		});

		setChartData({
			labels,
			datasets,
		});
	};

	useEffect(() => {
		async function fetchData() {
			const timezoneOffset = getTimezoneOffsetInMinutes();
			const feedsData = await get30DaysAggregateFeeds(organization, timezoneOffset);
			processChartData(feedsData);
		}
		fetchData();
	}, [organization]);

	const options = {
		responsive: true,
		aspectRatio: 3, // Default is 2 (2:1), setting to a higher value will make the chart "shorter"

		// maintainAspectRatio: true,
		scales: {
			x: {
				title: {
					display: true,
					text: 'Date',
					font: {
						size: 14,
					},
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Food Recycled (lbs)',
					font: {
						size: 14,
					},
				},
			},
		},
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				formatter: (value) => value.toFixed(0),
			},
			legend: {
				onClick: (e, legendItem, legend) => {
					const ci = legend.chart;
					const index = legendItem.datasetIndex;

					// Initialize lastClickTime if it doesn't exist
					if (!ci.lastClickTime) {
						ci.lastClickTime = {};
					}

					const currentTime = new Date().getTime();
					const lastClickTime = ci.lastClickTime[index] || 0;
					const doubleClickThreshold = 300; // Milliseconds within which a double click is detected

					// Function to check if only the clicked dataset is visible
					const isOnlyThisDatasetVisible = () => {
						const visibleDatasets = ci.data.datasets.filter(
							(_, i) => ci.getDatasetMeta(i).hidden !== true
						);
						return (
							visibleDatasets.length === 1 && visibleDatasets[0] === ci.data.datasets[index]
						);
					};

					if (currentTime - lastClickTime <= doubleClickThreshold) {
						// If a double-click is detected and it's the only dataset visible, reset all to visible
						if (isOnlyThisDatasetVisible()) {
							ci.data.datasets.forEach((_, i) => {
								ci.getDatasetMeta(i).hidden = false;
							});
						} else {
							// Otherwise, hide all datasets except the double-clicked one
							ci.data.datasets.forEach((_, i) => {
								ci.getDatasetMeta(i).hidden = i !== index;
							});
						}
					} else {
						// Single-click: Toggle visibility of the clicked dataset
						const meta = ci.getDatasetMeta(index);
						meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
					}

					// Update the last click time for the clicked dataset
					ci.lastClickTime[index] = currentTime;

					// Refresh the chart to reflect changes
					ci.update();
				},
			},
		},
	};

	return (
		<Stack direction='row' gap='1rem' sx={{ width: '100%' }}>
			<Paper style={styles.paper}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '2rem',
						alignItems: 'center',
						gap: '1rem',
					}}>
					{chartData && chartData.labels?.length > 0 ? (
						<>
							<Typography variant='p'>
								Double click a location to isolate, single click to hide
							</Typography>
							<Line data={chartData} options={options} />
						</>
					) : (
						<Typography></Typography>
					)}
				</div>
			</Paper>
		</Stack>
	);
};

export default RecycledFoodTrends;
