export const bodyStyle = {
	display: 'flex', // Align items horizontally
	height: '100vh', // Full viewport height
};

export const mainContentStyle = {
	flex: 1, // Take up remaining space
	display: 'flex',
	flexDirection: 'column',
	overflowY: 'auto', // Make only this part scrollable
};
