import CarbonEquivalents from './components/CarbonEquivalents/CarbonEquivalents';
import Compliance from './components/Compliance/Compliance';
import { Box, Stack } from '@mui/material';
import TopRow from './components/TopRow/TopRow';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { useContext } from 'react';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import home from '../../../../assets/examples/aggScreenshots/home.png';
import ExamplePageWarning from '../../../CustomerDashboard/components/ExamplePageWarning/ExamplePageWarning';

const Home = () => {
	const { organization } = useContext(OrganizationContext);

	if (!showPreLaunch(organization)) {
		return (
			<Box margin={'0 2rem 2rem 1rem'}>
				<Stack direction='column' justifyContent='space-between' alignItems='center' gap='1rem'>
					<TopRow />
					<Stack direction='column' pb='3rem' gap='1rem' width='100%'>
						<CarbonEquivalents />
						<Compliance />
					</Stack>
				</Stack>
			</Box>
		);
	} else {
		return (
			<>
				<ExamplePageWarning />
				<img src={home} alt='home' />
			</>
		);
	}
};

export default Home;
