import { Stack, Typography } from '@mui/material';

import { getCurrentDate } from '../../../../../../../utils/getCurrentDate';
import { formatDateToReadable } from '../../../../../../../utils/formatDateToReadable';

import FoodRecycledBarChart from './charts/FoodRecycledBarChart';
import FoodRecycled from './metrics/FoodRecycled';
import ActualCovers from './metrics/ActualCovers';
import ProjectedCovers from './metrics/ProjectedCovers';
import FoodRecycledPerCover from './metrics/FoodRecycledPerCover';
import TopUsersBarChart from './charts/TopUsersBarChart';
import FoodRecycledPerCoverYTDAvg from './metrics/FoodRecycledPerCoverYTDAvg';
import FoodRecycledYTD from './metrics/FoodRecycledYTD';
import FoodRecycledPerCoverThisTimeLastYear from './metrics/FoodRecycledPerCoverThisTimeLastYear';
import FoodRecycledPerCoverYTDAvgThisTimeLastYear from './metrics/FoodRecycledPerCoverYTDAvgThisTimeLastYear';
import Uptime from './metrics/Uptime';
import DailyWeight from './metrics/DailyWeight';
import NumFeedsDailyAvg from './metrics/NumFeedsDailyAvg';

const Report = ({ selectedWeekly }) => {
	return (
		<Stack direction='column' sx={{ width: '100%', gap: '1rem', alignItems: 'center' }}>
			<Stack direction='row' sx={{ gap: '2rem' }}>
				<Typography>
					<strong>REPORT GENERATED:</strong> {getCurrentDate()}
				</Typography>
				<Typography>
					<strong>SELECTED DATE RANGE:</strong>{' '}
					{formatDateToReadable(selectedWeekly.weekStart)} -{' '}
					{formatDateToReadable(selectedWeekly.weekEnd)}
				</Typography>
			</Stack>
			<FoodRecycledBarChart
				startDate={selectedWeekly.weekStart}
				endDate={selectedWeekly.weekEnd}
			/>
			{/* TRENDS */}
			<Stack direction='column' gap='1rem' width='100%'>
				<Stack direction='row' gap='1rem' width='100%'>
					<FoodRecycled foodRecycled={selectedWeekly.weight.toFixed(1)} />
					<FoodRecycledYTD year={selectedWeekly.year} endDate={selectedWeekly.weekEnd} />
					{/* <ActualCovers actualCovers={selectedWeekly.actCovers} /> */}
					{/* <FoodRecycledPerCover foodRecycledPerCover={selectedWeekly.FWPerCover?.toFixed(2)} /> */}
					{/* <FoodRecycledPerCoverYTDAvg
						year={selectedWeekly.year}
						endDate={selectedWeekly.weekEnd}
					/> */}
				</Stack>
				<Stack direction='row' gap='1rem' width='100%'>
					<Uptime selectedWeekly={selectedWeekly} />
					<DailyWeight selectedWeekly={selectedWeekly} />
					<NumFeedsDailyAvg selectedWeekly={selectedWeekly} />
					{/* <ProjectedCovers projectedCovers={selectedWeekly.projCovers} /> */}
					{/* <FoodRecycledPerCoverThisTimeLastYear
						year={selectedWeekly.year}
						yearWeek={selectedWeekly.yearWeek}
					/> */}
					{/* <FoodRecycledPerCoverYTDAvgThisTimeLastYear
						year={selectedWeekly.year}
						yearWeek={selectedWeekly.yearWeek}
					/> */}
				</Stack>
			</Stack>
			<TopUsersBarChart startDate={selectedWeekly.weekStart} endDate={selectedWeekly.weekEnd} />
		</Stack>
	);
};

export default Report;
