const styles = (theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		// height: '35vh',
		// width: '70vw',
		margin: '1rem',
		padding: '1rem',
		borderRadius: '1rem',
		border: `1px solid ${theme.palette.border.main}`,
	},
	pagePreferencesHeader: {
		display: 'flex',
		color: '#000',
		margin: '1rem',
		borderRadius: '1rem',
		padding: '0.25rem',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
	div3: {
		display: 'flex',
		color: '#000',
		margin: '1rem 10rem 0 10rem',
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: '1rem',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		// width: '100%',
		marginBottom: '1rem',
	},
});

export default styles;
