import React, { useContext, useEffect, useState } from 'react';
import { getInstallDate } from '../../../../functions/getInstallDate';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { getStyles } from './styles';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import KPI from './KPI';

const InstallDate = () => {
	const [installDate, setInstallDate] = useState('N/A');
	const { organization } = useContext(OrganizationContext);
	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchData() {
			const response = await getInstallDate(organization.org);
			if (response !== 0) {
				setInstallDate(response);
			}
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Paper style={styles.paper}>
			<Tooltip title='Date the machine was installed' placement='bottom'>
				<div style={styles.innerDiv}>
					<CalendarMonth style={{ width: '42px' }} />
					<KPI label='Install Date' value={installDate}></KPI>
				</div>
			</Tooltip>
		</Paper>
	);
};

export default InstallDate;
