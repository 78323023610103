import axios from 'axios';

// done
export const signIn = async (email, password) => {
	const url = `/.netlify/functions/signIn?email=${email}&password=${password}`;
	var response = {
		organization: null,
		userLocation: null,
		user: null,
		statusCode: null,
	};

	await axios
		.get(url)
		.then((res) => {
			response.statusCode = res.status;
			if (res.status === 200) {
				response.organization = res.data.organization;
				response.userLocation = res.data.userLocation;
				response.user = res.data.user;
			} else if (res.status === 404) {
				console.log('User not found');
			} else if (res.status === 401) {
				console.log('Invalid password');
			}
		})
		.catch((err) => {
			console.log(err);
			response.statusCode = err.response ? err.response.status : 500;
		});

	return response;
};
