export function formatDateToReadable(dateString) {
	// Convert the string to a Date object
	const dateObject = new Date(dateString);

	// Options for human-readable format
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	// Convert the Date object to a human-readable format
	return dateObject.toLocaleString('en-US', options);
}
