import axios from 'axios';

export const updateZFWE = async (originalFormData) => {
	const url = `/.netlify/functions/updateZFWE`;
	let formData = { ...originalFormData };

	if (formData?.image?.path !== undefined) {
		try {
			const imageUrl = await uploadImageToS3(formData.image, formData.event._id);
			formData.imageUrl = imageUrl; // Add the image URL to formData
		} catch (error) {
			console.error('Error uploading image:', error);
			return { error: error.message || 'Error uploading image' };
		}
	} else {
		console.log('No image specified');
	}

	try {
		const res = await axios.post(url, formData);
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};

const uploadImageToS3 = async (imageFile, eventId) => {
	const uniqueFileName = `${eventId}-${Date.now()}-${imageFile.name}`;

	try {
		const presignedResponse = await axios.post('/.netlify/functions/getPresignedUrl', {
			fileName: uniqueFileName,
			fileType: imageFile.type,
		});

		const { url: uploadUrl, fields } = presignedResponse.data;
		const formData = new FormData();

		if (fields.key) {
			formData.append('key', fields.key);
		}

		Object.entries(fields).forEach(([key, value]) => {
			if (key !== 'key') {
				formData.append(key, value);
			}
		});

		formData.append('file', imageFile); // Add the file last

		await axios({
			method: 'POST',
			url: uploadUrl,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: formData,
		});

		// Assuming the file URL is determined by the key and the base URL of the bucket
		// const fileUrl = `${uploadUrl}/${fields.key}`;
		return uniqueFileName;
	} catch (error) {
		console.error('Error uploading file to S3:', error);
		throw error; // Throw the error to be handled by the caller
	}
};
