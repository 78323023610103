import { Stack, Typography } from '@mui/material';
import React from 'react';

const ZWE = () => {
	return (
		<Stack direction='column' mt={10} gap={1}>
			<Typography variant='h6' fontWeight={500}>
				Schedule Upcoming Zero Food Waste Events
			</Typography>
		</Stack>
	);
};

export default ZWE;
