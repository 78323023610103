import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getWeekliesAllTime } from '../../../../functions/getWeekliesAllTime';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import WeeklySelector from './components/WeeklySelector';
import Report from './components/Report';

const WeeklyReports = () => {
	const { organization } = useContext(OrganizationContext);
	const [weeklies, setWeeklies] = useState();
	const [selectedWeekly, setSelectedWeekly] = useState();
	const [loading, setLoading] = useState(true);

	// get list of reports to populate report selector list
	useEffect(() => {
		async function fetchData() {
			const response = await getWeekliesAllTime(organization.org);
			setWeeklies(response);
			setSelectedWeekly(response[0]);
			setLoading(false); // Set loading to false once the data is loaded
		}
		fetchData();
	}, [organization.org]);

	return loading ? (
		<>Loading..</>
	) : (
		<Box sx={{ width: '100%', minHeight: '70vh' }}>
			<Stack direction='column' sx={{ margin: '1rem', gap: '2rem' }} alignItems={'flex-start'}>
				<WeeklySelector
					weeklies={weeklies}
					loading={loading}
					selectedWeekly={selectedWeekly}
					setSelectedWeekly={setSelectedWeekly}
				/>
				<Report selectedWeekly={selectedWeekly} />
			</Stack>
		</Box>
	);
};

export default WeeklyReports;
