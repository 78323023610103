import React, { useContext } from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import SampleReportHeader from './components/SampleReportHeader';
import ReportBody from './components/ReportBody/ReportBody';
import { ZFWEReportPreferencesContext } from '../../../../../../context/ZFWEReportPreferencesContext';
import { getStyles } from './styles';

const SampleReport = () => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { reportFontColor, reportHeaderBackground, reportHeaderText, reportStatisticText } =
		useContext(ZFWEReportPreferencesContext);

	return (
		<Paper sx={styles.paper}>
			<div style={styles.header}>
				<Typography variant='h5' fontWeight='600'>
					Sample Report
				</Typography>
			</div>
			<SampleReportHeader headerTextColor={reportHeaderText} />
			<ReportBody
				headerBackgroundColor={reportHeaderBackground}
				headerTextColor={reportHeaderText}
				statisticTextColor={reportStatisticText}
				fontColor={reportFontColor}
			/>
		</Paper>
	);
};

export default SampleReport;
