import { useContext, useEffect, useState } from 'react';

import { Paper, Tooltip, useTheme } from '@mui/material';

import { OrganizationContext } from '../../../../../../../../../context/OrganizationContext';

import { getAllTimeCompostCreated } from '../../../../../../../functions/getAllTimeCompostCreated';

import KPI from '../../../../WeeklyReports/components/KPI';
import { getStyles } from '../../../../WeeklyReports/components/styles';

const CompostCreated = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [compostCreated, setCompostCreated] = useState(0);

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeCompostCreated(organization._id);
			setCompostCreated(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Paper style={styles.paper}>
			<Tooltip title='Date the machine was installed' placement='bottom'>
				<div style={styles.innerDiv}>
					<KPI label='Compost Created' value={compostCreated} unit={'cubic yards'}></KPI>
				</div>
			</Tooltip>
		</Paper>
	);
};

export default CompostCreated;
