import React from 'react';
import { useTheme, Typography, Grid } from '@mui/material';
import Statistic from './components/Statistic';
import ReportTextBody from './components/ReportTextBody';
import { getStyles } from './styles';

const ReportBody = ({ headerBackgroundColor, headerTextColor, statisticTextColor, fontColor }) => {
	const theme = useTheme();
	const styles = getStyles(
		theme,
		headerBackgroundColor,
		headerTextColor,
		statisticTextColor,
		fontColor
	);

	return (
		<div style={styles.reportBody}>
			<div style={styles.reportThankYouHeader}>
				<Typography variant='h5' fontWeight='600' sx={{ color: headerTextColor }}>
					Thank you!
				</Typography>
			</div>
			<div style={styles.reportIntroTextBodyFormat}>
				<ReportTextBody textColor={fontColor} />
			</div>
			<div style={styles.reportAlltimeStatsFormat}>
				<Grid
					container
					padding={'1rem'}
					gap={1}
					width='100%'
					justifyContent='space-evenly'
					alignContent={'center'}>
					<Statistic
						title='Avoided Food Waste per Plate*'
						value='2'
						units='oz.'
						caption='*estimate based on most recent and accurate data available'
						textColor={statisticTextColor}
					/>
					<Statistic
						title='Total Avoided Food Waste'
						value='55'
						units='lbs.'
						caption=''
						textColor={statisticTextColor}
					/>
					<Statistic
						title='Carbon Sequestered'
						value='64.3'
						units='lbs.'
						caption=''
						textColor={statisticTextColor}
					/>
					<Statistic
						title='Compost Created'
						value='0.4'
						units='cubic yards'
						caption=''
						textColor={statisticTextColor}
					/>
				</Grid>
			</div>
			{/* Provide outline to continue container */}
			<div
				style={{
					display: 'flex',
					color: '#000',
					width: '100%',
					marginBottom: '0rem',
					marginTop: '0rem',
					borderRight: `1px solid ${theme.palette.border.main}`,
					borderLeft: `1px solid ${theme.palette.border.main}`,
					paddingTop: '0.25rem',
					paddingBottom: '0.25rem',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-evenly',
				}}>
				<div style={styles.carbonEquivalentsHeader}>
					<Typography variant='h6' fontWeight='600' sx={{ color: headerTextColor }}>
						US EPA Carbon Equivalents
					</Typography>
				</div>
			</div>
			<div style={styles.reportCarbonStatsFormat}>
				<Grid
					container
					padding={'1rem'}
					gap={1}
					width='100%'
					justifyContent='space-evenly'
					alignContent={'center'}>
					<Statistic
						title='Smartphones Charged'
						value='13,381'
						units='phones'
						caption=''
						textColor={statisticTextColor}
					/>
					<Statistic
						title='Miles Driven'
						value='273'
						units='miles'
						caption=''
						textColor={statisticTextColor}
					/>
					<Statistic
						title='Homes Powered for 24hrs'
						value='5'
						units='homes'
						caption=''
						textColor={statisticTextColor}
					/>
					<Statistic
						title='Trees Planted'
						value='2'
						units='trees'
						caption=''
						textColor={statisticTextColor}
					/>
				</Grid>
			</div>
			<div style={styles.reportOutroTextBodyFormat}>
				<ReportTextBody textColor={fontColor} />
			</div>
		</div>
	);
};

export default ReportBody;
