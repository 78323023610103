export const desktopStyles = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '330px',
	borderRadius: '0',
	background: '#fff',
};

export const mobileStyles = {
	width: '100vw',
	height: '100vh',
	position: 'fixed',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	top: 0,
	left: 0,
	zIndex: 9999,
	overflowY: 'none',
	background: '#fff',
};

export const brandLogo = {
	margin: '1rem',
	display: 'flex',
	borderRadius: '1rem',
	maxWidth: '90%',
};

export const spinnerStyle = {
	display: 'inline-block',
	width: '80px',
	height: '80px',
	after: {
		content: "''",
		display: 'block',
		width: '64px',
		height: '64px',
		margin: '8px',
		borderRadius: '50%',
		border: '6px solid #ccc',
		borderColor: '#ccc transparent #ccc transparent',
		animation: 'spin 1.2s linear infinite',
	},
	'@keyframes spin': {
		'0%': { transform: 'rotate(0deg)' },
		'100%': { transform: 'rotate(360deg)' },
	},
};
