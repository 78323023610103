import { Alert, Box, Stack, Typography } from '@mui/material';
import React from 'react';

const ExamplePageWarning = () => {
	return (
		<Alert variant='filled' severity='warning' sx={{ position: 'absolute', m: 1 }}>
			This page will become available after your Go-Live Date. The data shown below is to be used
			for demonstration purposes only and is not reflective of your organization's actual usage.
		</Alert>
	);
};

export default ExamplePageWarning;
