import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { updateProjectedCovers } from '../../../../functions/updateProjectedCovers';
import { getOperatorCalendar } from '../../../../functions/getOperatorCalendar';
import ProjectedTableMessaging from './ProjectedTableMessaging';

function createData(id, outletID, outletName, week1, week2, week3, week4, week5) {
	if (week5 === undefined) {
		return { id, outletID, outletName, week1, week2, week3, week4 };
	} else return { id, outletID, outletName, week1, week2, week3, week4, week5 };
}

const formatWeekRange = (weekData) => {
	const startDate = new Date(weekData.weekStart);
	const endDate = new Date(weekData.weekEnd);
	return `Week of (${startDate.getMonth() + 1}/${startDate.getDate()} - ${
		endDate.getMonth() + 1
	}/${endDate.getDate()})`;
};

export const ProjectedTable = ({ organization, outlets, year, month }) => {
	const [rows, setRows] = React.useState([]);
	const [calendarData, setCalendarData] = React.useState();

	React.useEffect(() => {
		if (outlets && outlets.length > 0) {
			if (calendarData && calendarData.length === 5) {
				const newRows = outlets.map((outlet, index) =>
					createData(
						index,
						outlet.outletID,
						outlet.outletName,
						outlet[`week1proj`],
						outlet[`week2proj`],
						outlet[`week3proj`],
						outlet[`week4proj`],
						outlet[`week5proj`]
					)
				);

				setRows(newRows);
			} else if (calendarData) {
				const newRows = outlets.map((outlet, index) =>
					createData(
						index,
						outlet.outletID,
						outlet.outletName,
						outlet[`week1proj`],
						outlet[`week2proj`],
						outlet[`week3proj`],
						outlet[`week4proj`]
					)
				);
				setRows(newRows);
			}
		} else {
			setRows([]);
		}
	}, [outlets, calendarData, month]);

	React.useEffect(() => {
		if (month && year) {
			async function fetchData() {
				const response = await getOperatorCalendar(year, month);
				setCalendarData(response);
			}
			fetchData();
		}
	}, [month, year]);

	const handleProjectedChange = (id, week, value) => {
		const parsedValue = value === '' ? null : parseInt(value);
		setRows((prevRows) =>
			prevRows.map((row) => (row.id === id ? { ...row, [`week${week}`]: parsedValue } : row))
		);
		updateProjectedCovers(
			organization,
			year,
			month,
			week,
			rows.find((row) => row.id === id).outletID,
			parsedValue
		);
	};

	return year && month ? (
		<TableContainer component={Paper}>
			<Table sx={{ width: '100%' }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<TableCell align='left'>Outlet Name</TableCell>
						{calendarData &&
							calendarData.map((weekData, index) => (
								<TableCell key={index} align='right'>
									{formatWeekRange(weekData)}
								</TableCell>
							))}
					</TableRow>
				</TableHead>

				<TableBody>
					{rows?.map((row) => (
						<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component='th' scope='row' align='left'>
								{row.outletName}
							</TableCell>
							<TableCell align='right'>
								<input
									type='text'
									value={row.week1 !== null ? row.week1 : ''}
									onChange={(e) => handleProjectedChange(row.id, 1, e.target.value)}
								/>
							</TableCell>
							<TableCell align='right'>
								<input
									type='text'
									value={row.week2 !== null ? row.week2 : ''}
									onChange={(e) => handleProjectedChange(row.id, 2, e.target.value)}
								/>
							</TableCell>
							<TableCell align='right'>
								<input
									type='text'
									value={row.week3 !== null ? row.week3 : ''}
									onChange={(e) => handleProjectedChange(row.id, 3, e.target.value)}
								/>
							</TableCell>
							<TableCell align='right'>
								<input
									type='text'
									value={row.week4 !== null ? row.week4 : ''}
									onChange={(e) => handleProjectedChange(row.id, 4, e.target.value)}
								/>
							</TableCell>
							{row.week5 !== undefined ? (
								<TableCell align='right'>
									<input
										type='text'
										value={row.week5 !== null ? row.week5 : ''}
										onChange={(e) => handleProjectedChange(row.id, 5, e.target.value)}
									/>
								</TableCell>
							) : (
								<></>
							)}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	) : (
		<ProjectedTableMessaging mo={month} yr={year} rws={rows}></ProjectedTableMessaging>
	);
};
