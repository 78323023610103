import { useContext, useEffect, useState } from 'react';

import { Paper, Tooltip, useTheme } from '@mui/material';

import { OrganizationContext } from '../../../../../../../../../context/OrganizationContext';
import { getAllTimeFoodRecycled } from '../../../../../../../functions/getAllTimeFoodRecycled';

import KPI from '../../../../WeeklyReports/components/KPI';
import { getStyles } from '../../../../WeeklyReports/components/styles';

const FoodRecycledAllTime = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [allTimeFoodRecycled, setAllTimeFoodRecycled] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeFoodRecycled(organization._id);
			setAllTimeFoodRecycled(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='All Time Food Recycled' value={allTimeFoodRecycled} unit={'tons'}></KPI>
			</div>
		</Paper>
	);
};

export default FoodRecycledAllTime;
