import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	useTheme,
} from '@mui/material';
import { getAllEvents } from '../../../../functions/getAllEvents';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { ZFWEReportPreferencesContext } from '../../../../../../context/ZFWEReportPreferencesContext';
import ZFWEReportGenerator from '../PDFGenerator/ZFWEReportGenerator';
import { deleteEvent } from '../../../../functions/deleteEvent';
import DeleteConfirmationMessage from './DeleteComponents/DeleteConfirmationMessage';
import FinalizeEventForm from './FinalizeEventForm';

function createData(
	name,
	status,
	start,
	end,
	category,
	finCoverCount,
	attendees,
	breakfasts,
	lunches,
	dinners,
	_id,
	eventStart,
	eventEnd,
	eventLogo,
	reportFontColor,
	reportHeaderBackground,
	reportHeaderText,
	reportStatisticText,
	introText,
	outroText,
	projectedCovers,
	actualCovers
) {
	return {
		name,
		status,
		start,
		end,
		category,
		finCoverCount,
		attendees,
		breakfasts,
		lunches,
		dinners,
		_id,
		eventStart,
		eventEnd,
		eventLogo,
		reportFontColor,
		reportHeaderBackground,
		reportHeaderText,
		reportStatisticText,
		introText,
		outroText,
		projectedCovers,
		actualCovers,
	};
}

const formatDate = (dateString) => {
	const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
	return new Date(`${dateString}T00:00:00Z`).toLocaleDateString('en-US', options);
};

const AllEvents = () => {
	const { organization } = useContext(OrganizationContext);
	const [rows, setRows] = useState([]);
	const theme = useTheme();
	const { setEvent, resetReportPreferences, event } = useContext(ZFWEReportPreferencesContext);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [finalizeClicked, setFinalizeClicked] = useState(false);
	const [deletedClicked, setDeletedClicked] = useState(false);
	const [submitClicked, setSubmitClicked] = useState(false);
	const [reportGenerated, setReportGenerated] = useState(false);
	const finalizeFormRef = useRef(null); // Create a ref for the FinalizeEventForm component
	const deleteConfirmationRef = useRef(null);
	const reportRef = useRef(null);

	useEffect(() => {
		async function fetchData() {
			try {
				const events = await getAllEvents(organization.org);
				const formattedRows = events.map((event) =>
					createData(
						event.eventName,
						event.status.charAt(0).toUpperCase() + event.status.slice(1),
						formatDate(event.eventStart),
						formatDate(event.eventEnd),
						event.eventCategory.charAt(0).toUpperCase() + event.eventCategory.slice(1),
						event.finCoverCount,
						event.finAttendees,
						event.finBreakfastServed,
						event.finLunchServed,
						event.finDinnerServed,
						event._id,
						event.eventStart,
						event.eventEnd,
						event.eventLogo,
						event.reportFontColor,
						event.reportHeaderBackground,
						event.reportHeaderText,
						event.reportStatisticText,
						event.introText,
						event.outroText,
						event.projCoverCount,
						event.finCoverCount
					)
				);
				setRows(formattedRows);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
		fetchData();
	}, [organization, submitClicked, deletedClicked, reportGenerated]);

	const filteredRows = rows.filter((row) => {
		return row.name.toLowerCase().includes(searchTerm.toLowerCase());
	});

	const scrollToFinalizeForm = () => {
		setFinalizeClicked(true); // Set finalizeClicked state to true to display the component

		// Use setTimeout to ensure the component is rendered before attempting to scroll
		setTimeout(() => {
			if (finalizeFormRef.current) {
				finalizeFormRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100); // Adjust timeout as needed based on your rendering logic
	};

	const scrollToDeleteConfirmation = () => {
		setDeletedClicked(true); // Set DeletedClicked state to true to display the component

		// Use setTimeout to ensure the component is rendered before attempting to scroll
		setTimeout(() => {
			if (deleteConfirmationRef.current) {
				deleteConfirmationRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100); // Adjust timeout as needed based on your rendering logic
	};

	const scrollToReport = () => {
		// setDeletedClicked(true); // Set DeletedClicked state to true to display the component

		// Use setTimeout to ensure the component is rendered before attempting to scroll
		setTimeout(() => {
			if (reportRef.current) {
				reportRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100); // Adjust timeout as needed based on your rendering logic
	};

	const handleRowClick = (row) => {
		resetReportPreferences();
		setSelectedEvent(selectedEvent && selectedEvent._id === row._id ? null : row);
		setFinalizeClicked(false);
		setDeletedClicked(false);
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const clearSelection = () => {
		resetReportPreferences();
		setEvent(null);
		setSelectedEvent(null);
		setFinalizeClicked(false);
		setDeletedClicked(false);
	};

	const handleShowReport = (row) => {
		setEvent(row);
	};

	const actionRow = (row, handleShowReport) => {
		return (
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#f5f5f5' }}
					colSpan={11}>
					<Box margin={1} display='flex' justifyContent='space-around'>
						<Button
							variant='contained'
							onClick={() => {
								setDeletedClicked(true);
								scrollToDeleteConfirmation();
							}}>
							Delete
						</Button>
						{selectedEvent.status === 'Planned' && (
							<Button
								variant='contained'
								onClick={() => {
									setFinalizeClicked(true);
									scrollToFinalizeForm();
								}}>
								Update Actual Event Details
							</Button>
						)}
						{
							<Button
								variant='contained'
								onClick={() => {
									handleShowReport(row);
									scrollToReport();
								}}>
								Generate Report
							</Button>
						}
					</Box>
				</TableCell>
			</TableRow>
		);
	};

	return (
		<div style={{ height: '100%', width: '100%', minHeight: '75vh' }}>
			<TextField
				label='Search Events'
				variant='outlined'
				value={searchTerm}
				onChange={handleSearchChange}
				style={{ marginBottom: '1rem', margin: '1rem' }}
			/>
			<TableContainer component={Paper}>
				<Table aria-label='simple table'>
					<TableHead>
						<TableRow>
							<TableCell align='left'>Event Name</TableCell>
							<TableCell align='left'>Status</TableCell>
							<TableCell align='left'>Start Date</TableCell>
							<TableCell align='left'>End Date</TableCell>
							<TableCell align='left'>Event Category</TableCell>
							<TableCell align='left'>Projected Covers</TableCell>
							<TableCell align='left'>Actual Covers</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredRows.map((row) => (
							<React.Fragment key={row._id}>
								<TableRow
									key={row._id}
									onClick={() => {
										setSelectedEvent(row);
										setEvent(null);
										handleRowClick(row);
									}}
									sx={{
										backgroundColor:
											selectedEvent && selectedEvent._id === row._id
												? '#d5f0e1'
												: 'transparent',
										cursor: 'pointer',
										'&:hover': {
											backgroundColor: '#d5f0e1',
										},
									}}>
									<TableCell component='th' scope='row'>
										{row.name}
									</TableCell>
									<TableCell align='left'>{row.status}</TableCell>
									<TableCell align='left'>{row.start}</TableCell>
									<TableCell align='left'>{row.end}</TableCell>
									<TableCell align='left'>{row.category}</TableCell>
									<TableCell align='left'>{row.projectedCovers}</TableCell>
									<TableCell align='left'>{row.actualCovers}</TableCell>
								</TableRow>
								{selectedEvent &&
									selectedEvent._id === row._id &&
									actionRow(row, handleShowReport)}
							</React.Fragment>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{finalizeClicked && (
				<div ref={finalizeFormRef}>
					<FinalizeEventForm
						attendees={selectedEvent?.attendees}
						breakfasts={selectedEvent?.breakfasts}
						lunches={selectedEvent?.lunches}
						dinners={selectedEvent?.dinners}
						eventID={selectedEvent?._id}
						setSubmitClicked={setSubmitClicked}
						clearSelection={clearSelection}
						eventName={selectedEvent?.name}></FinalizeEventForm>
				</div>
			)}
			{deletedClicked && (
				<div ref={deleteConfirmationRef}>
					<DeleteConfirmationMessage
						eventName={selectedEvent?.name}
						attendees={selectedEvent?.attendees}
						startDate={selectedEvent?.start}
						endDate={selectedEvent?.end}></DeleteConfirmationMessage>
					<Paper
						style={{
							height: '20%',
							display: 'flex',
							flexDirection: 'row',
							color: '#000',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '1rem',
							margin: '0 1rem 1rem 1rem',
							border: `1px solid ${theme.palette.border.main}`,
							padding: '0.25rem',
							borderRadius: '0 0 0.5rem 0.5rem',
						}}>
						<Button
							variant='contained'
							onClick={() => {
								deleteEvent(selectedEvent._id);
								setSubmitClicked(true);
								setDeletedClicked(false);
							}}>
							Delete
						</Button>
						<Button
							variant='contained'
							onClick={() => {
								setDeletedClicked(false);
								setSelectedEvent(null);
							}}>
							Cancel
						</Button>
					</Paper>
				</div>
			)}

			{event && (
				<div ref={reportRef}>
					<ZFWEReportGenerator onReportGenerated={() => setReportGenerated(true)} />
				</div>
			)}
		</div>
	);
};

export default AllEvents;
