import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './styles';

const SmartphonesCharged = ({ totalAvoidedFoodWaste, reportStatisticText }) => {
	return (
		<View style={styles.statItem}>
			<Text style={{ ...styles.statHeader, color: reportStatisticText }}>
				Smartphones Charged
			</Text>
			<Text style={{ ...styles.statValue, color: reportStatisticText }}>
				{(totalAvoidedFoodWaste * 0.002 * 121643)
					.toFixed(0)
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
				phones
			</Text>
		</View>
	);
};

export default SmartphonesCharged;
