import { Box, Stack, Typography } from '@mui/material';
import FoodRecycledAllTime from './components/FoodRecycledAllTime';
import EquivalentC02 from './components/EquivalentCO2';
import CompostCreated from './components/CompostCreated';

const AllTimeMetrics = () => {
	return (
		<Box sx={{ width: '100%' }}>
			<Stack
				direction='row'
				gap='1rem'
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					margin: '1rem',
				}}>
				<Typography variant='h5' fontWeight='600'>
					All-Time Metrics
				</Typography>
			</Stack>
			<Stack direction='row' gap='1rem' width='100%'>
				<FoodRecycledAllTime />
				<EquivalentC02 />
				<CompostCreated />
			</Stack>
		</Box>
	);
};

export default AllTimeMetrics;
