import axios from 'axios';

export const getAcresFertilized = async (organization) => {
	const url = `/.netlify/functions/getAcresFertilizedAggregate`;
	let acresFertilized = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				acresFertilized = res.data;
			} else {
				console.log('Failed to fetch acres fertilized');
			}
		})
		.catch((err) => {
			console.error('Error fetching acres fertilized:', err);
		});

	return acresFertilized;
};
