import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import CustomerHUD from './CustomerHUD/CustomerHUD';
import CustomerHUDHelp from './CustomerHUD/CustomerHUDHelp';
import SignIn from './SignIn/SignIn';
import CustomerDashboard from './CustomerDashboard/CustomerDashboard';
import ForgotPassword from './SignIn/ForgotPassword/ForgotPassword';
import SignUp from './SignUp/SignUp';
import ResetPassword from './SignIn/ResetPassword/ResetPassword';
import ForgotPasswordConfirmation from './SignIn/ForgotPassword/ForgotPasswordConfirmation';
import AggregateDashboard from './AggregateDashboard/AggregateDashboard';
import Playground from './Playground/Playground';
import Onboarding from './Onboarding/Onboarding';
import OrgSelect from './SignIn/OrgSelect/OrgSelect';

const RoutesComponent = () => {
	const location = useLocation();

	// Set browser tab title
	useEffect(() => {
		const originalTitle = document.title;
		if (location.pathname === '/hud') {
			document.title = 'BioGreen360 - Customer HUD';
		} else if (location.pathname === '/dashboard') {
			document.title = 'BioGreen360 - Customer Dashboard';
		} else if (location.pathname === '/aggregateDashboard') {
			document.title = 'BioGreen360 - Aggregate Dashboard';
		} else {
			document.title = 'BioGreen360 - Circular Organic Solutions';
		}
		// Cleanup function to reset the title
		return () => {
			document.title = originalTitle;
		};
	}, [location.pathname]);

	return (
		<Routes>
			<Route path='/' element={<SignIn />} />
			<Route path='/sign-in' element={<SignIn />} />
			<Route path='/signup' element={<SignUp />} />
			<Route path='/hud' element={<CustomerHUD />} />
			<Route path='/hud-help' element={<CustomerHUDHelp />} />
			<Route path='/onboarding' element={<Onboarding />} />
			<Route path='/dashboard' element={<CustomerDashboard />} />
			<Route path='/aggregateDashboard' element={<AggregateDashboard />} />
			<Route path='/forgot-password' element={<ForgotPassword />} />
			<Route path='/forgot-password-confirmation' element={<ForgotPasswordConfirmation />} />
			<Route path='/reset-password' element={<ResetPassword />} />
			<Route path='/org-select' element={<OrgSelect />} />
			{/* Playground For testing components in isolation */}
			<Route path='/playground' element={<Playground />} />
		</Routes>
	);
};

export default RoutesComponent;
