import React, { useContext, useEffect, useState } from 'react';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getAvgUtilizationLast30Days } from '../../../../../../../../functions/getAvgUtilizationLast30Days';

const AvgUtilizationLast30Days = () => {
	const [value, setValue] = useState(0);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAvgUtilizationLast30Days(organization._id);
			if (response !== 'NaN') {
				setValue(response);
			}
		}
		fetchData();
	}, [organization]);

	return <KPI label='Avg Utilization (Lbs per day)' value={value} description='Past Week (Lbs)' />;
};

export default AvgUtilizationLast30Days;
