import { Paper, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ZFWEReportPreferencesContext } from '../../../../../../context/ZFWEReportPreferencesContext';
import { getStyles } from './styles';

export const IntroTextEditor = () => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { introText, setIntroText } = useContext(ZFWEReportPreferencesContext);
	const [inputValue, setInputValue] = useState(introText || '');

	useEffect(() => {
		if (introText) {
			setInputValue(introText);
		}
	}, [introText]);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
		setIntroText(event.target.value);
	};

	return (
		<Paper sx={styles.paper}>
			<div style={styles.header}>
				<Typography variant='h5'>Intro Text</Typography>
			</div>
			<textarea value={inputValue} onChange={handleInputChange} style={styles.textArea} />
		</Paper>
	);
};
