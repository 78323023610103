import { Grid, Paper, Stack, useTheme } from '@mui/material';
import React from 'react';
import TopUsersLast30Days from './components/TopUsersLast30Days/TopUsersLast30Days';
import AvgFeedsPerDayLast30Days from './components/AvgFeedsPerDayLast30Days/AvgFeedsPerDayLast30Days';
import AvgFeedWeightLast30Days from './components/AvgFeedWeightLast30Days/AvgFeedWeightLast30Days';
import NumActiveUsers from './components/NumActiveUsers/NumActiveUsers';
import NumTotalUsers from './components/NumTotalUsers/NumTotalUsers';
import { getStyles } from './styles';

const Card2 = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	return (
		<Grid container spacing={'1rem'}>
			<Grid item md={12} lg={9} sx={{ width: '100%' }}>
				<Paper style={styles.paper}>
					<TopUsersLast30Days />
				</Paper>
			</Grid>
			<Grid item xs={12} md={12} lg={3}>
				<Stack direction='column' gap='1rem' sx={{ width: '100%' }}>
					<Paper style={styles.paper} sx={{ paddingY: '1rem', width: '100%' }}>
						<AvgFeedsPerDayLast30Days />
					</Paper>
					<Paper style={styles.paper} sx={{ paddingY: '1rem' }}>
						<AvgFeedWeightLast30Days />
					</Paper>
					<Paper style={styles.paper} sx={{ paddingY: '1rem' }}>
						<NumActiveUsers />
					</Paper>
					<Paper style={styles.paper} sx={{ paddingY: '1rem' }}>
						<NumTotalUsers />
					</Paper>
				</Stack>
			</Grid>
		</Grid>
	);
};

const GridItem = ({ children, styles }) => (
	<Grid item xs={12} md={6} lg={6}>
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>{children}</div>
		</Paper>
	</Grid>
);

export default Card2;
