import React, { useContext, useEffect, useState } from 'react';
import KPI from './KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getAcresFertilized } from '../../../../../functions/getAcresFertilized';
import GrassIcon from '@mui/icons-material/Grass';
import { Tooltip } from '@mui/material';

const AcresFertilized = () => {
	const [value, setValue] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAcresFertilized(organization.org);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Tooltip title='Acres fertilized based on compost generated' placement='bottom'>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					margin: '10px',
					textAlign: 'center',
					alignItems: 'center',
				}}>
				<GrassIcon />
				<KPI label='Acres Fertilized' value={value} subtext='compost generated'></KPI>
			</div>
		</Tooltip>
	);
};

export default AcresFertilized;
