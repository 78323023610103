import React, { useContext } from 'react';
import { Paper, Typography, useTheme, TextField } from '@mui/material';
import { ZFWEReportPreferencesContext } from '../../../../../../../../../../context/ZFWEReportPreferencesContext';

const ColorPickerWithTextField = ({ color, setColor, label }) => {
	const handleColorChange = (event) => {
		setColor(event.target.value.toUpperCase()); // Ensure the color is always in uppercase
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
			<TextField
				type='text'
				value={color}
				onChange={handleColorChange}
				sx={{ marginRight: '1rem' }}
				inputProps={{ maxLength: 7 }} // Limit input to 7 characters (# + 6 hex digits)
				label={label}
			/>
			<input
				type='color'
				value={color}
				onChange={handleColorChange}
				style={{
					width: '40px',
					height: '40px',
					border: 'none',
					padding: '0',
				}}
			/>
		</div>
	);
};

export const ColorSchemeEditor = () => {
	const theme = useTheme();
	const {
		reportFontColor,
		setReportFontColor,
		reportHeaderBackground,
		setReportHeaderBackground,
		reportHeaderText,
		setReportHeaderText,
		reportStatisticText,
		setReportStatisticText,
	} = useContext(ZFWEReportPreferencesContext);

	return (
		<Paper
			style={{
				gap: '1rem',
				borderRadius: '1rem',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				border: `1px solid ${theme.palette.border.main}`,
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: theme.palette.cardHeader.background,
					borderRadius: '1rem 1rem 0rem 0rem',
					padding: '0.5rem 1rem 0.5rem 1rem ',
				}}>
				<Typography variant='h5'>Page Color Scheme</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '1rem',
				}}>
				<ColorPickerWithTextField
					color={reportFontColor}
					setColor={setReportFontColor}
					label='Font Color'
				/>
				<ColorPickerWithTextField
					color={reportHeaderBackground}
					setColor={setReportHeaderBackground}
					label='Header Background Color'
				/>
				<ColorPickerWithTextField
					color={reportHeaderText}
					setColor={setReportHeaderText}
					label='Header Text Color'
				/>
				<ColorPickerWithTextField
					color={reportStatisticText}
					setColor={setReportStatisticText}
					label='Statistic Color'
				/>
			</div>
		</Paper>
	);
};
