import React, { useState } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Chip,
	InputAdornment,
	IconButton,
	Box,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const languages = ['English', 'Spanish', 'Portuguese', 'Vietnamese', 'French', 'Arabic'];

const Languages = () => {
	const [selectedLanguages, setSelectedLanguages] = useState([]);

	const handleClearAll = () => {
		setSelectedLanguages([]);
	};

	return (
		<FormControl fullWidth variant='outlined' sx={{ mt: 5 }}>
			<InputLabel>Select Languages</InputLabel>
			<Select
				label='Select Languages'
				multiple
				value={selectedLanguages}
				onChange={(e) => setSelectedLanguages(e.target.value)}
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selected.map((value) => (
							<Chip key={value} label={value} />
						))}
					</Box>
				)}
				endAdornment={
					selectedLanguages.length > 0 && (
						<InputAdornment position='end' sx={{ mr: 2 }}>
							<IconButton onClick={handleClearAll}>
								<ClearIcon />
							</IconButton>
						</InputAdornment>
					)
				}>
				{languages.map((language) => (
					<MenuItem key={language} value={language}>
						{language}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default Languages;
