import React, { useContext } from 'react';
import { Divider, Paper, useTheme, IconButton } from '@mui/material';
import { OrganizationContext } from '../../../../context/OrganizationContext';

import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import SchoolIcon from '@mui/icons-material/School';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import { BrandLogo } from './components/BrandLogo';
import SidebarItem from './components/SidebarItem';

import poweredByBioGreen from '../../../../assets/PoweredByBioGreen.svg';
import Children from './components/Children/Children';
import { desktopStyles, mobileStyles } from './styles';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import { UploadBrandLogo } from './components/UploadBrandLogo';

const Sidebar = ({ tab, setTab, isMobile, showMobileSidebar, setShowMobileSidebar }) => {
	const theme = useTheme();

	const { organization } = useContext(OrganizationContext);

	const handleSetTab = (page) => {
		setTab(page);
	};

	const handleCloseSidebar = () => {
		setShowMobileSidebar(false);
	};

	return (
		organization && (
			<Paper
				style={isMobile ? mobileStyles : desktopStyles}
				sx={{
					border: `1px solid ${theme.palette.border.main}`,
					boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)',
				}}>
				{isMobile && (
					<IconButton
						onClick={handleCloseSidebar}
						sx={{ position: 'absolute', top: 0, left: 0, margin: '1rem' }}>
						<CloseIcon />
					</IconButton>
				)}
				{organization.logo ? <BrandLogo /> : <UploadBrandLogo />}
				<Divider sx={{ width: '100%' }} />
				<div
					style={{
						height: '100%',
						width: '90%',
						alignItems: 'flex-start',
						padding: '2rem',
					}}>
					{!isMobile && showPreLaunch(organization) && (
						<SidebarItem
							tab={tab}
							action={() => {
								handleSetTab('Pre-Launch');
								handleCloseSidebar();
							}}
							icon={<ChecklistRtlIcon />}
							label='Pre-Launch'
						/>
					)}
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Home');
							handleCloseSidebar();
						}}
						icon={<HomeIcon />}
						label='Home'
					/>
					{!isMobile && (
						<SidebarItem
							tab={tab}
							action={() => {
								handleSetTab('Trends');
								handleCloseSidebar();
							}}
							icon={<BarChartIcon />}
							label='Trends'
						/>
					)}
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Compliance');
							handleCloseSidebar();
						}}
						icon={<SchoolIcon />}
						label='Compliance'
					/>
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Covers');
							handleCloseSidebar();
						}}
						icon={<RestaurantIcon />}
						label='Covers'
					/>
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Celebrations');
							handleCloseSidebar();
						}}
						icon={<CelebrationIcon />}
						label='Celebrations'
					/>
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Users');
							handleCloseSidebar();
						}}
						icon={<PersonIcon />}
						label='Users'
					/>
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Locations');
							handleCloseSidebar();
						}}
						icon={<PlaceIcon />}
						label='Locations'
					/>
					<SidebarItem
						tab={tab}
						action={() => {
							handleSetTab('Reports');
							handleCloseSidebar();
						}}
						icon={<SummarizeIcon />}
						label='Reports'
					/>
				</div>
				<Divider sx={{ width: '100%' }} />

				<Children />
				<Divider sx={{ width: '100%' }} />

				{!isMobile && (
					<img src={poweredByBioGreen} alt='' style={{ width: '90%', margin: '1rem' }} />
				)}
			</Paper>
		)
	);
};

export default Sidebar;
