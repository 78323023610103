import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Stack, Typography } from '@mui/material';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getFeedTimeOfDayLast30Days } from '../../../../../../../../functions/getFeedTimeOfDayLast30Days';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const FeedTimeOfDayLast30Days = () => {
	const [feedWeighstByTimeOfDay, setFeedWeighstByTimeOfDay] = useState([0]);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getFeedTimeOfDayLast30Days(organization.org);
			setFeedWeighstByTimeOfDay(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Stack
			direction='column'
			sx={{
				alignItems: 'center',
				width: '100%',
				justifyContent: 'center',
			}}>
			<Typography variant='h5' textAlign={'center'}>
				Feed Time of Day (Last 30 Days)
			</Typography>

			<BarChartComponent feedWeightsByTimeOfDay={feedWeighstByTimeOfDay} />
		</Stack>
	);
};

const BarChartComponent = ({ feedWeightsByTimeOfDay }) => {
	const data = {
		labels: Array.from({ length: 24 }, (_, i) => i), // 24 hours
		datasets: [
			{
				label: 'Pounds (lbs) Fed',
				data: feedWeightsByTimeOfDay,
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				borderColor: 'rgba(53, 162, 235, 0.8)',
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				align: 'end',
				anchor: 'end',
				formatter: (value) => value.toFixed(0), // Limit to 1 decimal place
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Pounds (lbs) Fed',
				},
			},
			x: {
				// Define any specific options for x-axis if needed
				title: {
					display: true,
					text: 'Hour of Day',
				},
			},
		},
	};

	return <Bar data={data} options={options} />;
};

export default FeedTimeOfDayLast30Days;
