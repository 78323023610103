import { Stack, Typography } from '@mui/material';
import React from 'react';

const Covers = () => {
	return (
		<Stack direction='column' mt={10} gap={1}>
			<Typography variant='h6' fontWeight={500}>
				Enter cover count projections for the next year, by week
			</Typography>
		</Stack>
	);
};

export default Covers;
