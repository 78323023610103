import React, { useContext, useEffect, useState } from 'react';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getUptimeLast30Days } from '../../../../../../../../functions/getUptimeLast30Days';

const UptimeLast30Days = () => {
	const [value, setValue] = useState(0);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getUptimeLast30Days(organization._id);
			if (response !== 'NaN') {
				setValue(response);
			}
		}
		fetchData();
	}, [organization]);
	return (
		<div
			style={{
				width: '100%',
				padding: '0.5rem',
			}}>
			<KPI
				label='Uptime Last 30 Days'
				value={value + '%'}
				description='% of time the machine is on'
			/>
		</div>
	);
};

export default UptimeLast30Days;
