import { Paper, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { getStyles } from '../styles';
import KPI from '../KPI';

const FoodRecycled = ({ foodRecycled }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Food Recycled' value={foodRecycled || 'N/A'} unit={'Lbs'}></KPI>
			</div>
		</Paper>
	);
};

export default FoodRecycled;
