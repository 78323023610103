import axios from 'axios';

export const getDowntime = async (orgID) => {
	const url = `/.netlify/functions/getDowntime`;
	let numJams = 0;

	await axios
		.post(url, { orgID })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				numJams = res.data;
			} else {
				console.log('Failed to fetch DOWNTIME');
			}
		})
		.catch((err) => {
			console.error('Error fetching DOWNTIME:', err);
		});

	return numJams;
};
