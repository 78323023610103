import { Chip, Paper, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { useContext, useEffect, useState } from 'react';
import { getUptime } from '../../../../../../functions/getUptime';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';

const Uptime = ({ selectedWeekly }) => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { organization } = useContext(OrganizationContext);
	const [uptime, setUptime] = useState(0);
	const [percentChange, setPercentChange] = useState(0);

	useEffect(() => {
		async function fetchData() {
			const response = await getUptime(
				selectedWeekly.year,
				selectedWeekly.yearWeek,
				organization._id
			);
			setUptime(response.uptime);
			setPercentChange(response.percentChange);
			// console.log(response);
		}
		if (organization && selectedWeekly) {
			fetchData();
		}
	}, [selectedWeekly, organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI
					label='Uptime'
					value={uptime ? `${Math.round(uptime * 100)}%` : 'N/A'}
					isPercent={true}></KPI>
				<Chip
					sx={{ width: '100%', backgroundColor: percentChange > 0 ? '#d2f1e1' : '#f6685b' }}
					label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last week`}
				/>
			</div>
		</Paper>
	);
};

export default Uptime;
