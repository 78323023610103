import { useContext } from 'react';

import { Button, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { OrganizationContext } from '../../../../context/OrganizationContext';

import AccountMenu from './AccountMenu';

const NavBar = ({ tab, isMobile, showMobileSidebar, setShowMobileSidebar }) => {
	const { organization } = useContext(OrganizationContext);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderRadius: '1rem',
				padding: '1rem',
			}}>
			{isMobile && (
				<Button
					onClick={() => {
						setShowMobileSidebar(!showMobileSidebar);
					}}>
					<MenuIcon />
				</Button>
			)}
			<div style={{ display: 'flex', gap: '3px' }}>
				<Typography variant='p' fontWeight={600} color='#a9a8a9'>
					{`${organization.org}`}
				</Typography>
				{!isMobile && (
					<Typography variant='p' fontWeight={900} color='black'>
						{`/ ${tab}`}
					</Typography>
				)}
			</div>
			<div style={{ display: 'flex' }}>
				<AccountMenu />
			</div>
		</div>
	);
};

export default NavBar;
