import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getAllOrganizations } from '../../CustomerDashboard/functions/getAllOrganizations';
import { OrganizationContext } from '../../../context/OrganizationContext';
import { useNavigate } from 'react-router-dom';
import { getLocationByOrgName } from '../../CustomerDashboard/functions/getLocationByOrgName';
import { UserLocationContext } from '../../../context/UserLocationContext';
import { Button, Stack, TextField, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const OrgSelect = () => {
	const [organizations, setOrganizations] = useState([]);
	const [filteredOrganizations, setFilteredOrganizations] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const { setOrganization } = useContext(OrganizationContext);
	const { setUserLocation } = useContext(UserLocationContext);
	const navigate = useNavigate();

	useEffect(() => {
		const fetch = async () => {
			const response = await getAllOrganizations();
			setOrganizations(response);
			setFilteredOrganizations(response); // Initialize filtered organizations
		};
		fetch();
	}, []);

	const handleSearchChange = (event) => {
		const value = event.target.value.toLowerCase();
		setSearchTerm(value);

		const filtered = organizations.filter(
			(organization) =>
				organization.org.toLowerCase().includes(value) ||
				organization._id.toLowerCase().includes(value) ||
				(organization.goLive &&
					new Date(organization.goLive).toLocaleDateString().toLowerCase().includes(value))
		);

		setFilteredOrganizations(filtered);
	};

	const handleOrganizationClick = async (organization) => {
		setOrganization(organization);

		const response = await getLocationByOrgName(organization.org);

		setUserLocation(response);

		if (organization?.children?.length > 0) {
			navigate('/aggregateDashboard');
		} else {
			navigate('/dashboard');
		}
	};

	const columns = [
		{ field: 'org', headerName: 'Organization', flex: 1 },
		{
			field: 'goLive',
			headerName: 'Go Live Date',
			flex: 1,
			valueFormatter: (params) => {
				const date = params.value ? new Date(params.value) : null;
				return date && !isNaN(date)
					? date.toLocaleDateString('en-US', { timeZone: 'UTC' })
					: '';
			},
		},
		{
			field: 'actions',
			headerName: 'Dashboard',
			flex: 0.2,
			renderCell: (params) => (
				<IconButton
					color='primary'
					onClick={() => handleOrganizationClick(params.row)}
					aria-label='open'>
					<OpenInNewIcon />
				</IconButton>
			),
			sortable: false, // Disable sorting for the actions column
		},
	];

	return (
		<Stack sx={{ padding: '2rem' }} spacing={2}>
			<TextField
				label='Search Organizations'
				variant='outlined'
				value={searchTerm}
				onChange={handleSearchChange}
				sx={{ background: 'white' }}
			/>
			<DataGrid
				rows={filteredOrganizations}
				columns={columns}
				pageSize={5}
				rowsPerPageOptions={[5, 10, 20]}
				getRowId={(row) => row._id} // Use _id as the row identifier
				disableSelectionOnClick
				sx={{ background: 'white' }}
			/>
		</Stack>
	);
};

export default OrgSelect;
