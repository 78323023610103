import { Chip, Paper, Tooltip, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getFoodRecycledPerCoverYTDByDateRange } from '../../../../../../functions/getFoodRecycledPerCoverYTDByDateRange';

const FoodRecycledPerCoverYTDAvg = ({ selectedMonthYear }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [foodRecycledPerCoverYTD, setFoodRecycledPerCoverYTD] = useState();
	const [percentChange, setPercentChange] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getFoodRecycledPerCoverYTDByDateRange(
				organization._id,
				selectedMonthYear.year,
				selectedMonthYear.month
			);
			setFoodRecycledPerCoverYTD(response.avgFWPerCover);
			setPercentChange(response.percentChange);
		}
		if (selectedMonthYear && organization) fetchData();
	}, [selectedMonthYear, organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI
					label='Food Recycled Per Cover YTD Avg'
					value={
						foodRecycledPerCoverYTD && foodRecycledPerCoverYTD !== 'NaN'
							? foodRecycledPerCoverYTD
							: 'N/A'
					}
					unit={'oz'}></KPI>
				<Chip
					sx={{ width: '100%', backgroundColor: percentChange < 0 ? '#d2f1e1' : '#f6685b' }}
					label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last month`}
				/>
			</div>
		</Paper>
	);
};

export default FoodRecycledPerCoverYTDAvg;
