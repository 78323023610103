import { Button, Paper, Typography, useTheme } from '@mui/material';
import { LogoEditor } from './components/LogoEditor/LogoEditor';
import { ColorSchemeEditor } from './components/ColorSchemeEditor/ColorSchemeEditor';
import { IntroTextEditor } from './components/IntroTextEditor/IntroTextEditor';
import { OutroTextEditor } from './components/OutroTextEditor/OutroTextEditor';
import { updateZFWE } from '../../../../../../functions/updateZFWE';
import { useContext, useEffect } from 'react';
import { ZFWEReportPreferencesContext } from '../../../../../../../../context/ZFWEReportPreferencesContext';
import { getDefaultReportSettings } from '../../../../../../functions/getDefaultReportSettings';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';

export const ReportSettings = ({ onReportGenerated }) => {
	const theme = useTheme();
	const { organization } = useContext(OrganizationContext);
	const {
		event,
		image,
		introText,
		outroText,
		reportFontColor,
		reportHeaderBackground,
		reportHeaderText,
		reportStatisticText,
		FWperCover,
		setReportHeaderBackground,
		setReportHeaderText,
		setReportStatisticText,
		setReportFontColor,
		setIntroText,
		setOutroText,
	} = useContext(ZFWEReportPreferencesContext);

	useEffect(() => {
		// fetch default report settings from globalLocations object
		async function fetchData() {
			const defaultSettings = await getDefaultReportSettings(organization.org);

			if (defaultSettings) {
				setReportHeaderBackground(
					event.reportHeaderBackground || defaultSettings.reportHeaderBGColor
				);
				setReportHeaderText(event.reportHeaderText || defaultSettings.reportHeaderTextColor);
				setReportStatisticText(
					event.reportStatisticText || defaultSettings.reportStatisticColor
				);
				setReportFontColor(event.reportFontColor || defaultSettings.reportFontColor);
				setIntroText(event.introText || defaultSettings.introText);
				console.log(event.introText, defaultSettings.introText);
				setOutroText(event.outroText || defaultSettings.outroText);
			}
		}

		// if event already has custom settings for every field, no need to grab default settings
		if (
			event.reportHeaderBackground &&
			event.reportHeaderText &&
			event.reportStatisticText &&
			event.reportFontColor &&
			event.introText &&
			event.outroText
		) {
			setReportHeaderBackground(event.reportHeaderBackground);
			setReportHeaderText(event.reportHeaderText);
			setReportStatisticText(event.reportStatisticText);
			setReportFontColor(event.reportFontColor);
			setIntroText(event.introText);
			setOutroText(event.outroText);
		} else {
			fetchData();
		}
	}, [organization]);

	const saveChanges = () => {
		const formData = {
			event,
			image,
			introText,
			outroText,
			reportFontColor,
			reportHeaderBackground,
			reportHeaderText,
			reportStatisticText,
			FWperCover,
		};

		updateZFWE(formData)
			.then((response) => {
				console.log('Success:', response);
				onReportGenerated();
				// Optionally: Handle success (e.g., show a success message)
			})
			.catch((error) => {
				console.error('Error:', error);
				// Optionally: Handle error (e.g., show an error message)
			});
	};

	return (
		<Paper
			style={{
				gap: '1rem',
				borderRadius: '1rem',
				// width: '100%',
				margin: '1rem',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				border: `1px solid ${theme.palette.border.main}`,
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: theme.palette.cardHeader.background,
					borderRadius: '1rem 1rem 0rem 0rem',
					padding: '0.5rem 1rem 0.5rem 1rem ',
				}}>
				<Typography variant='h5'>Report Customizer</Typography>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', margin: '1rem', gap: '1rem' }}>
				<LogoEditor />
				<ColorSchemeEditor />
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', margin: '1rem', gap: '1rem' }}>
				<IntroTextEditor />
				<OutroTextEditor />
			</div>
			<Button
				type='submit'
				variant='contained'
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					border: '2px solid #e7e7e7',
					borderRadius: '1rem',
					padding: '1rem',
					paddingLeft: '1rem',
					paddingRight: '1rem',
					margin: '1rem',
				}}
				onClick={() => {
					saveChanges();
				}}>
				<Typography>Save Changes</Typography>
			</Button>
		</Paper>
	);
};
