import { Button, useTheme } from '@mui/material';
import React from 'react';

const TabSelector = ({ currentTab, setCurrentTab }) => {
	const theme = useTheme();

	return (
		<div>
			<div
				style={{
					// width: '100%',
					display: 'flex',
					flexDirection: 'row',
					color: theme.palette.primary.main,
					gap: '1rem',
					margin: '1rem 1rem 0 1rem',
					border: `1px solid ${theme.palette.border.main}`,
					padding: '0.5rem',
					borderRadius: '0.5rem',
				}}>
				<Button
					onClick={() => {
						setCurrentTab('Aggregate');
					}}
					sx={{
						color: currentTab === 'Aggregate' ? '#58b481' : theme.palette.primary.main,
						backgroundColor: currentTab === 'Aggregate' ? '#d5f0e1' : 'transparent',
						'&:hover': {
							backgroundColor: currentTab === 'Aggregate' ? '#d5f0e1' : 'transparent',
						},
					}}>
					Aggregate
				</Button>
				<Button
					sx={{
						color: currentTab === 'Individual' ? '#58b481' : theme.palette.primary.main,
						backgroundColor: currentTab === 'Individual' ? '#d5f0e1' : 'transparent',
						'&:hover': {
							backgroundColor: currentTab === 'Individual' ? '#d5f0e1' : 'transparent',
						},
					}}
					onClick={() => {
						setCurrentTab('Individual');
					}}>
					Individual
				</Button>
			</div>
		</div>
	);
};

export default TabSelector;
