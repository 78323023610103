import axios from 'axios';

export const addUser = async (formData, organization) => {
	const url = `/.netlify/functions/addUser`;

	try {
		const res = await axios.post(url, { ...formData, organization });
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};
