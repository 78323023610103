import React, { useContext, useState } from 'react';
import { Button, Paper, useTheme } from '@mui/material';
import AddNewEvent from './components/AddNewEvent/AddNewEvent';
import { coversContainer } from './styles';
import { UserContext } from '../../../../context/UserContext';
import { ZFWEReportPreferencesContext } from '../../../../context/ZFWEReportPreferencesContext';
import AllEvents from './components/AllEvents/AllEvents';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { isValidDate } from '../../../../utils/isValidDate';
import example_event_report from '../../../../assets/examples/example_event_report.png';
import ExamplePageWarning from '../../components/ExamplePageWarning/ExamplePageWarning';

const ZeroFoodWasteEvents = () => {
	const theme = useTheme();
	const [tab, setTab] = useState('add');
	const { user } = useContext(UserContext);
	const { organization } = useContext(OrganizationContext);
	const { setEvent, event } = useContext(ZFWEReportPreferencesContext);

	if (isValidDate(organization.goLive)) {
		return (
			<Paper style={coversContainer} sx={{ border: `1px solid ${theme.palette.border.main}` }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						color: theme.palette.primary.main,
						gap: '1rem',
						margin: '1rem 1rem 0 1rem',
						border: `1px solid ${theme.palette.border.main}`,
						padding: '0.5rem',
						borderRadius: '0.5rem 0.5rem 0 0',
					}}>
					{/* {(user.userDept === 'Sales' ||
					user.userDept === 'Marketing' ||
					user.ZFWEeditor === true) && ( */}
					<Button
						onClick={() => {
							setEvent(null);
							setTab('add');
						}}
						sx={{
							width: '100%',

							color: tab === 'add' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: tab === 'add' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: tab === 'add' ? '#d5f0e1' : 'transparent',
							},
						}}>
						Add New Event
					</Button>
					{/* )} */}

					<Button
						sx={{
							width: '100%',
							color: tab === 'events' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: tab === 'events' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: tab === 'events' ? '#d5f0e1' : 'transparent',
							},
						}}
						onClick={() => {
							setEvent(null);
							setTab('events');
						}}>
						Events
					</Button>
				</div>
				<div
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						color: '#000',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '1rem',
						margin: '0 1rem 1rem 1rem',
						border: `1px solid ${theme.palette.border.main}`,
						padding: '0.25rem',
						borderRadius: '0 0 0.5rem 0.5rem',
					}}>
					{tab === 'events' && <AllEvents />}
					{tab === 'add' && <AddNewEvent />}
				</div>
			</Paper>
		);
	} else {
		return (
			<>
				<ExamplePageWarning />
				<img src={example_event_report} />
			</>
		);
	}
};

export default ZeroFoodWasteEvents;
