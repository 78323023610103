import { FormControl, TextField } from '@mui/material';
import React from 'react';

const LunchesServedInput = ({ lunchesServed, setLunchesServed }) => {
	return (
		<div>
			{/* <label>
				Lunches Served *
				<input
					type='number'
					value={lunchesServed}
					onChange={(e) => setLunchesServed(e.target.value)}
					required
				/>
			</label> */}
			<FormControl fullWidth={true}>
				<TextField
					type='number'
					label='Lunches Served'
					value={lunchesServed}
					onChange={(e) => setLunchesServed(e.target.value)}
					required></TextField>
			</FormControl>
		</div>
	);
};

export default LunchesServedInput;
