import { Stack, Typography } from '@mui/material';
import React from 'react';

const Certificates = () => {
	return (
		<Stack direction='column' mt={10} gap={1}>
			<Typography variant='h6' fontWeight={500}>
				Enter Sustainablility Certification/Initiative and select 'ADD' button
			</Typography>
		</Stack>
	);
};

export default Certificates;
