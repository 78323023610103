import React, { useState } from 'react';
import { Button, Paper, Typography, useTheme } from '@mui/material';
import OtherLanguages from './OtherLanguages';

// Styles for the iframe and buttons
const useStyles = (currentTab, theme) => ({
	iframeContainer: {
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		paddingTop: '56.25%', // 16:9 aspect ratio
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	responsiveIframe: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: '75%',
		height: '75%',
		transform: 'translate(-50%, -50%)', // Centering the iframe
	},
	// Dynamic button style based on the selected tab
	button: (tabName) => ({
		color: currentTab === tabName ? '#58b481' : '#000',
		backgroundColor: currentTab === tabName ? '#d5f0e1' : 'transparent',
		margin: '1rem',
		'&:hover': {
			backgroundColor: currentTab === tabName ? '#d5f0e1' : 'transparent',
		},
	}),
});

const tabs = [
	'Platform Overview',
	'Best Practices',
	'Feeding',
	'Biology',
	'Containers',
	'Contaminants',
	'Bridging',
];

// Video URLs corresponding to each tab
const videoLinks = {
	platformoverview:
		'https://www.youtube.com/embed/oJAm9Ys_JVU?list=PLA1Md5KJwcnC5GBoUQdZALm7kSUrwDxVz',
	bestpractices: 'https://www.youtube.com/embed/k99GsZfZUEc?rel=0&controls=0',
	feeding: 'https://www.youtube.com/embed/JwSG0Cev_CQ?rel=0&controls=0',
	biology: 'https://www.youtube.com/embed/GG3xAQkOotw?rel=0&controls=0',
	containers: 'https://www.youtube.com/embed/nP6wKVXAdP4?rel=0&controls=0',
	contaminants: 'https://www.youtube.com/embed/uv0OWtaYo4A?rel=0&controls=0',
	bridging: 'https://www.youtube.com/embed/9ZkvFftCUiM',
};

// Reusable TabButton component
const TabButton = ({ label, tabName, setCurrentTab, styles }) => (
	<Button sx={styles.button(tabName)} onClick={() => setCurrentTab(tabName)}>
		<Typography variant='body1' fontWeight='500'>
			{label}
		</Typography>
	</Button>
);

const Training = () => {
	const [currentTab, setCurrentTab] = useState('bestpractices');
	const theme = useTheme(); // Access theme for dynamic styling
	const styles = useStyles(currentTab, theme); // Generate styles

	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				margin: '1rem',
				borderRadius: '1rem',
				border: `1px solid ${theme.palette.border.main}`,
			}}>
			{/* Header */}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '1rem',
				}}>
				<Typography variant='h3' fontWeight='600' margin='1rem'>
					Training Hub
				</Typography>

				{/* Tabs for selecting different training videos */}
				<div
					style={{
						borderRadius: '1rem',
						border: `1px solid ${theme.palette.border.main}`,
						marginTop: '1rem',
					}}>
					{tabs.map((label) => (
						<TabButton
							key={label}
							label={label}
							tabName={label.toLowerCase().replace(/\s/g, '')} // Create tab name by formatting label
							setCurrentTab={setCurrentTab}
							styles={styles}
						/>
					))}
				</div>
			</div>

			{/* Video display */}
			<div
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					marginTop: '3rem',
				}}>
				{/* Embed the video corresponding to the selected tab */}
				<div style={styles.iframeContainer}>
					<iframe
						style={styles.responsiveIframe}
						src={videoLinks[currentTab]}
						title={`BG3: ${currentTab}`}
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					/>
				</div>
			</div>

			{/* Other languages section */}
			<OtherLanguages />
		</Paper>
	);
};

export default Training;
