import { Button, Typography } from '@mui/material';
import React from 'react';

const SaveButton = ({ saveChanges }) => {
	return (
		<Button
			type='submit'
			variant='contained'
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				border: '2px solid #e7e7e7',
				borderRadius: '1rem',
				padding: '1rem',
				paddingLeft: '1rem',
				paddingRight: '1rem',
				margin: '1rem',
			}}
			onClick={() => {
				saveChanges();
			}}>
			<Typography>Save Changes</Typography>
		</Button>
	);
};

export default SaveButton;
