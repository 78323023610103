import React from 'react';
import PDFSection from './components/PDFSection/PDFSection';
import { Paper, useTheme } from '@mui/material';
import { Header } from './components/Header/Header';
import { FoodWastePerCover } from './components/FoodWastePerCover/FoodWastePerCover';
import { ReportSettings } from './components/ReportSettings/ReportSettings';

const ZFWEReportGenerator = ({ onReportGenerated }) => {
	const theme = useTheme();

	return (
		<div style={{ margin: '1rem', marginTop: '3rem' }}>
			<Paper
				style={{
					gap: '1rem',
					borderRadius: '1rem',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					// justifyContent: 'space-evenly',
					border: `1px solid ${theme.palette.border.main}`,
				}}>
				<Header />
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<FoodWastePerCover />
				</div>
				<ReportSettings onReportGenerated={onReportGenerated} />
				<PDFSection />
			</Paper>
		</div>
	);
};

export default ZFWEReportGenerator;
