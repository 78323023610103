import { Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Accesory = ({ link, title, img, attributes, useCase }) => {
	const theme = useTheme();

	const openInNewTab = () => {
		window.open(link, '_blank', 'noopener,noreferrer');
	};
	return (
		<Grid
			item
			xs={3.9}
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-between'
			border='1px solid #e7e7e7'
			borderRadius='1rem'
			padding='2rem'
			sx={{ border: `1px solid ${theme.palette.border.main}` }}>
			<div
				onClick={openInNewTab}
				style={{
					display: 'flex',
					flexDirection: 'column',
					cursor: 'pointer',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '2rem',
				}}>
				<Tooltip title='Open link in new tab' placement='bottom'>
					<Stack direction='row' gap={2} sx={{ alignItems: 'center' }}>
						<Typography variant='h6' fontWeight='600'>
							{title}
						</Typography>
						<OpenInNewIcon />
					</Stack>
				</Tooltip>

				<img src={img} alt='' style={{ maxWidth: '100%', maxHeight: '20vh' }} />
				<Typography variant='h6' textAlign='center' fontWeight='600'>
					{attributes}
				</Typography>
				<Typography variant='h6'>{useCase}</Typography>
			</div>
		</Grid>
	);
};

export default Accesory;
