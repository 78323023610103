import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.jsx';
import { OrganizationContext } from '../../../../context/OrganizationContext.jsx';
import { getOrganizationImage } from '../../functions/getOrganizationImage.jsx';
import { useTheme } from '@mui/material';

export const BrandLogo = () => {
	const theme = useTheme();
	const classes = styles(theme);
	const [image, setImage] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const base64Image = await getOrganizationImage(organization.logo);
			setImage(`data:image/png;base64,${base64Image}`);
		}
		fetchData();
	}, [organization]);

	return (
		<div style={{ maxHeight: '100%' }}>
			<img src={image} alt='orgImage' style={classes.brandLogo} />
		</div>
	);
	
};
