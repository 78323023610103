import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { formatMonthToReadable } from '../../../../../../../utils/formatMonthToReadable';

const MonthSelector = ({ months, selectedMonth, setSelectedMonth }) => {
	const handleChange = (event) => {
		setSelectedMonth(event.target.value);
	};

	const menuProps = {
		PaperProps: {
			style: {
				maxHeight: 200, // Set the maximum height for the menu
			},
		},
	};

	return (
		<FormControl sx={{ justifySelf: 'flex-start', width: '100%' }}>
			<InputLabel id='month-dropdown-label'>Choose a month</InputLabel>
			<Select
				labelId='month-dropdown-label'
				id='month-dropdown'
				value={selectedMonth || ''}
				label='Choose a month'
				MenuProps={menuProps}
				onChange={handleChange}>
				{months?.map((month, index) => (
					<MenuItem value={month} key={index}>
						{formatMonthToReadable(month)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default MonthSelector;
