import { Grid, Typography } from '@mui/material';
import React from 'react';
import { BrandLogo } from '../../../../../components/Sidebar/BrandLogo';

const SampleReportHeader = ({ headerTextColor }) => {
	return (
		<Grid
			item
			xs={4.9}
			display='flex'
			flexDirection='row'
			alignItems='center'
			justifyContent='space-evenly'
			//border='1px solid #e7e7e7'
			borderRadius='1rem'
			margin='0rem'>
			<div>
				<BrandLogo />
			</div>
			<div sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Typography fontWeight={600} sx={{ color: headerTextColor }}>
					ZERO-FOOD-WASTE EVENT REPORT:
				</Typography>
				<Typography fontWeight={600} sx={{ color: headerTextColor }}>
					"Sample Event Name"
				</Typography>
				<Typography fontWeight={600} sx={{ color: headerTextColor }}>
					"(event date)"
				</Typography>
			</div>
		</Grid>
	);
};

export default SampleReportHeader;
