import React, { useContext, useEffect, useState } from 'react';
import KPI from '../../../../../components/Cards/KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getHomesPoweredForADay } from '../../../../../functions/getHomesPoweredForADay';
import HomeIcon from '@mui/icons-material/Home';
import { Stack, Tooltip } from '@mui/material';

const HomesPowered = () => {
	const [value, setValue] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getHomesPoweredForADay(organization);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Tooltip title='Homes powered based on US average' placement='bottom'>
			<Stack direction='column' margin='10px' textAlign='center' alignItems='center'>
				<HomeIcon />
				<KPI label='Homes Powered' value={value} subtext='based on US avg.'></KPI>
			</Stack>
		</Tooltip>
	);
};

export default HomesPowered;
