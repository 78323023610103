import React, { useContext } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Grid,
} from '@mui/material';
import { UserContext } from '../../../../context/UserContext';
import { isSiteChampion } from '../../../../utils/isSiteChampion';

const UsersTable = ({ users, classes, selectedUser, setSelectedUser }) => {
	const { user } = useContext(UserContext);
	return (
		<Grid container marginTop='1rem'>
			<Grid item xs={12}>
				<div style={classes.usersTable}>
					<TableContainer>
						<Table aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell>First</TableCell>
									<TableCell>Last</TableCell>
									<TableCell>Title</TableCell>
									{isSiteChampion(user) ||
										(user?.organization === 'BioGreen360' && <TableCell>Code</TableCell>)}
									<TableCell>Department</TableCell>
									<TableCell>Language</TableCell>
									<TableCell>Email</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map((userData, id) => (
									<TableRow
										key={id}
										onClick={() => {
											setSelectedUser(userData);
										}}
										sx={{
											backgroundColor:
												selectedUser === userData ? '#e7e7e7' : 'transparent',
										}}>
										<TableCell>{userData.FIRST || '-'}</TableCell>
										<TableCell>{userData.LAST || '-'}</TableCell>
										<TableCell>{userData.title || '-'}</TableCell>
										{isSiteChampion(user) ||
											(user?.organization === 'BioGreen360' && (
												<TableCell>{userData.CODE || 'N/A'}</TableCell>
											))}
										<TableCell>{userData.userDept || '-'}</TableCell>
										<TableCell>{userData.LANGUAGE || '-'}</TableCell>
										<TableCell>{userData.email || '-'}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</Grid>
		</Grid>
	);
};

export default UsersTable;
