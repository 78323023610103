import { Chip, Paper, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getNumFeedsDailyAvgByDateRange } from '../../../../../../functions/getNumFeedsDailyAvgByDateRange';

const NumFeedsDailyAvg = ({ selectedMonthYear }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [numFeedsDailyAvg, setNumFeedsDailyAvg] = useState(0);
	const [percentChange, setPercentChange] = useState(0);

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getNumFeedsDailyAvgByDateRange(
				organization._id,
				selectedMonthYear.year,
				selectedMonthYear.month
			);
			setNumFeedsDailyAvg(response.numFeedsDailyAvg);
			setPercentChange(response.percentChange);
		}
		if (selectedMonthYear && organization) {
			fetchData();
		}
	}, [selectedMonthYear, organization]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Avg. Feeds Per Day' value={numFeedsDailyAvg || 'N/A'}></KPI>
				<Chip
					sx={{ width: '100%', backgroundColor: percentChange > 0 ? '#d2f1e1' : '#f6685b' }}
					label={`${percentChange > 0 ? '+' : ''}${percentChange}% since last month`}
				/>
			</div>
		</Paper>
	);
};

export default NumFeedsDailyAvg;
