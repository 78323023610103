import axios from 'axios';

export const getTreesPlanted = async (organization) => {
	const url = `/.netlify/functions/getTreesPlantedAggregate`;
	var treesPlanted = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				treesPlanted = res.data;
			} else {
				console.log('Failed to fetch trees planted');
			}
		})
		.catch((err) => {
			console.error('Error fetching trees planted:', err);
		});

	return treesPlanted;
};
