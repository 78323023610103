import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import React from 'react';

const Network = () => {
	return (
		<Stack mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Network Setup
			</Typography>
			<Typography variant='h6' mt={1}>
				Complete Network/IT requirements as detailed below
			</Typography>
			<Box sx={{ mt: 5 }}>
				<List>
					<ListItem>
						• Whitelist the machine(s)'s MAC address(es) for general internet access
					</ListItem>
					<ListItem>
						• 1 to 1 NAT through the network's firewall with source ACLs of 71.232.136.43,
						71.174.235.63, 98.216.211.225, and 24.62.160.238 for UDP traffic both in and out
						of port 3636
					</ListItem>
				</List>
			</Box>
		</Stack>
	);
};

export default Network;
