import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ActualTable } from './ActualTable';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getOutletCoversByMonthAndYear } from '../../../../functions/getOutletCoversByMonthAndYear';
import { getOperatorCalendar } from '../../../../functions/getOperatorCalendar';
import HeaderSection from './HeaderSection';

const formatWeekRange = (weekData) => {
	const startDate = new Date(weekData.weekStart);
	const endDate = new Date(weekData.weekEnd);
	return `Week of (${startDate.getMonth() + 1}/${startDate.getDate()} - ${
		endDate.getMonth() + 1
	}/${endDate.getDate()})`;
};

const Actual = () => {
	const { organization } = useContext(OrganizationContext);
	const [outlets, setOutlets] = useState();
	const [calendarData, setCalendarData] = useState();
	const [selectedYear, setSelectedYear] = useState('');
	const [selectedMonth, setSelectedMonth] = useState('');
	const [selectedWeek, setSelectedWeek] = useState('');

	useEffect(() => {
		async function fetchData() {
			const response = await getOutletCoversByMonthAndYear(
				organization._id,
				selectedMonth,
				selectedYear,
				selectedWeek
			);
			setOutlets(response);
		}
		fetchData();
	}, [organization, selectedMonth, selectedYear, selectedWeek]);

	// fetch weeks for selected month and year
	useEffect(() => {
		if (selectedMonth && selectedYear) {
			async function fetchData() {
				const response = await getOperatorCalendar(selectedYear, selectedMonth);
				setCalendarData(response);
			}
			fetchData();
		}
	}, [selectedMonth, selectedYear]);

	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
	};

	const handleMonthChange = (event) => {
		setSelectedMonth(event.target.value);
	};

	const handleWeekChange = (event) => {
		setSelectedWeek(event.target.value);
	};

	const generateYearRange = () => {
		const currentYear = new Date().getFullYear() + 1;
		const years = [];
		for (let year = 2021; year <= currentYear; year++) {
			years.push(year);
		}
		return years;
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '95%',
				height: '100%',
				gap: '1rem',
				marginTop: '1rem',
				marginBottom: '1rem',
				alignItems: 'center',
			}}>
			<HeaderSection />
			<Grid container direction='row' width='70%' justifyContent='space-evenly'>
				<Grid item xs={2.5}>
					<FormControl fullWidth>
						<InputLabel id='demo-simple-select-label'>Year</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							label='Year'
							value={selectedYear}
							onChange={handleYearChange}>
							{generateYearRange().map((year) => (
								<MenuItem key={year} value={year.toString()}>
									{year}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={2.5}>
					<FormControl fullWidth>
						<InputLabel id='demo-simple-select-label'>Month</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							label='Month'
							value={selectedMonth}
							onChange={handleMonthChange}>
							<MenuItem value={1}>January</MenuItem>
							<MenuItem value={2}>February</MenuItem>
							<MenuItem value={3}>March</MenuItem>
							<MenuItem value={4}>April</MenuItem>
							<MenuItem value={5}>May</MenuItem>
							<MenuItem value={6}>June</MenuItem>
							<MenuItem value={7}>July</MenuItem>
							<MenuItem value={8}>August</MenuItem>
							<MenuItem value={9}>September</MenuItem>
							<MenuItem value={10}>October</MenuItem>
							<MenuItem value={11}>November</MenuItem>
							<MenuItem value={12}>December</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={5}>
					<FormControl fullWidth>
						<InputLabel id='demo-simple-select-label'>Week</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							label='Week'
							value={selectedWeek}
							onChange={handleWeekChange}>
							{calendarData &&
								calendarData.map((weekData, index) => (
									<MenuItem key={index} value={weekData.monthWeek}>
										{formatWeekRange(weekData)}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<ActualTable
				outlets={outlets}
				week={selectedWeek}
				year={selectedYear}
				month={selectedMonth}
				organization={organization}
			/>
		</div>
	);
};

export default Actual;
