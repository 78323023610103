import { Stack, Typography } from '@mui/material';
import technical_cutsheet from '../../../../../../assets/BG3 GEN 4 TECHNICAL CUTSHEET v1.5.pdf';

const MachineCutsheet = () => {
	return (
		<Stack mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Machine Technical Cutsheet
			</Typography>
			<Typography variant='h6'>Review the machine technical cutsheet</Typography>
			<iframe
				title='technical cutsheet'
				src={technical_cutsheet}
				style={{ width: '100%', height: '1125px', marginTop: '5rem' }}
			/>
		</Stack>
	);
};

export default MachineCutsheet;
