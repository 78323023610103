import React, { useContext } from 'react';
import { Paper, Typography, useTheme, TextField, Stack } from '@mui/material';
import { ZFWEReportPreferencesContext } from '../../../../../../context/ZFWEReportPreferencesContext';
import { getStyles } from './styles';

const ColorPickerWithTextField = ({ color, setColor, label }) => {
	const handleColorChange = (event) => {
		setColor(event.target.value.toUpperCase()); // Ensure the color is always in uppercase
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
			<TextField
				type='text'
				value={color}
				onChange={handleColorChange}
				sx={{ marginRight: '1rem' }}
				inputProps={{ maxLength: 7 }} // Limit input to 7 characters (# + 6 hex digits)
				label={label}
			/>
			<input
				type='color'
				value={color}
				onChange={handleColorChange}
				style={{
					width: '40px',
					height: '40px',
					border: 'none',
					padding: '0',
				}}
			/>
		</div>
	);
};

export const ColorSchemeEditor = () => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const {
		reportFontColor,
		setReportFontColor,
		reportHeaderBackground,
		setReportHeaderBackground,
		reportHeaderText,
		setReportHeaderText,
		reportStatisticText,
		setReportStatisticText,
	} = useContext(ZFWEReportPreferencesContext);

	return (
		<Paper sx={styles.paper}>
			<div style={styles.header}>
				<Typography variant='h5'>Report Color Scheme</Typography>
			</div>
			<Stack direction='column' margin={'1rem'} alignItems={'center'}>
				<ColorPickerWithTextField
					color={reportFontColor || '#000000'}
					setColor={setReportFontColor}
					label='Font Color'
				/>
				<ColorPickerWithTextField
					color={reportHeaderBackground || '#000000'}
					setColor={setReportHeaderBackground}
					label='Header Background Color'
				/>
				<ColorPickerWithTextField
					color={reportHeaderText || '#000000'}
					setColor={setReportHeaderText}
					label='Header Text Color'
				/>
				<ColorPickerWithTextField
					color={reportStatisticText || '#000000'}
					setColor={setReportStatisticText}
					label='Statistic Color'
				/>
			</Stack>
		</Paper>
	);
};
