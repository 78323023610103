export const desktopStyles = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '330px',
	borderRadius: '0',
};

export const mobileStyles = {
	width: '100%',
	height: '100vh',
	position: 'fixed',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	top: 0,
	left: 0,
	zIndex: 9999,
	overflowY: 'none',
	background: '#fff',
};

export const brandLogo = {
	margin: '1rem',
	display: 'flex',
	borderRadius: '1rem',
	maxWidth: '90%',
};
