import React, { useState, useEffect, useContext } from 'react';
import { brandLogo } from './styles.jsx';
import { OrganizationContext } from '../../../../context/OrganizationContext.jsx';
import { getOrganizationImage } from '../../functions/getOrganizationImage.jsx';
import { Typography } from '@mui/material';

export const BrandLogo = () => {
	const [image, setImage] = useState();
	const [isLoading, setIsLoading] = useState(false); // State to track loading status
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			if (organization.logo) {
				setIsLoading(true); // Start loading
				const base64Image = await getOrganizationImage(organization.logo);
				setImage(`data:image/png;base64,${base64Image}`);
				setIsLoading(false); // End loading
			} else {
				setIsLoading(false); // Ensure loading is false if no logo
			}
		}

		fetchData();
	}, [organization]);

	return (
		<div>
			{image && <img src={image} alt='orgImage' style={brandLogo} />}
			{!image && (
				<Typography variant='h6' fontWeight={600} style={brandLogo}>
					{organization.org}
				</Typography>
			)}
		</div>
	);
};
