/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getDigWeight } from '../../functions/getDigWeight';
import { Typography, useTheme, LinearProgress } from '@mui/material';
import styles from './styles';
import 'react-circular-progressbar/dist/styles.css';
import { UserLocationContext } from '../../../../context/UserLocationContext';
import { getMachineName } from '../../functions/getMachineName';
import { useTranslation } from 'react-i18next';

const DigestionTankStatus = () => {
	const theme = useTheme();
	const classes = styles(theme);

	const [digWeight, setDigWeight] = useState();
	const [digPercentFull, setDigPercentFull] = useState(0);
	const [machineIsFull, setMachineIsFull] = useState();
	const [machineName, setMachineName] = useState('Machine');

	const { organization } = useContext(OrganizationContext);
	const { userLocation } = useContext(UserLocationContext);

	const { t } = useTranslation();

	useEffect(() => {
		async function fetchData() {
			const response = await getDigWeight(organization.org);
			const response2 = await getMachineName(userLocation.locationName);
			setDigWeight(response);
			if (response2?.machineName?.length) {
				setMachineName(response2.machineName);
			}

			// Calculate the percentage full, considering 65 lbs as 0% full.
			let percentFull;
			if (response <= 65) {
				percentFull = 0; // If the weight is 65 lbs or less, consider it as 0% full.
			} else {
				// Calculate the percentage full based on the weight above 65 lbs.
				const weightAboveBaseline = response - 65;
				const capacity = 140 - 65; // The capacity above the baseline weight of 65 lbs.
				percentFull = (weightAboveBaseline / capacity) * 100;
				percentFull = percentFull > 100 ? 100 : percentFull;
			}

			setDigPercentFull(Math.floor(percentFull)); // Use Math.floor to round down to the nearest whole number.

			// Determine if the machine is full.
			setMachineIsFull(response >= 140);
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, [userLocation, organization]);

	return (
		<div style={classes.digestionTankStatusCard}>
			<div style={classes.cardTitle}>
				<Typography variant='h5' fontWeight={'bold'}>
					"{machineName}'s" {t('Status')}
				</Typography>
			</div>
			<Typography variant='h4' style={{ width: '100%', textAlign: 'center', padding: '2px' }}>
				{`${digPercentFull}%`}
			</Typography>
			{/* <div style={classes.progressCircle}>
				<CircularProgressbar
					value={digPercentFull}
					text={`${digPercentFull}%`}
					styles={buildStyles({
						pathColor: theme.palette.lightGreen.main,
						textColor: theme.palette.secondary.main,
					})}
				/>
			</div> */}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					height: '100%',
					width: '100%',
				}}>
				<div style={classes.linearProgressBackground}>
					<LinearProgress
						variant='determinate'
						value={digPercentFull}
						sx={{
							height: '100%', // Take full height of the container
							'& .MuiLinearProgress-bar': {
								backgroundColor: '#b3c66b',
							},
							'&.MuiLinearProgress-root': {
								backgroundColor: '#e0e0e0',
							},
						}}
					/>
				</div>
				{/* <CircularProgressWithLabel
				variant='determinate'
				value={digPercentFull}
				size={150}
				style={classes.progressCircle}></CircularProgressWithLabel> */}
				<div style={classes.statusMessage}>
					{machineIsFull
						? t('Machine is Full!')
						: `${t('Feed')} ${140 - (digWeight || 0)} lbs.`}
				</div>

				{/* <div style={classes.digWeightStyle}>{digWeight} lbs</div> */}
			</div>
		</div>
	);
};

export default DigestionTankStatus;
