import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, Box, Button, Stack } from '@mui/material';

const Logo = () => {
	const [logo, setLogo] = useState(null);

	const onDrop = (acceptedFiles) => {
		const file = acceptedFiles[0];
		setLogo(
			Object.assign(file, {
				preview: URL.createObjectURL(file),
			})
		);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

	return (
		<Stack direction='column' sx={{ height: '100%' }}>
			<Box
				{...getRootProps()}
				border='2px dashed lightGrey'
				borderRadius='4px'
				height='100%'
				padding='16px'
				textAlign='center'
				display='flex'
				alignItems='center'
				justifyContent={'center'}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<Typography>Drop the files here ...</Typography>
				) : (
					!logo && <Typography>Drag & Drop or click to Upload Logo</Typography>
				)}
				{logo && (
					<Box mt={2}>
						{/* <Typography variant='h6'>Selected Logo:</Typography> */}
						<img
							src={logo.preview}
							alt='Selected Logo'
							style={{ width: '200px', marginTop: '16px' }}
						/>
					</Box>
				)}
			</Box>
		</Stack>
	);
};

export default Logo;
