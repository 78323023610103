import { Stack, Typography } from '@mui/material';
import React from 'react';

const GoLive = () => {
	return (
		<Stack mt={10} mx={10}>
			<Typography variant='h4' fontWeight='600'>
				Go Live Date
			</Typography>
		</Stack>
	);
};

export default GoLive;
