import { Grid, Typography } from '@mui/material';
import React from 'react';

const Statistic = ({ title, value, units, caption, textColor }) => {
	return (
		<Grid
			item
			xs={5.9}
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			border='1px solid #e7e7e7'
			borderRadius='1rem'
			padding='1rem'>
			<Typography textAlign={'center'} variant='p' fontWeight='650' sx={{ color: textColor }}>
				{title}
			</Typography>
			<Typography textAlign={'center'} variant='h6' fontWeight='700' sx={{ color: textColor }}>
				{value} {units}
			</Typography>
			{caption ? (
				<Typography textAlign={'center'} variant='p' fontWeight='150' sx={{ color: textColor }}>
					{caption}
				</Typography>
			) : null}
		</Grid>
	);
};

export default Statistic;
