import axios from 'axios';

export const addNewEvent = async (
	organization,
	eventName,
	eventCategory,
	projAttendees,
	eventStart,
	eventEnd,
	demographic,
	projBreakfastsServed,
	projLunchesServed,
	projDinnersServed,
	projTotalCovers
) => {
	const url = `/.netlify/functions/addNewEvent?organization=${organization}&eventName=${eventName}&eventCategory=${eventCategory}&projAttendees=${projAttendees}&eventStart=${eventStart}&eventEnd=${eventEnd}&demographic=${demographic}&projBreakfastsServed=${projBreakfastsServed}&projLunchesServed=${projLunchesServed}&projDinnersServed=${projDinnersServed}&projTotalCovers=${projTotalCovers}`;
	var data = 0;

	console.log(
		organization,
		eventName,
		eventCategory,
		projAttendees,
		eventStart,
		eventEnd,
		demographic,
		projBreakfastsServed,
		projLunchesServed,
		projDinnersServed
	);

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
