export const signInContainer = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
	width: '100%',
	backgroundColor: '#fff',
	// border: '1px solid black',
};

export const signInForm = {
	maxWidth: '500px',
	margin: '0 auto',
	padding: '40px',
	backgroundColor: '#fff',
	borderRadius: '10px',
	height: '67%',
	display: 'flex',
	justifyContent: 'space-evenly',
	flexDirection: 'column',
	// border: '1px solid #e7e7e7',
};

export const inputField = {
	height: '5vh',
	maxWidth: '80vw',
	padding: '10px',
	border: '1px solid #ddd',
	borderRadius: '5px',
};

export const buttonStyles = {
	width: '100%',
	padding: '1rem',
	border: 'none',
	borderRadius: '30px',
	backgroundColor: '#789a3c',
	color: 'white',
	fontSize: '1rem',
	maxWidth: '80vw',
};

export const forgotPassword = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	padding: '1rem',
	textAlign: 'flex-end',
	color: '#007BFF',
	cursor: 'pointer',
	// border: '1px solid black',
};

export const errorMessageStyle = {
	color: 'red',
	textAlign: 'center',
	marginTop: '10px',
	marginBottom: '30px',
};

export const buttonStyle = {
	width: '100%',
	padding: '1rem',
	border: 'none',
	borderRadius: '30px',
	backgroundColor: '#789a3c',
	color: 'white',
	fontSize: '1rem',
	cursor: 'pointer',
};

export const signInRight = {
	background: 'linear-gradient(to bottom, #3b7a4e, #b3b25a)',
	color: '#f7f7f7',
	width: '50vw',
	height: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const card = {
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	border: '1px #b0c99d solid',
	borderRadius: '10px',
	textAlign: 'center',
	backgroundColor: '#9ed2be',
	marginTop: '1vh',
};
