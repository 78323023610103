import React from 'react';
import { Typography } from '@mui/material';
import { kpiStyles } from './styles';

const KPI = ({ label, value, subtext }) => {
	return (
		<div style={kpiStyles}>
			<Typography variant='p' fontWeight='500' sx={{ color: '' }}>
				{label}
			</Typography>
			<Typography variant='h4' fontWeight={'600'}>
				{/* {parseFloat(value).toLocaleString()} */}
				{value !== 'NaN' ? parseFloat(value).toLocaleString() : 0}
			</Typography>
			<Typography variant='p2' sx={{ color: '#3c3c3c' }}>
				{subtext}
			</Typography>
		</div>
	);
};

export default KPI;
