import React, { useContext, useEffect, useState } from 'react';
import KPI from './KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getHomesPoweredForADay } from '../../../../../functions/getHomesPoweredForADay';
import { Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const HomesPowered = () => {
	const [value, setValue] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getHomesPoweredForADay(organization.org);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Tooltip title='Homes powered based on US average' placement='bottom'>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					margin: '10px',
					textAlign: 'center',
					alignItems: 'center',
				}}>
				<HomeIcon />
				<KPI label='Homes Powered' value={value} subtext='based on US avg.'></KPI>
			</div>
		</Tooltip>
	);
};

export default HomesPowered;
