import { useContext, useEffect, useState } from 'react';
import { Paper, Tooltip, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getFoodRecycledYTDByDateRange } from '../../../../../../functions/getFoodRecycledYTDByDateRange';

const FoodRecycledYTD = ({ year, endDate }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [foodRecycledYTD, setFoodRecycledYTD] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getFoodRecycledYTDByDateRange(organization._id, year, endDate);
			setFoodRecycledYTD(response);
		}
		fetchData();
	}, [endDate, organization._id, year]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI
					label='Food Recycled YTD'
					value={foodRecycledYTD && foodRecycledYTD !== 'NaN' ? foodRecycledYTD : 'N/A'}
					unit='lbs'></KPI>
			</div>
		</Paper>
	);
};

export default FoodRecycledYTD;
