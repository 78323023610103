import { Button, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Timeline from './components/Timeline/Timeline';
import RendersApproved from './components/RendersApproved/RendersApproved';
import TopBar from './components/TopBar/TopBar';
import ProgramSetup from './components/ProgramSetup/ProgramSetup';
import NetworkSetup from './components/NetworkSetup/NetworkSetup';
import SpaceReady from './components/SpaceReady/SpaceReady';
import InstallDate from './components/InstallDate/InstallDate';
import GoLive from './components/GoLive/GoLive';
import BackButton from './components/BackButton/BackButton';
import NextButton from './components/NextButton/NextButton';

const Onboarding = () => {
	const [step, setStep] = useState(1);
	const stepRef = useRef(step);

	const numSteps = 6;

	const nextStep = () => {
		if (stepRef.current < numSteps) {
			setStep(stepRef.current + 1);
		}
	};

	const prevStep = () => {
		if (stepRef.current > 1) {
			setStep(stepRef.current - 1);
		}
	};

	useEffect(() => {
		stepRef.current = step; // Update ref to latest step

		const handleKeyDown = (event) => {
			if (event.key === 'ArrowLeft') {
				prevStep(step);
			} else if (event.key === 'ArrowRight') {
				nextStep(step);
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [step]);

	return (
		<Stack
			direction='column'
			minHeight='105vh'
			component={Paper}
			sx={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
			<TopBar />
			<Timeline currentStep={step} setStep={setStep} />

			{/* <Typography variant='h4' m={'1rem'} fontWeight='600' textAlign={'center'}>
				GETTING READY FOR YOUR BG3 SYSTEM
			</Typography> */}

			{/* <Timeline /> */}
			{step === 1 && <RendersApproved />}
			{step === 2 && <ProgramSetup />}
			{step === 3 && <NetworkSetup />}
			{step === 4 && <SpaceReady />}
			{step === 5 && <InstallDate />}
			{step === 6 && <GoLive />}

			<Stack
				direction='row'
				alignItems='center'
				justifyContent={'flex-end'}
				component={Paper}
				sx={{
					// backgroundColor: ' white',
					position: 'fixed',
					bottom: '0',
					left: '0',
					right: '0',
					gap: '1rem',
					padding: '1rem',
				}}>
				<BackButton step={step} prevStep={prevStep} />
				<NextButton step={step} nextStep={nextStep} />
			</Stack>
		</Stack>
	);
};

export default Onboarding;
