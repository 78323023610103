import axios from 'axios';

export const getFoodRecycledPerCoverYTDByDateRange = async (orgID, year, month) => {
	const url = `/.netlify/functions/getFoodRecycledPerCoverYTDByDateRange?orgID=${orgID}&year=${year}&month=${month}`;

	var foodRecycledYTD = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				foodRecycledYTD = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return foodRecycledYTD;
};
