import React, { createContext, useState, useContext, useEffect } from 'react';

export const UserLocationContext = createContext();

export const UserLocationProvider = ({ children }) => {
	// Function to load the state from local storage
	const loadState = () => {
		try {
			const savedState = localStorage.getItem('userLocation');
			return savedState ? JSON.parse(savedState) : {};
		} catch (error) {
			console.error('Error loading state:', error);
			return {};
		}
	};

	// const [userLocation, setUserLocation] = useState(loadState());
	const [userLocation, setUserLocation] = useState(loadState());
	// Effect to save state to local storage when it changes
	useEffect(() => {
		localStorage.setItem('userLocation', JSON.stringify(userLocation || null));
	}, [userLocation]);

	return (
		<UserLocationContext.Provider value={{ userLocation, setUserLocation }}>
			{children}
		</UserLocationContext.Provider>
	);
};

export const useUserLocation = () => {
	return useContext(UserLocationContext);
};
