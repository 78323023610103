import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getNumUsersActive } from '../../../../../../../../functions/getNumUsersActive';

import KPI from '../KPI';

const NumActiveUsers = () => {
	const [value, setValue] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getNumUsersActive(organization._id);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return <KPI label='Number of Active Users' value={value} description='This Week' />;
};

export default NumActiveUsers;
