import { FormControl, TextField } from '@mui/material';
import React from 'react';

const StartDatePicker = ({ startDate, setStartDate }) => {
	return (
		<div>
			<FormControl fullWidth={true}>
				<TextField
					label='Start Date'
					type='date'
					value={startDate}
					onChange={(e) => setStartDate(e.target.value)}
					required
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</FormControl>
		</div>
	);
};

export default StartDatePicker;
