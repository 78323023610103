import { Text } from '@react-pdf/renderer';
import React from 'react';

const IntroText = ({ fontColor, introText }) => {
	return (
		<Text
			style={{
				fontSize: 12,
				textAlign: 'center',
				color: fontColor,
				marginHorizontal: 10,
				marginVertical: 20,
			}}>
			{introText}
		</Text>
	);
};

export default IntroText;
