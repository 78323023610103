export const home = {
	justifyContent: 'space-between',
	// gap: '1rem',
	alignItems: 'center',
	margin: '0 2rem 0 1rem',
};

export const body = {
	marginTop: '1rem',
	width: '100%',
	// height: '100%',
	gap: '1rem',
};
