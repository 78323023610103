import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import NameTheMachine from './components/NameTheMachine';
import Outlets from './components/Outlets';
import Covers from './components/Covers';
import ZWE from './components/ZWE';
import Languages from './components/Languages';
import Rooms from './components/Rooms';
import Space from './components/Space';
import Bills from './components/Bills';
import Certificates from './components/Certificates';
import Logo from './components/Logo';
import Revenue from './components/Revenue';

const ProgramSetup = () => {
	return (
		<Stack mt={10} mx={10} marginBottom='15vh'>
			<Typography variant='h4' fontWeight='600'>
				Program Setup
			</Typography>
			<Stack direction='row' mt={5} sx={{ width: '100%' }}>
				<Stack
					direction='column'
					spacing={4}
					sx={{
						width: '100%',
						margin: '0 auto',
						padding: '20px',
					}}>
					<NameTheMachine />
					<Languages />
					<Rooms />
					<Space />
					<Revenue />
				</Stack>
				<Stack
					direction='column'
					spacing={4}
					sx={{
						width: '100%',
						margin: '0 auto',
						padding: '20px',
					}}>
					<Logo />
				</Stack>
			</Stack>

			<Divider sx={{ mt: 10 }} />
			<Outlets />
			<Divider sx={{ mt: 10 }} />
			<Covers />
			<Divider sx={{ mt: 10 }} />
			<ZWE />
			<Divider sx={{ mt: 10 }} />
			<Bills />
			<Divider sx={{ mt: 10 }} />
			<Certificates />
		</Stack>
	);
};

export default ProgramSetup;
