import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import React from 'react';

const SpaceReady = () => {
	return (
		<Stack mt={10} mx={10}>
			<Typography variant='h4' fontWeight='600'>
				Engineering/Logistical Requirements
			</Typography>
			<FormGroup sx={{ mt: 10 }}>
				<FormControlLabel
					control={<Checkbox />}
					label='Clear the area and walkways where the machine(s) and smart containers are to be
				positioned. Ensure accessibility of at least 1.5 feet of clearance around the machine
				footprint (see cutsheet for unit dimensions).'
				/>
				<FormControlLabel
					control={<Checkbox />}
					label='Address the following safety issue(s): ,'
				/>
				<FormControlLabel
					control={<Checkbox />}
					label='Address the following special electrical code or fire code requirements: ,'
				/>
				<FormControlLabel
					control={<Checkbox />}
					label='Provide an electrical drop (6-ft max from machine) and room in a local breaker box, as
				detailed: 14-30R Recepticle plug, 30 AMP, 208-240V +/- 10% Single-Phase AC 60 HZ'
				/>
			</FormGroup>
			<FormControlLabel
				control={<Checkbox />}
				label='Provide an electrical drop (6-ft max from machine) and room in a local breaker box, as
				detailed: 14-30R Recepticle plug, 30 AMP, 208-240V +/- 10% Single-Phase AC 60 HZ'
			/>
			<FormControlLabel
				control={<Checkbox />}
				label='Provide an ethernet drop (6-ft from machine), as detailed: RJ45 Ethernet Jack CAT 5+'
			/>
			<FormControlLabel
				control={<Checkbox />}
				label='Clear area for exhaust run: External Building Open Air,External Building Open Air'
			/>
			<FormControlLabel
				control={<Checkbox />}
				label='Clear secondary location for smart container storage and pallet jack access: Available
				space outdoors where we can store containers as needed, at quantity 10'
			/>
			<FormControlLabel
				control={<Checkbox />}
				label='				Furnish and install epoxy to floor beneath machine placement(s) with 1-foot buffer (if
				concerned with damage to concrete)'
			/>
			<FormControlLabel
				control={<Checkbox />}
				label='If floor beneath machine placement(s) is sloped at greater than 2% grade, furnish and
				install a level concrete pad for the machine to be placed on'
			/>
		</Stack>
	);
};

export default SpaceReady;
