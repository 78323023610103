const styles = (theme) => ({
	canvas: {
		display: 'flex',
		flexDirection: 'column',
		margin: '1rem',
		borderRadius: '1rem',
		border: `1px solid ${theme.palette.border.main}`,
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		color: theme.palette.primary.main,
		gap: '1rem',
		margin: '1rem 1rem 0 1rem',
		padding: '1rem',
		alignItems: 'center',
		borderRadius: '0.5rem',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
		borderRadius: '1rem',
		border: `1px solid ${theme.palette.border.main}`,
	},
	usersTable: {
		overflowX: 'auto',
		borderRadius: '1rem',
		// width: '100%',
		height: '50vh',
		border: `1px solid ${theme.palette.border.main}`,
	},
});

export default styles;
