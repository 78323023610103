import { Text, View } from '@react-pdf/renderer';
import React from 'react';

const ThankYou = ({ headerBackgroundColor, headerTextColor }) => {
	return (
		<Text
			style={{
				fontSize: 12,
				fontWeight: 'bold',
				textAlign: 'center',
				// marginTop: 20,
				backgroundColor: headerBackgroundColor,
				color: headerTextColor,
				// borderRadius: '10px',
				borderRadius: '10px',
				padding: 10,
			}}>
			Thank you!
		</Text>
	);
};

export default ThankYou;
