// functions/uploadImageFile.js

import axios from 'axios';

const uploadOrgLogo = async (orgID, imageFile) => {
	try {
		// Step 1: Get a pre-signed URL from your backend
		const presignedResponse = await axios.post('/.netlify/functions/getPresignedUrl', {
			fileName: imageFile.name,
			fileType: imageFile.type,
			orgID: orgID,
		});

		const { url: uploadUrl, fields } = presignedResponse.data;

		const formData = new FormData();
		// Ensure 'key' field is added first
		if (fields.key) {
			formData.append('key', fields.key);
		}
		Object.entries(fields).forEach(([key, value]) => {
			if (key !== 'key') {
				// Avoid appending 'key' twice
				formData.append(key, value);
			}
		});
		formData.append('file', imageFile); // 'file' should be the last field appended

		const uploadResponse = await axios({
			method: 'POST',
			url: uploadUrl,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: formData,
		});

		if (uploadResponse.status === 204) {
			const url = `/.netlify/functions/uploadOrgLogo?orgID=${orgID}&photo=${imageFile.name}-${orgID}`;
			var data = 0;

			await axios
				.get(url)
				.then((res) => {
					if (res.data != null && res.data !== false) {
						data = res.data;
						// console.log(data);
					} else {
						console.log('failed');
					}
				})
				.catch((err) => {
					console.log(err);
				});

			// console.log('File uploaded successfully');
		} else {
			console.error('Upload to S3 failed:', uploadResponse.statusText);
		}
	} catch (error) {
		console.error('Error uploading file:', error);
	}
};

// TODO: STORE LOGO PATH IN globalOrgs(organization: organization.org) as logo

// TODO: STORE MACHINE PHOTO IN globalLocations(locationName: userLocation) as photo

export { uploadOrgLogo };
