import { Paper, Typography, useTheme } from '@mui/material';
import ImageSelector from './components/ImageSelector/ImageSelector';

export const LogoEditor = () => {
	const theme = useTheme();

	return (
		<Paper
			style={{
				gap: '1rem',
				borderRadius: '1rem',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				border: `1px solid ${theme.palette.border.main}`,
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: theme.palette.cardHeader.background,
					borderRadius: '1rem 1rem 0rem 0rem',
					padding: '0.5rem 1rem 0.5rem 1rem ',
				}}>
				<Typography variant='h5'>Customer/Event Logo (BETA)</Typography>
			</div>
			<ImageSelector />
		</Paper>
	);
};
