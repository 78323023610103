import { useContext, useEffect, useState } from 'react';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';
import { getSafetyIssues } from '../../../../functions/getSafetyIssues';
import { getSpecialRequirements } from '../../../../functions/getSpecialRequirements';
import { getExhaustProximity } from '../../../../functions/getExhaustProximity';
import { getAirExhaust } from '../../../../functions/getAirExhaust';
import { getSecondaryLocation } from '../../../../functions/getSecondaryLocation';

const SpaceReady = () => {
	const { userLocation } = useContext(UserLocationContext);
	const [safetyIssues, setSafetyIssues] = useState();
	const [specialRequirements, setSpecialRequirements] = useState();
	const [exhaustProximity, setExhaustProximity] = useState();
	const [airExhaust, setAirExhaust] = useState();
	const [secondaryLocation, setSecondaryLocation] = useState();

	useEffect(() => {
		const fetchSafetyIssues = async () => {
			const response = await getSafetyIssues(userLocation._id);
			setSafetyIssues(response);
		};
		const fetchSpecialRequirements = async () => {
			const response = await getSpecialRequirements(userLocation._id);
			setSpecialRequirements(response);
		};
		const fetchExhaustProximity = async () => {
			const response = await getExhaustProximity(userLocation._id);
			setExhaustProximity(response);
		};
		const fetchAirExhaust = async () => {
			const response = await getAirExhaust(userLocation._id);
			setAirExhaust(response);
		};
		const fetchSecondaryLocation = async () => {
			const response = await getSecondaryLocation(userLocation._id);
			setSecondaryLocation(response);
		};
		fetchSafetyIssues();
		fetchSpecialRequirements();
		fetchExhaustProximity();
		fetchAirExhaust();
		fetchSecondaryLocation();
	}, [userLocation]);

	return (
		<Stack mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Engineering/Logistical Requirements
			</Typography>
			<List sx={{ mt: 5 }}>
				<ListItem>
					• Clear the area and walkways where the machine(s) and smart containers are to be
					positioned. Ensure accessibility of at least 1.5 feet of clearance around the machine
					footprint (see cutsheet for unit dimensions).
				</ListItem>
				{safetyIssues?.length > 0 && (
					<ListItem>• Address the following safety issue(s): {safetyIssues}</ListItem>
				)}
				{specialRequirements?.length > 0 && (
					<ListItem>
						• Address the following special electrical code or fire code requirements:
						{specialRequirements},
					</ListItem>
				)}
				<ListItem>
					• Provide an electrical drop (6-ft max from machine) and room in a local breaker box,
					as detailed: 14-30R Recepticle plug, 30 AMP, 208-240V +/- 10% Single-Phase AC 60 HZ
				</ListItem>
				<ListItem>
					• Provide an ethernet drop (6-ft from machine), as detailed: RJ45 Ethernet Jack CAT
					5+
				</ListItem>
				{exhaustProximity?.length > 0 && (
					<ListItem>• Clear area for exhaust run: {exhaustProximity}</ListItem>
				)}
				{airExhaust?.length > 0 && (
					<ListItem>• Clear area for exhaust run: {airExhaust}</ListItem>
				)}
				{secondaryLocation?.length > 0 && (
					<ListItem>
						• Clear secondary location for smart container storage and pallet jack access:
						{secondaryLocation}
					</ListItem>
				)}
				<ListItem>
					• Furnish and install epoxy to floor beneath machine placement(s) with 1-foot buffer
					(if concerned with damage to concrete)
				</ListItem>
				<ListItem>
					• If floor beneath machine placement(s) is sloped at greater than 2% grade, furnish
					and install a level concrete pad for the machine to be placed on
				</ListItem>
			</List>
		</Stack>
	);
};

export default SpaceReady;
