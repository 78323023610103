import React from 'react';
import { Stack, Typography } from '@mui/material';

const KPI = ({ label, value, description }) => {
	return (
		<Stack
			direction='column'
			sx={{
				alignItems: 'center',
				justifyContent: 'center',
				gap: '0.5rem',
				py: '1rem',
			}}>
			<Typography variant='p' fontWeight={'500'}>
				{label}
			</Typography>
			<Typography variant='h4' fontWeight={'600'}>
				{value}
			</Typography>
			<Typography variant='p2' fontWeight={'300'}>
				{description}
			</Typography>
		</Stack>
	);
};

export default KPI;
