import React, { useContext, useEffect, useState } from 'react';
import KPI from './KPI';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getAllTimeCarbonOffsets } from '../../../../functions/getAllTimeCarbonOffsets';
import icon from '../../../../../../assets/carbonSequestered.png';
import { Paper, Tooltip, useTheme } from '@mui/material';
import { getStyles } from './styles';

const EquivalentC02 = () => {
	const [carbonOffsets, setCarbonOffsets] = useState();

	const { organization } = useContext(OrganizationContext);

	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeCarbonOffsets(organization.org);
			setCarbonOffsets(response);
		}
		fetchData();
	}, []);

	return (
		<Paper style={styles.paper}>
			<Tooltip title='Equivalent tons of CO₂ Sequestered' placement='bottom'>
				<div style={styles.innerDiv}>
					<img src={icon} alt='' style={{ width: '42px' }} />
					<KPI label='Equivalent CO₂ Sequestered' value={carbonOffsets} unit='tons'></KPI>
				</div>
			</Tooltip>
		</Paper>
	);
};

export default EquivalentC02;
