import React from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordConfirmation = () => {
	let navigate = useNavigate();

	const handleBackToLogin = () => {
		// Navigate back to the sign-in page
		navigate('/sign-in');
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
				padding: '20px',
			}}>
			<Typography variant='h4' style={{ marginBottom: '20px' }}>
				Check Your Email
			</Typography>
			<Typography variant='body1' style={{ marginBottom: '20px' }}>
				We have sent a password reset link to the email address associated with your account.
				Please check your email and follow the instructions to reset your password.
			</Typography>
			<Typography variant='body2' style={{ marginBottom: '20px' }}>
				If you don't receive the email within a few minutes, please check your spam or junk
				folder.
			</Typography>
			<Button variant='contained' onClick={handleBackToLogin}>
				Back to Login
			</Button>
		</div>
	);
};

export default ForgotPasswordConfirmation;
