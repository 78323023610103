import {
	Box,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getStyles } from './styles';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getOrgsByIds } from '../../../../functions/getOrgsByIds';
import { getCoverIssues } from '../../../../functions/getCoverIssues';
import { Circle } from '@mui/icons-material';

const Compliance2 = () => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { organization } = useContext(OrganizationContext);
	const [orgNames, setOrgNames] = useState([{}]);

	useEffect(() => {
		async function fetchData() {
			if (organization?.children?.length > 0) {
				const response = await getOrgsByIds(organization.children, false);

				const rowsData = await Promise.all(
					response.map(async (org, index) => {
						const coversUpToDate = await getCoverIssues(org._id);

						return {
							id: index + 1,
							org: org.org,
							coversUpToDate: !org.hasCovers
								? 'N/A'
								: coversUpToDate.length === 0
								? 'UP TO DATE'
								: 'NEEDS UPDATE',
						};
					})
				);

				setOrgNames(rowsData);
			}
		}

		fetchData();
	}, [organization]);

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				flexDirection: 'column',
				gap: '1rem',
			}}>
			<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
				Compliance
			</Typography>
			<div style={{ display: 'flex', width: '100%' }}>
				<Paper style={styles.paper}>
					<TableContainer style={{ borderRadius: '1rem 1rem 1rem 1rem' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Organization Name</TableCell>
									<TableCell align='right'>Covers</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orgNames?.map((child, index) => (
									<TableRow
										key={index}
										sx={{
											'&:last-child td, &:last-child th': { border: 0 },
										}}>
										<TableCell component='th' scope='row'>
											{child.org}
										</TableCell>
										<TableCell
											component='th'
											scope='row'
											align='right'
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											{child.coversUpToDate !== 'N/A' && (
												<Circle
													sx={{
														height: '15px',
														color:
															child.coversUpToDate === 'UP TO DATE'
																? '#1aa11b'
																: '#f63065',
													}}
												/>
											)}
											{child.coversUpToDate}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</div>
		</div>
	);
};

const FutureCompliance = () => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const { organization } = useContext(OrganizationContext);
	const [orgNames, setOrgNames] = useState([{}]);

	useEffect(() => {
		async function fetchData() {
			if (organization?.children?.length > 0) {
				const response = await getOrgsByIds(organization.children, false);

				// Use Promise.all to wait for all promises from the map to resolve
				const rowsData = await Promise.all(
					response.map(async (org, index) => {
						const coversUpToDate = await getCoverIssues(org._id);

						return {
							id: index + 1,
							org: org.org,
							coversUpToDate: coversUpToDate.length === 0 ? 'UP TO DATE' : 'NEEDS UPDATE',
							// topUser: topUser ? topUser.FIRST + ' ' + topUser.LAST : 'N/A',
						};
					})
				);

				setOrgNames(rowsData);
			}
		}

		fetchData();
	}, [organization]);

	return (
		<Stack direction='column' gap='1rem'>
			<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
				Compliance
			</Typography>
			<Box>
				<Paper style={styles.paper}>
					<TableContainer style={{ borderRadius: '1rem 1rem 1rem 1rem' }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Organization Name</TableCell>
									<TableCell align='right'>Property Score</TableCell>
									<TableCell align='right'>Downtime</TableCell>
									<TableCell align='right'>Utilization</TableCell>
									<TableCell align='right'>Covers</TableCell>
									<TableCell align='right'>ZFWE</TableCell>
									{/* <TableCell align='right'>Covers</TableCell> */}
								</TableRow>
							</TableHead>
							<TableBody>
								{orgNames?.map((child, index) => (
									<TableRow
										key={index}
										sx={{
											'&:last-child td, &:last-child th': { border: 0 },
										}}>
										<TableCell component='th' scope='row'>
											{child.org}
										</TableCell>

										<TableCell align='right' component='th' scope='row'>
											100%
										</TableCell>
										<TableCell align='right' component='th' scope='row'>
											25%
										</TableCell>
										<TableCell align='right' component='th' scope='row'>
											25%
										</TableCell>
										<TableCell align='right' component='th' scope='row'>
											25%
										</TableCell>
										<TableCell align='right' component='th' scope='row'>
											25%
										</TableCell>
										{/* <TableCell
											component='th'
											scope='row'
											align='right'
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											<Circle
												sx={{
													padding: '5px',
													height: '15px',
													color:
														child.coversUpToDate === 'UP TO DATE'
															? '#1aa11b'
															: '#f63065',
												}}
											/>
											{child.coversUpToDate}
										</TableCell> */}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Box>
		</Stack>
	);
};

export default Compliance2;
