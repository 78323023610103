import debounce from 'lodash/debounce';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { addTranslation } from './addTranslation';

const apiKey = 'IlOVNcFbaUcbAp1f20OcsQ';
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

const addTranslationDebounced = debounce((key, lngs, ns) => {
	addTranslation(key).then(() => {
		i18next.reloadResources(lngs, ns, () => {});
	});
}, 300); // Adjust the delay (300ms) as needed

i18next
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		ns: ['default'],
		defaultNS: 'default',
		supportedLngs: ['en', 'es'],
		addPath: `https://api.i18nexus.com/project_resources/base_strings`,
		backend: {
			loadPath: loadPath,
		},
		saveMissing: true,
		missingKeyHandler: function (lngs, ns, key, fallbackValue, updateMissing, options) {
			console.log(`update missing key: ${key}`);
			if (key !== '') {
				addTranslationDebounced(key, lngs, ns);
			}
		},
	});
