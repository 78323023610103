import axios from 'axios';

export const getMachineImage = async (organization) => {
	const encodedOrganization = encodeURIComponent(organization);

	const url = `/.netlify/functions/getMachineImage?organization=${encodedOrganization}`;

	try {
		const response = await axios.get(url);
		if (response) {
			const base64Image = response.data;
			return base64Image;
		} else {
			console.log('No data received');
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
};
