import { Grid, Paper, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import UsersTable from './UsersTable';
import styles from './styles';
import AddUpdateUser from './AddUpdateUser';
import { getAggregateUsers } from '../../functions/getAggregateUsers';

const Users = () => {
	const { organization } = useContext(OrganizationContext);

	const theme = useTheme();
	const classes = styles(theme);

	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const response = await getAggregateUsers(organization.org);
			if (response && response.users) {
				setUsers(response.users); // Assuming the response has a data property that is an array of users
			}
		}
		fetchData();
	}, [organization]);

	const refreshUsers = () => {
		async function fetchData() {
			const response = await getAggregateUsers(organization.org);
			if (response && response.users) {
				setUsers(response.users); // Assuming the response has a data property that is an array of users
			}
		}
		fetchData();
	};

	return (
		<Paper sx={classes.canvas}>
			<div style={classes.body}>
				<Title title={'User Management'} />
				<UsersTable
					users={users}
					classes={classes}
					selectedUser={selectedUser}
					setSelectedUser={setSelectedUser}
				/>
				<Grid container sx={classes.row}>
					<Grid item xs={12} padding='1rem'>
						<AddUpdateUser
							selectedUser={selectedUser}
							setSelectedUser={setSelectedUser}
							refreshUsers={refreshUsers}
						/>
					</Grid>
				</Grid>
			</div>
		</Paper>
	);
};

const Title = ({ title }) => {
	return (
		<Typography variant='h3' fontWeight='600'>
			{title}
		</Typography>
	);
};

export default Users;
