import React, { useState, useContext, useEffect } from 'react';
import { Typography, useTheme, Box } from '@mui/material';
import styles from './styles';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getActiveReminders } from '../../functions/getActiveReminders';
import ReminderIcon from '../../../../assets/icons/bell icon.png';
import { useTranslation } from 'react-i18next';

const Reminders = () => {
	const theme = useTheme();
	const classes = styles(theme);

	const [reminders, setReminders] = useState([]);
	const [currentReminderIndex, setCurrentReminderIndex] = useState(0);

	const { organization } = useContext(OrganizationContext);
	const { t } = useTranslation();

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getActiveReminders(organization._id);
				if (Array.isArray(response)) {
					setReminders(response);
				} else {
					setReminders([]);
				}
			} catch (error) {
				console.error('Error fetching reminders:', error);
				setReminders([]);
			}
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, [organization]);

	useEffect(() => {
		if (reminders.length > 0) {
			const interval = setInterval(() => {
				setCurrentReminderIndex((prevIndex) => (prevIndex + 1) % reminders.length);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [reminders]);

	return (
		<div style={classes.digestionTankStatusCard}>
			<div style={classes.cardTitle}>
				<Typography variant='h5' fontWeight={'bold'}>
					{t('Reminders')}
				</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					margin: '1rem',
				}}>
				<Box style={classes.iconImageContainer}>
					<img src={ReminderIcon} alt='Reminder' style={classes.iconImage} />
				</Box>
				<div style={classes.textContainer}>
					{reminders.length > 0 ? (
						<>
							<Typography variant='h6' fontWeight='bold' textAlign='center'>
								{t(reminders[currentReminderIndex].celebrating)}
							</Typography>
							<Typography variant='h6' textAlign='center'>
								{t(reminders[currentReminderIndex].message)}
							</Typography>
							<Typography variant='h6' textAlign='center'>
								{t(reminders[currentReminderIndex].subMessage)}
							</Typography>
						</>
					) : (
						<Typography variant='h6' textAlign='center'>
							{t('Reminders Coming Soon')}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
};

export default Reminders;
