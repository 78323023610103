import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import bg_logo from '../../../assets/bg_logo.png';
import {
	buttonStyles,
	errorMessageStyle,
	inputField,
	signInContainer,
	signInForm,
} from './styles.jsx';
import { resetPassword } from '../../CustomerDashboard/functions/resetPassword';

const ResetPassword = () => {
	let navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('token');

	useEffect(() => {
		if (token) {
			// You now have access to the token
		} else {
			// if no token or token cannot be found in globaUsers, return to sign in page or say you do not have permission to access this page click to return to sign in
		}
	}, [token]);

	const handleResetPassword = async () => {
		if (password !== confirmPassword) {
			setErrorMessage("Passwords don't match.");
			return;
		}
		try {
			const response = await resetPassword(token, password);
			if (response.statusCode === 200) {
				navigate('/sign-in');
			} else {
				// Use the message from the response for user feedback
				setErrorMessage(response.message || 'An error occurred. Please try again later.');
			}
		} catch (error) {
			setErrorMessage('An error occurred. Please try again later.');
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleResetPassword();
		}
	};

	return (
		<div style={signInContainer}>
			<form style={signInForm} onSubmit={(e) => e.preventDefault()}>
				<img src={bg_logo} alt='Logo' className='glogo' style={{ maxWidth: '80vw' }} />

				<div
					style={{
						height: '70%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
					}}>
					<h3>Password</h3>
					<input
						type='password'
						style={inputField}
						placeholder='Choose password'
						autoComplete='new-password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onKeyPress={handleKeyPress}
					/>
					<input
						type='password'
						style={inputField}
						placeholder='Confirm password'
						autoComplete='new-password'
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						onKeyPress={handleKeyPress}
					/>

					{errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}

					<Button variant='contained' onClick={handleResetPassword} sx={buttonStyles}>
						Reset Password
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;
