import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Stack, Typography } from '@mui/material';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getTopUsersInDateRange } from '../../../../../../functions/getTopUsersInDateRange';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const TopUsersBarChart = ({ startDate, endDate }) => {
	const [feedWeights, setFeedWeights] = useState([]);
	const [users, setUsers] = useState([]);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getTopUsersInDateRange(organization._id, startDate, endDate);
			if (response.users && response.weights) {
				setUsers(response.users);
				setFeedWeights(response.weights);
			}
		}
		if (endDate && organization && startDate) {
			fetchData();
		}
	}, [endDate, organization, startDate]);

	const data = {
		labels: users,
		datasets: [
			{
				label: 'Food Recycled (lbs)',
				data: feedWeights,
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				borderColor: 'rgba(53, 162, 235, 0.8)',
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			datalabels: {
				display: true,
				color: 'black',
				align: 'end',
				anchor: 'end',
				formatter: (value) => value.toFixed(0), // Limit to 1 decimal place
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Food Recycled (lbs)',
				},
			},
			x: {
				title: {
					display: true,
					text: 'Users',
				},
			},
		},
		maintainAspectRatio: false, // Maintain aspect ratio
	};

	return (
		<Stack
			style={{
				height: '25vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h5' fontWeight={600} alignSelf='flex-start'>
				Top Users
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Bar data={data} options={options} />
			</Box>
		</Stack>
	);
};

export default TopUsersBarChart;
