import React, { useEffect } from 'react';
import { Typography, useTheme } from '@mui/material';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const EventStatus = () => {
	const theme = useTheme();
	const classes = styles(theme);
	const { t } = useTranslation();

	return (
		<div style={classes.upcomingEventsCard}>
			<div style={classes.cardTitle}>
				<Typography variant='h5' fontWeight={'bold'}>
					{t('Upcoming Events')}
				</Typography>
			</div>
			<Typography variant='h6' style={{ flex: 1, paddingLeft: '25vw' }}>
				{/* Add padding to the left */}
				{t('No Upcoming Events Logged')}
			</Typography>
		</div>
	);
};

export default EventStatus;
