import { FormControl, TextField } from "@mui/material";
import React from "react";

const FinalizeAttendees = ({ attendees, setAttendees }) => {
  return (
    <div>
      <FormControl fullWidth={true}>
        {/* <label>
				Attendees *
				<input
					type='number'
					value={attendees}
					onChange={(e) => setAttendees(e.target.value)}
					required
					style={{ height: '2rem' }}
				/>
			</label> */}

        <TextField
          type="number"
          label="Attendees"
          value={attendees}
          onChange={(e) => setAttendees(e.target.value)}
          required
        ></TextField>
      </FormControl>
    </div>
  );
};

export default FinalizeAttendees;
