import React, { useContext, useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getOutletCoversByMonthAndYear } from '../../../../functions/getOutletCoversByMonthAndYear';
import { ProjectedTable } from './ProjectedTable';
import HeaderSection from './HeaderSection';
import { createNewProjDocs } from '../../../../functions/createNewProjDocs';
import { UserLocationContext } from '../../../../../../context/UserLocationContext';

const Projected = () => {
	const { organization } = useContext(OrganizationContext);
	const { userLocation } = useContext(UserLocationContext);

	const [outlets, setOutlets] = useState();
	const [selectedYear, setSelectedYear] = useState('');
	const [selectedMonth, setSelectedMonth] = useState('');

	useEffect(() => {
		async function fetchData() {
			const response = await getOutletCoversByMonthAndYear(
				organization._id,
				selectedMonth,
				selectedYear
			);
			setOutlets(response);

			if (selectedMonth !== '' && selectedYear !== '' && response.length === 0) {
				await createNewProjDocs(
					userLocation.locationName,
					organization,
					selectedMonth,
					selectedYear
				);
				// Re-fetch the outlet data after adding new documents
				const updatedResponse = await getOutletCoversByMonthAndYear(
					organization._id,
					selectedMonth,
					selectedYear
				);
				setOutlets(updatedResponse);
			}
		}
		fetchData();
	}, [organization, selectedMonth, selectedYear, userLocation.locationName]);

	// updates year based on dropdown selection
	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
	};

	// updates month based on dropdown selection
	const handleMonthChange = (event) => {
		setSelectedMonth(event.target.value);
	};

	// returns an array of years from 2021 - current
	const generateYearRange = () => {
		const currentYear = new Date().getFullYear() + 1;
		const years = [];
		for (let year = 2021; year <= currentYear; year++) {
			years.push(year);
		}
		return years;
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '95%',
				height: '100%',
				gap: '1rem',
				marginTop: '1rem',
				marginBottom: '1rem',
				alignItems: 'center',
			}}>
			{/* title and bullet list */}
			<HeaderSection />

			<Grid container direction='row' width='50%' justifyContent='space-evenly'>
				{/* year selector */}
				<Grid item xs={4}>
					<FormControl fullWidth>
						<InputLabel id='demo-simple-select-label'>Year</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							label='Year'
							value={selectedYear}
							onChange={handleYearChange}>
							{generateYearRange().map((year) => (
								<MenuItem key={year} value={year.toString()}>
									{year}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				{/* month selector */}
				<Grid item xs={4}>
					<FormControl fullWidth>
						<InputLabel id='demo-simple-select-label'>Month</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							label='Month'
							value={selectedMonth}
							onChange={handleMonthChange}>
							<MenuItem value={1}>January</MenuItem>
							<MenuItem value={2}>February</MenuItem>
							<MenuItem value={3}>March</MenuItem>
							<MenuItem value={4}>April</MenuItem>
							<MenuItem value={5}>May</MenuItem>
							<MenuItem value={6}>June</MenuItem>
							<MenuItem value={7}>July</MenuItem>
							<MenuItem value={8}>August</MenuItem>
							<MenuItem value={9}>September</MenuItem>
							<MenuItem value={10}>October</MenuItem>
							<MenuItem value={11}>November</MenuItem>
							<MenuItem value={12}>December</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			{/* table */}
			<ProjectedTable
				organization={organization}
				outlets={outlets}
				year={selectedYear}
				month={selectedMonth}
			/>
		</div>
	);
};

export default Projected;
