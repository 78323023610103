import React from 'react';
import poweredByBioGreen from '../../../../assets/PoweredByBioGreen.svg';
import AccountMenu from '../../../AggregateDashboard/components/NavBar/AccountMenu';
import { Paper, Stack } from '@mui/material';

const TopBar = () => {
	return (
		<Stack
			component={Paper}
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			sx={{
				zIndex: 10000,
				boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
				position: 'sticky',
				top: 0,
			}}
			px={1}>
			<img src={poweredByBioGreen} alt='' style={{ margin: '0.5rem', height: '3rem' }} />
			<AccountMenu />
		</Stack>
	);
};

export default TopBar;
