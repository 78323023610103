import axios from 'axios';

export const createNewProjDocs = async (userLocation, organization, month, year) => {
	const encodedUserLocation = encodeURIComponent(userLocation);
	const encodedOrganization = encodeURIComponent(organization.org);
	const url = `/.netlify/functions/createNewProjDocs?userLocation=${encodedUserLocation}&organization=${encodedOrganization}&orgId=${organization._id}&month=${month}&year=${year}`;

	let data = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
