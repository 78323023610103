import { FormControl, TextField } from "@mui/material";
import React from "react";

const FinalizeBreakfasts = ({ breakfasts, setBreakfasts }) => {
  return (
    <div className="">
      {/* <label>
				Breakfasts *
				<input
					type='number'
					value={breakfasts}
					onChange={(e) => setBreakfasts(e.target.value)}
					required
				/>
			</label> */}
      <FormControl fullWidth={true}>
        <TextField
          type="number"
          label="Breakfasts Served"
          value={breakfasts}
          onChange={(e) => setBreakfasts(e.target.value)}
          required
        ></TextField>
      </FormControl>
    </div>
  );
};

export default FinalizeBreakfasts;
