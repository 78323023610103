import './App.css';
import RoutesComponent from './pages/RoutesComponent';
import React from 'react';
import { ThemeContextProvider } from './context/ThemeContext';

function App() {
	return (
		<ThemeContextProvider>
			<div className='App'>
				<RoutesComponent />
			</div>
		</ThemeContextProvider>
	);
}

export default App;
