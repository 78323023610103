import { Chip, Grid, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import AcresFertilized from './components/AcresFertilized';
import HomesPowered from './components/HomesPowered';
import MilesDriven from './components/MilesDriven';
import TreesPlanted from './components/TreesPlanted';
import { getStyles } from './styles';

const AllTimeCarbonOffsetEquivalents = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	return (
		<Stack direction='column' gap='1rem'>
			<Stack
				direction={{ xs: 'column', lg: 'row' }} // 'column' for xs to md breakpoints, 'row' for lg and above
				alignItems={'center'}
				gap='1rem'
				style={{
					padding: '0.5rem 1rem 0.5rem 1rem',
				}}>
				<Typography variant='h5' fontWeight='600' textAlign='center'>
					All-Time Carbon Offset Equivalents
				</Typography>
				<Tooltip title='Open in new tab' placement='bottom'>
					<Chip
						variant='outlined'
						label='US EPA Equivalencies References'
						sx={styles.chip}
						onClick={() =>
							window.open(
								'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references',
								'_blank'
							)
						}></Chip>
				</Tooltip>
			</Stack>
			<Grid container spacing={'1rem'}>
				<GridItem styles={styles}>
					<AcresFertilized />
				</GridItem>
				<GridItem styles={styles}>
					<TreesPlanted />
				</GridItem>
				<GridItem styles={styles}>
					<HomesPowered />
				</GridItem>
				<GridItem styles={styles}>
					<MilesDriven />
				</GridItem>
			</Grid>
		</Stack>
	);
};

const GridItem = ({ children, styles }) => (
	<Grid item xs={12} md={6} lg={3}>
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>{children}</div>
		</Paper>
	</Grid>
);

export default AllTimeCarbonOffsetEquivalents;
