import React, { useEffect, useState } from 'react';
import { getStyles } from './styles';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { getNumUsersActive } from '../../../functions/getNumUsersActive';
import { getNumUsersTotal } from '../../../functions/getNumUsersTotal';

const ActiveUsers = ({ orgID }) => {
	const theme = useTheme();
	const styles = getStyles(theme);
	const [numActiveUsers, setNumActiveUsers] = useState(0);
	const [numTotalUsers, setNumTotalUsers] = useState(0);

	useEffect(() => {
		async function fetchData() {
			const numActive = await getNumUsersActive(orgID);
			const numTotal = await getNumUsersTotal(orgID);
			setNumActiveUsers(numActive);
			setNumTotalUsers(numTotal);
		}
		if (orgID) {
			fetchData();
		}
	}, [orgID]);

	return (
		<div style={styles.gridItemStyle}>
			<Typography variant='h5'>Active Users</Typography>
			<Typography variant='h4' fontWeight={600} padding={'1rem'}>
				{numActiveUsers} / {numTotalUsers} total
			</Typography>
			<Typography variant='p'>Last 30 Days</Typography>
		</div>
	);
};

export default ActiveUsers;
