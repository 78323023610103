import axios from 'axios';

export const getAllTimeCarbonOffsetsAggregate = async (orgID) => {
	const url = `/.netlify/functions/getAllTimeCarbonOffsetsAggregate?orgID=${orgID}`;
	var data = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
