import { Stack, Typography } from '@mui/material';
import technical_cutsheet from '../../../../../../assets/CALIBER SMART CONTAINER TECHNICAL CUTSHEET v1.pdf';

const ContainerCutsheet = () => {
	return (
		<Stack mx={5}>
			<Typography variant='h4' fontWeight='600'>
				Container Technical Cutsheet
			</Typography>
			<Typography variant='h6'>Review the container technical cutsheet</Typography>
			<iframe
				title='technical cutsheet'
				src={technical_cutsheet}
				style={{ width: '100%', height: '1125px', marginTop: '5rem' }}
			/>
		</Stack>
	);
};

export default ContainerCutsheet;
