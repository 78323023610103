import React, { useEffect, useState } from 'react';
import { getStyles } from './styles';
import { getDiversionbyCategoryLast30Days } from '../../../../CustomerDashboard/functions/getDiversionbyCategoryLast30Days';
import { Typography, useTheme } from '@mui/material';
import { Pie } from 'react-chartjs-2';

const OutletBreakdown = ({ orgID }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Percentage',
				data: [],

				borderWidth: 1,
			},
		],
	});

	useEffect(() => {
		async function fetchData() {
			const response = await getDiversionbyCategoryLast30Days(orgID);
			if (response && response.departments) {
				const labels = [];
				const data = [];
				const backgroundColor = [];
				const borderColor = [];

				Object.keys(response.departments).forEach((key) => {
					const department = response.departments[key];
					labels.push(department.title);
					data.push(parseFloat(department.percentage));
					// Prepare color - assume color is in HEX format, add 'rgba' wrapper
					const color = `#${department.color}`;
					backgroundColor.push(color);
					borderColor.push(color);
				});

				setChartData((prevState) => ({
					...prevState,
					labels: labels,
					datasets: [
						{
							...prevState.datasets[0],
							data: data,
							backgroundColor: backgroundColor,
							borderColor: borderColor,
						},
					],
				}));
			}
		}
		if (orgID) {
			fetchData();
		}
	}, [orgID]);
	// Define chart options
	const options = {
		plugins: {
			legend: {
				position: 'right', // Position the legend on the right
			},
		},
	};

	return (
		<div style={styles.gridItemStyle}>
			<Typography variant='h5'>Outlet Breakdown</Typography>
			<div>
				<Pie data={chartData} options={options} />
			</div>
		</div>
	);
};

export default OutletBreakdown;
