import { FormControl, TextField } from "@mui/material";
import React from "react";

const FinalizeLunches = ({ lunches, setLunches }) => {
  return (
    <div>
      {/* <label>
				Lunches  *
				<input
					type='number'
					value={lunches}
					onChange={(e) => setLunches(e.target.value)}
					required
				/>
			</label> */}
      <FormControl fullWidth={true}>
        <TextField
          type="number"
          label="Lunches Served"
          value={lunches}
          onChange={(e) => setLunches(e.target.value)}
          required
        ></TextField>
      </FormControl>
    </div>
  );
};

export default FinalizeLunches;
