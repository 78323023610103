import axios from 'axios';

export const getTopUsersLast30Days = async (organization) => {
	// const url = `http://localhost:5000/getMilesDriven/${organization}`;
	const url = `/.netlify/functions/getTopUsersLast30Days?organization=${organization}`;

	var topUsersLast30Days = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				topUsersLast30Days = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return topUsersLast30Days;
};
