import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getAvailabilityLast30Days } from '../../../../../../../../functions/getAvailabilityLast30Days';

const AvailiabilityLast30Days = () => {
	const [value, setValue] = useState(0);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAvailabilityLast30Days(organization.org);
			setValue(response);
		}
		fetchData();
	}, [organization]);
	return (
		<div
			style={{
				width: '100%',
				// border: '1px solid black',
				padding: '0.5rem',
			}}>
			<KPI
				label='Availability Last 30 Days'
				value={value + '%'}
				description='% of time the machine is ready to be fed'
			/>
		</div>
	);
};

export default AvailiabilityLast30Days;
