export const getStyles = (theme) => ({
	paper: {
		borderRadius: '1rem',
		border: `1px solid ${theme.palette.border.main}`,
		// boxShadow: 'none',
		boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 2px -2px rgba(0, 0, 0, 0.1)', // More subtle soft shadow
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	innerDiv: {
		padding: '2rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
});

export const rowElement = {
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	margin: '10px',
	textAlign: 'center',
};
