import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, useTheme } from '@mui/material';
import MachinePhoto from './components/MachinePhoto/MachinePhoto';
import UptimeLast30Days from './components/UptimeLast30Days/UptimeLast30Days';
import AvailiabilityLast30Days from './components/AvailabilityLast30Days/AvailiabilityLast30Days';
import FeedTimeOfDayLast30Days from './components/FeedTimeOfDayLast30Days/FeedTimeOfDayLast30Days';
import { getStyles } from './styles';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getMachineImage } from '../../../../../../functions/getMachineImage';

const Card1 = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [image, setImage] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await getMachineImage(organization.org);
				setImage(response.imageUrl);
			} catch (error) {
				console.error('Error fetching machine image:', error);
			}
		}
		fetchData();
	}, [organization]);

	return (
		<Grid container spacing={'1rem'}>
			<GridItem styles={styles}>
				<UptimeLast30Days />
			</GridItem>
			<GridItem styles={styles}>
				<AvailiabilityLast30Days />
			</GridItem>
			<Grid item xs={12} md={12} lg={4}>
				<Paper style={styles.paper}>
					<MachinePhoto />
				</Paper>
			</Grid>
			<Grid item xs={12} md={12} lg={8}>
				<Paper style={styles.paper}>
					<div style={styles.innerDiv}>
						<FeedTimeOfDayLast30Days />
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

const GridItem = ({ children, styles }) => (
	<Grid item xs={12} md={12} lg={6}>
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>{children}</div>
		</Paper>
	</Grid>
);

export default Card1;
