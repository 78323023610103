import {
	Avatar,
	Box,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import React, { useContext, useState } from 'react';
import Logout from '@mui/icons-material/Logout';
import { UserContext } from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { UserLocationContext } from '../../../../context/UserLocationContext';

const AccountMenu = () => {
	const navigate = useNavigate();
	const { user, setUser } = useContext(UserContext);
	const { setOrganization } = useContext(OrganizationContext);
	const { setUserLocation } = useContext(UserLocationContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title='Account'>
					<IconButton
						onClick={handleClick}
						size='small'
						sx={{ ml: 2 }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}>
						<Avatar sx={{ width: 32, height: 32, background: '#cad2d3' }}>
							{/* {user.email[0].toUpperCase()} */}
							<PersonIcon fontSize='small' />
						</Avatar>
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				{user?.email && <MenuItem onClick={handleClose}>{user.email}</MenuItem>}
				{user?.organization === 'BioGreen360' && (
					<MenuItem
						sx={{ color: '#f6685b', fontWeight: '500' }}
						onClick={() => {
							setOrganization();
							navigate('/org-select');
						}}>
						Change Organization
					</MenuItem>
				)}
				<MenuItem
					sx={{ color: '#f6685b', fontWeight: '500' }}
					onClick={() => {
						setUser();
						setUserLocation();
						setOrganization();
						navigate('/');
					}}>
					Sign Out
				</MenuItem>
			</Menu>
		</>
	);
};

export default AccountMenu;
