import { Stack, Typography, TextField, Button } from '@mui/material';
import React, { useState } from 'react';

const Rooms = () => {
	const [rooms, setRooms] = useState('');

	const handleChange = (event) => {
		setRooms(event.target.value);
	};

	const handleSubmit = () => {
		console.log(`Number of rooms: ${rooms}`);
	};

	return (
		<TextField
			label='Number of Rooms'
			variant='outlined'
			type='number'
			fullWidth
			value={rooms}
			onChange={handleChange}
		/>
	);
};

export default Rooms;
