import axios from 'axios';

export const forgotPassword = async (email) => {
	const url = `/.netlify/functions/forgotPassword`;
	let data = 0;

	try {
		const response = await axios.post(url, { email });
		if (response != null && response !== false) {
			data = response;
		} else {
			console.log('Password reset request failed');
		}
	} catch (err) {
		console.log('Error during password reset request:', err);
	}

	return data;
};
