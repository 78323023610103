import React, { useContext, useEffect, useRef, useState } from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getStyles } from '../styles';
import { getCoverCountsAggregate } from '../../../../functions/getCoverCountsAggregate';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

const CoversAggregate = ({ coverCounts }) => {
	const theme = useTheme();
	const [chartData, setChartData] = useState({});
	const chartRef = useRef(null);

	const styles = getStyles(theme);

	useEffect(() => {
		processChartData(coverCounts);
	}, [coverCounts]);

	const generateLast12MonthsLabels = () => {
		const labels = [];
		const date = new Date();
		for (let i = 11; i >= 0; i--) {
			const monthDate = new Date(date.getFullYear(), date.getMonth() - i, 1);
			labels.push(`${monthDate.getFullYear()}-${String(monthDate.getMonth() + 1)}`);
		}
		return labels;
	};

	const processChartData = (averagesPerMonth) => {
		if (averagesPerMonth) {
			const labels = generateLast12MonthsLabels();

			// You only need one dataset since you're aggregating across all organizations
			const data = labels.map((date) => {
				// Correct the date formatting to match the keys in averagesPerMonth
				const formattedDate = `${date.split('-')[0]}-${date.split('-')[1].padStart(2, '0')}`;
				// Access the average value directly using the formattedDate
				return averagesPerMonth[formattedDate] || null;
			});

			const datasets = [
				{
					label: 'Aggregate Covers',
					// tension: 0.1,
					pointRadius: 1,
					// pointColor: 'transparent',
					// borderJoinStyle: 'round',
					data: data,
					fill: {
						target: 'origin', // Set the fill options
					},
					borderColor: 'rgb(75, 192, 192)',
					backgroundColor: 'rgba(75, 192, 192, 0.5)',
				},
			];

			setChartData({
				labels: labels.map(
					(label) => `${label.split('-')[0]}-${label.split('-')[1].padStart(2, '0')}`
				), // Ensure labels are formatted correctly
				datasets,
			});
		}
	};

	useEffect(() => {
		if (chartRef.current && chartRef.current.ctx) {
			const ctx = chartRef.current.ctx;
			const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight);
			gradient.addColorStop(0, 'rgba(75, 192, 192, 0.8)');
			gradient.addColorStop(1, 'rgba(75, 192, 192, 0)');

			const chartInstance = chartRef.current;
			chartInstance.data.datasets.forEach((dataset) => {
				dataset.backgroundColor = gradient; // Apply gradient to background color
			});
			chartInstance.update();
		}
	}, [chartData]);

	const options = {
		responsive: true,
		aspectRatio: 4, // Default is 2 (2:1), setting to a higher value will make the chart "shorter"

		scales: {
			x: {
				grid: {},
				title: {
					display: true,
					text: 'Date', // Add your x-axis label text here
					font: {
						size: 14, // Optional: choose a font size
					},
				},
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Covers', // Add your y-axis label text here
					font: {
						size: 14, // Optional: choose a font size
					},
				},
			},
		},
	};

	return (
		<Stack direction='row' gap='1rem' sx={{ width: '100%' }}>
			<Paper style={styles.paper}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '2rem',
						alignItems: 'center',
						gap: '1rem',
					}}>
					{chartData && chartData.labels?.length > 0 ? (
						<>
							<Typography variant='p'>
								Make sure to keep cover counts up to date to maximize dashboard
								functionality
							</Typography>
							<Line ref={chartRef} data={chartData} options={options} />
						</>
					) : (
						<Typography></Typography>
					)}
				</div>
			</Paper>
		</Stack>
	);
};

export default CoversAggregate;
