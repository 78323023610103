import React, { useState } from 'react';
import {
	Button,
	Stack,
	TextField,
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';

const Outlets = () => {
	const [availableOptions, setAvailableOptions] = useState([
		'Prep Kitchen',
		'Café',
		'Restaurant',
		'Cafeteria',
	]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [newOption, setNewOption] = useState('');

	const handleAddOption = () => {
		if (newOption.trim() && !availableOptions.includes(newOption)) {
			setAvailableOptions([...availableOptions, newOption]);
			setNewOption('');
		}
	};

	const handleMoveToSelected = (option) => {
		setAvailableOptions(availableOptions.filter((item) => item !== option));
		setSelectedOptions([...selectedOptions, option]);
	};

	const handleMoveToAvailable = (option) => {
		setSelectedOptions(selectedOptions.filter((item) => item !== option));
		setAvailableOptions([...availableOptions, option]);
	};

	return (
		<Stack direction='column' mt={10}>
			<Typography variant='h6' fontWeight={500}>
				Select your food waste outlets
			</Typography>
			<Typography variant='body1'>
				i.e. "Prep Kitchen", "Café", "Restaurant", "Cafeteria"...
			</Typography>
			<Stack direction='row' mt={2} gap={2}>
				<TextField
					placeholder='Enter a value'
					value={newOption}
					onChange={(e) => setNewOption(e.target.value)}
					sx={{ width: '250px' }}
				/>
				<Button variant='contained' sx={{ width: '150px' }} onClick={handleAddOption}>
					ADD
				</Button>
			</Stack>
			<Stack direction='row' mt={4} gap={4}>
				<Stack sx={{ width: '100%' }}>
					<Typography variant='h6'>Available Options</Typography>
					<List
						sx={{
							border: '1px solid #ccc',
							borderRadius: '4px',
							width: '100%',
							height: '300px',
							overflow: 'auto',
						}}>
						{availableOptions.map((option, index) => (
							<ListItem key={index}>
								<ListItemText primary={option} />
								<ListItemSecondaryAction>
									<IconButton
										edge='end'
										aria-label='move'
										onClick={() => handleMoveToSelected(option)}>
										<ArrowForward />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</Stack>
				<Stack sx={{ width: '100%' }}>
					<Typography variant='h6'>Selected Options</Typography>
					<List
						sx={{
							border: '1px solid #ccc',
							borderRadius: '4px',
							width: '100%',
							height: '100%',
							overflow: 'auto',
						}}>
						{selectedOptions.map((option, index) => (
							<ListItem key={index}>
								<ListItemText primary={option} />
								<ListItemSecondaryAction>
									<IconButton
										edge='end'
										aria-label='move'
										onClick={() => handleMoveToAvailable(option)}>
										<ArrowBack />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Outlets;
