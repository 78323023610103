import React, { useEffect, useState } from 'react';
import { getStyles } from './styles';
import { Typography, useTheme } from '@mui/material';
import { getAvgFeedWeightLast30Days } from '../../../functions/getAvgFeedWeightLast30Days';

const AvgFeedWeight = ({ orgID }) => {
	const [value, setValue] = useState(0);
	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchData() {
			const response = await getAvgFeedWeightLast30Days(orgID);
			setValue(response);
		}
		if (orgID) {
			fetchData();
		}
	}, [orgID]);

	return (
		<div style={styles.gridItemStyle}>
			<Typography variant='h5'>Avg Feed Weight</Typography>
			<Typography variant='h4' fontWeight={600} padding={'1rem'}>
				{value} Lbs
			</Typography>
			<Typography variant='p'>Last 30 Days</Typography>
		</div>
	);
};

export default AvgFeedWeight;
