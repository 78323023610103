import { Button, Tooltip } from '@mui/material';
import React from 'react';

const SubmitButton = ({ submitButtonDisabled }) => {
	return (
		<Tooltip title='Create Request For Proposal' placement='bottom'>
			<div>
				<Button
					type='submit'
					variant='contained'
					disabled={submitButtonDisabled}
					sx={{ padding: '1rem', paddingLeft: '3rem', paddingRight: '3rem', margin: '3rem' }}>
					Create RFP
				</Button>
			</div>
		</Tooltip>
	);
};

export default SubmitButton;
