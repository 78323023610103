import React, { useContext, useEffect, useState } from 'react';
import KPI from '../../../../../components/Cards/KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getAllTimeFoodRecycled } from '../../../../../functions/getAllTimeFoodRecycled';
import icon from '../../../../../../../assets/foodCycling.png';
import { Stack } from '@mui/material';

const AllTimeFoodRecycled = () => {
	const [allTimeFoodRecycled, setAllTimeFoodRecycled] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeFoodRecycled(organization._id);
			setAllTimeFoodRecycled(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Stack direction='column' alignItems='center'>
			<img src={icon} alt='' style={{ width: '42px', marginBottom: '0.5rem' }} />

			<KPI
				label='All Time Food Recycled'
				value={allTimeFoodRecycled}
				subtext='tons diverted from landfill'></KPI>
		</Stack>
	);
};

export default AllTimeFoodRecycled;
