import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getNumUsersTotal } from '../../../../../../../../functions/getNumUsersTotal';

const NumTotalUsers = () => {
	const [value, setValue] = useState(0);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getNumUsersTotal(organization._id);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Stack
			direction={'column'}
			sx={{
				padding: '1rem',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 1,
			}}>
			<Typography variant='p' fontWeight={'500'}>
				Total Number of Users
			</Typography>
			<Typography variant='h4' fontWeight={'600'}>
				{value}
			</Typography>
		</Stack>
	);
};

export default NumTotalUsers;
