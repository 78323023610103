import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { OrganizationProvider } from './context/OrganizationContext';
import { UserLocationProvider } from './context/UserLocationContext';
import { UserProvider } from './context/UserContext';
import { ZFWEReportPreferencesProvider } from './context/ZFWEReportPreferencesContext';
import './utils/translations/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<OrganizationProvider>
			<UserProvider>
				<UserLocationProvider>
					<ZFWEReportPreferencesProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ZFWEReportPreferencesProvider>
				</UserLocationProvider>
			</UserProvider>
		</OrganizationProvider>
	</React.StrictMode>
);
