import axios from "axios";

export const getHistoricalCovers = async (orgID) => {
  const url = `/.netlify/functions/getHistoricalCovers?orgID=${orgID}`;
  var data = 0;

  await axios
    .get(url)
    .then((res) => {
      if (res.data != null && res.data !== false) {
        data = res.data;
      } else {
        console.log("failed");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return data;
};
