import axios from 'axios';

export const getHomesPoweredForADay = async (organization) => {
	const url = `/.netlify/functions/getHomesPoweredForADayAggregate`;
	let homesPowered = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				homesPowered = res.data;
			} else {
				console.log('Failed to fetch homes powered for a day');
			}
		})
		.catch((err) => {
			console.error('Error fetching homes powered:', err);
		});

	return homesPowered;
};
