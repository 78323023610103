import React, { createContext, useState, useContext, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const loadState = () => {
		try {
			const savedState = localStorage.getItem('user');
			return savedState ? JSON.parse(savedState) : null;
		} catch (error) {
			console.error('Error loading state:', error);
			return {};
		}
	};

	const [user, setUser] = useState(loadState());

	// Effect to save state to local storage when it changes
	useEffect(() => {
		localStorage.setItem('user', JSON.stringify(user || null));
	}, [user]);

	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export const useCustomerLocation = () => {
	return useContext(UserContext);
};
