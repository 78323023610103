import axios from 'axios';

export const getTopUserByOrganization = async (organization) => {
	const url = `/.netlify/functions/getTopUserByOrganization`;
	let topUser = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				topUser = res.data;
			} else {
				console.log('Failed to fetch top user by organization');
			}
		})
		.catch((err) => {
			console.error('Error fetching top user by organization:', err);
		});

	return topUser;
};
