import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './styles';

const MilesDriven = ({ totalAvoidedFoodWaste, reportStatisticText }) => {
	return (
		<View style={styles.statItem}>
			<Text style={{ ...styles.statHeader, color: reportStatisticText }}>Miles Driven</Text>
			<Text style={{ ...styles.statValue, color: reportStatisticText }}>
				{(totalAvoidedFoodWaste * 0.002 * 2483)
					.toFixed(0)
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
				miles
			</Text>
		</View>
	);
};

export default MilesDriven;
