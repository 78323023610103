import axios from 'axios';

export const getLbsFedToday = async (organization) => {
	const url = `/.netlify/functions/getLbsFedToday?organization=${organization}`;

	var lbsFed;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				lbsFed = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return lbsFed;
};

