import { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { styles } from './styles';
import { getLbsFedPerMonthPastYear } from '../../../../../../functions/getLbsFedPerMonthPastYear'; // Renamed for clarity

const FoodWasteByMonth = () => {
	const theme = useTheme();
	const classes = styles(theme);
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Pounds (lbs) Fed Per Month',
				data: [],
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				borderColor: 'rgba(53, 162, 235, 0.8)',
				borderWidth: 1,
			},
		],
	});
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getLbsFedPerMonthPastYear(organization.org); // Corrected function name
			if (response) {
				setChartData({
					labels: response.months,
					datasets: [
						{
							...chartData.datasets[0],
							data: response.weights.map((weight) => parseFloat(weight)),
						},
					],
				});
			}
		}
		fetchData();
	}, [organization, chartData.datasets]);

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Pounds (lbs) Fed',
				},
			},
			x: {
				title: {
					display: true,
					text: 'Month/Year',
				},
			},
		},
		maintainAspectRatio: false, // Maintain aspect ratio
	};

	return (
		<Stack
			sx={{
				height: '25vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h6' fontWeight='600' alignSelf='flex-start'>
				Food Waste By Month (Past Year)
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Bar data={chartData} options={options} />
			</Box>
		</Stack>
	);
};

export default FoodWasteByMonth;
