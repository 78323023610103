import React, { useState, useEffect, useContext, useCallback } from 'react';
import { brandLogo } from './styles.jsx';
import { OrganizationContext } from '../../../../context/OrganizationContext.jsx';
import { getOrganizationImage } from '../../functions/getOrganizationImage.jsx';
import { useDropzone } from 'react-dropzone';
import { Typography, Box, Stack, Button } from '@mui/material';
import { uploadOrgLogo } from '../../functions/uploadOrgLogo.jsx';

export const UploadBrandLogo = () => {
	const [isLoading, setIsLoading] = useState(false); // State to track loading status
	const [previewUrl, setPreviewUrl] = useState(null);

	const { organization, setOrganization } = useContext(OrganizationContext);

	const [logo, setLogo] = useState(null);

	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			const file = acceptedFiles[0];
			setLogo(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
	});

	const clearImage = () => {
		setLogo(null);
		setPreviewUrl(null);
		URL.revokeObjectURL(previewUrl);
	};

	const handleUpload = async () => {
		try {
			await uploadOrgLogo(organization._id, logo);
			setOrganization({
				...organization,
				logo: `${logo.name}-${organization._id}`, // Update this with the actual path used
			});
		} catch (error) {
			console.error('Error uploading logo:', error);
		}
	};

	return (
		<div>
			<Stack direction='column' sx={{ height: '100%' }}>
				<Box
					{...getRootProps()}
					border='2px dashed lightGrey'
					borderRadius='4px'
					height='100%'
					padding='16px'
					textAlign='center'
					display='flex'
					alignItems='center'
					justifyContent='center'
					margin={3}>
					<input {...getInputProps()} />
					{isDragActive ? (
						<Typography>Drop the files here ...</Typography>
					) : (
						!logo && <Typography>Drag & Drop or click to Upload Brand Logo</Typography>
					)}
					{logo && (
						<Box mt={2}>
							<img
								src={previewUrl}
								alt='Selected Logo'
								style={{ width: '200px', marginTop: '16px' }}
							/>
						</Box>
					)}
				</Box>
				{logo && (
					<Stack
						direction='row'
						gap='1rem'
						sx={{
							justifyContent: 'space-evenly',
							margin: '1rem',
						}}>
						<Button
							type='submit'
							variant='contained'
							onClick={handleUpload}
							disabled={!logo}
							sx={{ width: '100%' }}>
							<Typography>Save</Typography>
						</Button>
						<Button
							onClick={clearImage}
							disabled={!logo}
							variant='outlined'
							color='secondary'
							sx={{ width: '100%' }}>
							Cancel
						</Button>
					</Stack>
				)}
			</Stack>
		</div>
	);
};
