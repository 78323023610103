export const getStyles = (theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		gap: '1rem',
		borderRadius: '1rem 1rem 0rem 0rem',
		padding: '0.5rem 1rem 0.5rem 1rem ',
	},
	chip: {
		borderColor: theme.palette.buttonLink.main,
		backgroundColor: 'hsl(125,22%,90%)',
		color: 'hsl(125,22%,50%)',
	},
	paper: {
		gap: '1rem',
		borderRadius: '1rem',
		border: `1px solid ${theme.palette.border.main}`,
		boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)', // More subtle soft shadow
		width: '100%',
		height: '100%',
	},
	innerDiv: {
		display: 'flex',
		height: '100%',

		borderRadius: '1rem 1rem 0rem 0rem',
		padding: '0.5rem 1rem 0.5rem 1rem ',
	},
});
