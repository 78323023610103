import { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Stack, Typography } from '@mui/material';

import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';

import { getLbsFedPerDayInDateRange } from '../../../../../../functions/getLbsFedPerDayInDateRange';

const FoodRecycledBarChart = ({ startDate, endDate }) => {
	const [feedWeights, setFeedWeights] = useState([]);
	const [dates, setDates] = useState([]);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getLbsFedPerDayInDateRange(organization._id, startDate, endDate);
			setDates(response.dates);
			setFeedWeights(response.weights?.map((weight) => parseFloat(weight)));
		}
		fetchData();
	}, [endDate, organization, startDate]);

	const data = {
		labels: dates,
		datasets: [
			{
				label: 'Food Recycled (lbs) Per Day',
				data: feedWeights,
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				borderColor: 'rgba(53, 162, 235, 0.8)',
				borderWidth: 1,
			},
		],
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Food Recycled (lbs)',
				},
			},
			x: {
				title: {
					display: true,
					text: 'Date',
				},
			},
		},
		maintainAspectRatio: false, // Maintain aspect ratio
	};

	return (
		<Stack
			sx={{
				height: '25vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h5' fontWeight={600} alignSelf='flex-start'>
				Food Recycled Per Day
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Bar data={data} options={options} />
			</Box>
		</Stack>
	);
};

export default FoodRecycledBarChart;
