import React, { useContext, useEffect, useState } from 'react';
import KPI from '../../../../../components/Cards/KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getAvgUptimeLast30Days } from '../../../../../functions/getAvgUptimeLast30Days';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Stack, alpha } from '@mui/material';

const AvgUptimeLast30Days = () => {
	const [avgUptimeLast30Days, setAvgUptimeLast30Days] = useState(0);

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAvgUptimeLast30Days(organization);
			setAvgUptimeLast30Days(response);
		}
		fetchData();
	}, []);

	return (
		<Stack direction='column' alignItems='center'>
			<HourglassBottomIcon
				fontSize='large'
				sx={{
					marginBottom: '0.5rem',
					// backgroundColor: alpha('#b5ffc7', 0.4),
					// color: alpha('#b5ffc7', 1),

					borderRadius: '10%',
					padding: '0.5rem',
				}}
			/>
			<KPI
				label='Avg Uptime (Last 30 Days)'
				value={avgUptimeLast30Days + '%'}
				subtext='% of time the machine is on'></KPI>
		</Stack>
	);
};

export default AvgUptimeLast30Days;
