import { Button } from '@mui/material';
import React from 'react';

const BackButton = ({ step, prevStep }) => {
	return (
		<Button
			variant='outlined'
			onClick={() => {
				prevStep(step);
			}}
			sx={{
				width: '10vw',
				// height: '6vh',
				borderColor: '#84be05',
				color: '#84be05',
				'&:hover': {
					borderColor: '#84be05',
				},
			}}>
			Previous
		</Button>
	);
};

export default BackButton;
