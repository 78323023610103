export const getStyles = (theme) => {
	return {
		gridItemStyle: {
			boxSizing: 'border-box', // Ensures padding is included in the element's height

			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-evenly',
			alignItems: 'center',
			backgroundColor: 'white',
			borderRadius: '1rem',
			padding: '1rem',
			border: `1px solid ${theme.palette.border.main}`,
			boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)', // More subtle soft shadow
		},
	};
};
