import React from 'react';
import { Box, Typography, TextField, Button, Stack } from '@mui/material';

const Footer = () => {
	return (
		<Stack
			direction='row'
			sx={{
				position: 'relative',
				mt: 50,
				// justifyContent: 'flex-start',
				gap: '3rem',
				padding: '2rem 2rem 2rem 2rem ',
				backgroundColor: '#7a913b',
				color: '#fff',
			}}>
			<Stack direction='column'>
				<Typography variant='h5' sx={{ color: 'white' }} gutterBottom>
					About
				</Typography>
				<Typography variant='body1' sx={{ color: 'rgba(255,255,255,0.8)' }}>
					BioGreen360 is a disruptive technology that dramatically reduces food waste without
					requiring water.
				</Typography>
				<Typography variant='body2' sx={{ color: 'rgba(255,255,255,0.8)' }} marginTop={5}>
					© 2024 BioGreen360. All rights reserved. Yes, all of them.
				</Typography>
			</Stack>

			<Stack direction='column'>
				<Typography variant='h6' sx={{ color: 'white' }} gutterBottom>
					Contact Us
				</Typography>
				<Typography variant='body1' sx={{ color: 'rgba(255,255,255,0.8)' }}>
					275 Portsmouth Ave, Suite 100
				</Typography>
				<Typography variant='body1' sx={{ color: 'rgba(255,255,255,0.8)' }}>
					Stratham, NH 03885
				</Typography>
				<Typography variant='body1' sx={{ color: 'rgba(255,255,255,0.8)' }}>
					Phone: 603-570-6159
				</Typography>
				<Typography variant='body1' sx={{ color: 'rgba(255,255,255,0.8)' }}>
					Email: info@biogreen360.com
				</Typography>
			</Stack>
		</Stack>
	);
};

export default Footer;
