import React, { useEffect, useState } from 'react';
import { getStyles } from './styles';
import { Typography, useTheme } from '@mui/material';
import { getAvgUtilizationLast30Days } from '../../../../CustomerDashboard/functions/getAvgUtilizationLast30Days';

const Utilization = ({ orgID }) => {
	const [value, setValue] = useState(0);
	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchData() {
			const response = await getAvgUtilizationLast30Days(orgID);
			setValue(response);
		}
		if (orgID) {
			fetchData();
		}
	}, [orgID]);

	return (
		<div style={styles.gridItemStyle}>
			<Typography variant='h5'>Utilization</Typography>
			<Typography variant='h4' fontWeight={600} padding={'1rem'}>
				{value} Lbs / day
			</Typography>
			<Typography variant='p'>Last 30 Days</Typography>
		</div>
	);
};

export default Utilization;
