import { Typography } from '@mui/material';
import React from 'react';

const ComingSoon = () => {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				marginTop: '20%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '2rem',
				textAlign: 'center',
			}}>
			<Typography variant='h1' fontSize={'2.5rem'} fontWeight={900} className='gradientText'>
				Coming Soon
			</Typography>
			<Typography variant='h4' fontWeight={600}>
				We're working hard to bring you something amazing. Stay tuned!
			</Typography>
		</div>
	);
};

export default ComingSoon;
