// styles.js
const styles = (theme) => ({
	leaderboardCard: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between', // This will spread out the children to start, center, and end
		alignItems: 'center',
		width: '100%',
		height: '100%', // Make the card take full height
		borderRadius: '10px',
		backgroundColor: theme.palette.foreground.main,
		border: '1px ' + theme.palette.border.main + ' solid',
	},
	cardTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '2.75rem',
		width: '100%',
		borderRadius: '6px 6px 0 0 ',
		backgroundColor: theme.palette.paleGreen.main,
	},
	userInfoContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		width: '100%',
		// height: '100%',
		// flex: '1', // Take up all available space
		// paddingTop: '5vh',
	},
	podiumImageContainer: {
		width: '90%', // Set a fixed width
		// height: '90%', // Set a fixed height
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	podiumImage: {
		// height: 'auto', // Maintain original height
		width: '100%', // Width as 100% of the parent container
		// maxWidth: '1', // Max width to restrict the size
		objectFit: 'contain', // Maintain aspect ratio without distortion
	},
	statusMessage: {
		fontSize: '3vh',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	digWeightStyle: {
		fontSize: '3vh',
		fontWeight: 'bold',
		width: '90%',
		margin: '1rem',
		textAlign: 'center',
		padding: '1rem',
		borderRadius: '1rem',
		backgroundColor: theme.palette.orange.main,
		color: theme.palette.primary.main,
	},
});

export default styles;
