import React from 'react';
import { Typography } from '@mui/material';

const ReportTextBody = ({ textColor }) => {
	return (
		<Typography
			variant='p'
			align='center'
			display='flex'
			padding='0.5rem'
			width='80%'
			margin='1rem'
			fontWeight='600'
			sx={{ color: textColor }}>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
			ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
			ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
			reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
			sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
			laborum.
		</Typography>
	);
};

export default ReportTextBody;
