import { useContext, useEffect, useState } from 'react';
import { Paper, Tooltip, useTheme } from '@mui/material';
import { getStyles } from '../styles';
import KPI from '../KPI';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getFoodRecycledByDateRange } from '../../../../../../functions/getFoodRecycledByDateRange';

const FoodRecycled = ({ startDate, endDate }) => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [foodRecycled, setFoodRecycled] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getFoodRecycledByDateRange(organization._id, startDate, endDate);
			setFoodRecycled(response);
		}
		if (organization?._id && endDate && startDate) {
			fetchData();
		}
	}, [endDate, organization, startDate]);

	return (
		<Paper style={styles.paper}>
			<div style={styles.innerDiv}>
				<KPI label='Food Recycled' value={foodRecycled || 'N/A'} unit={'Lbs'}></KPI>
			</div>
		</Paper>
	);
};

export default FoodRecycled;
