import axios from 'axios';

export const getLastFeed = async (organization) => {
	const url = `/.netlify/functions/getLastFeed?organization=${organization}`;

	var lastFeed;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				lastFeed = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return lastFeed;
};
