export const mainSection = {
	display: 'flex',
	flexDirection: 'column',
	gap: '0.5vw',
	width: '100%',
};

// TOP BAR START
export const topSection = {
	display: 'flex',
	height: '15vh',
	width: '100%',
	flexDirection: 'row',
	gap: '0.5vw',
};

export const logoSection = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	height: '14vh',
	width: '20vw',
	// border: '2px solid black',
	// borderRadius: '0.5vw',
	borderRadius: '10px',
	backgroundColor: '#ffffff',
	border: '1px ' + '#dcdee1' + ' solid',
};

export const eventSection = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	width: '100%',
	// border: '2px solid black',
	borderRadius: '0.5vw',
};
// TOP BAR END

// MID SECTION 1 START
export const midSectionTop = {
	display: 'flex',
	height: '20vh',
	width: '100%',
	flexDirection: 'row',
	gap: '0.5vw',
};

export const statusSection = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '35vw',
	borderRadius: '1vw',
	// borderSizing: 'border-box',
	// flexGrow: 1,
};

export const actionMessage = {
	display: 'flex',
	alignItems: 'center', // Vertically center items
	width: '100%',
	backgroundColor: '#f57971', // Red background for visibility
	fontSize: '1.5rem', // Adjust font size as needed
	borderRadius: '0.5vw',
	flexGrow: 1,
	height: '20vh',
};

export const noActionMessage = {
	display: 'flex',
	alignItems: 'center', // Vertically center items
	width: '100%',
	backgroundColor: '#b3c66b',
	fontSize: '1.5rem', // Adjust font size as needed
	borderRadius: '0.5vw',
	flexGrow: 1,
	height: '20vh',
};

export const callToActionSection = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	// marginRight: '0.5vw',
	// border: '2px solid black',
	borderRadius: '1vw',
	borderSizing: 'border-box',
	flexGrow: 1,
	height: '20vh',
};

// MID SECTION 1 END

// MID SECTION 2 START

export const callToActionBottomBar = {
	height: '70vh', // Same height as midSectionBottom
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	backgroundColor: '#f57971', // Set the background color to red
	borderRadius: '0.5vw',
};

export const midSectionBottom = {
	height: '70vh',
	display: 'flex',
	flexDirection: 'row',
	gap: '0.5vw',
};

export const sectionLCR = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	borderRadius: '0.5vw',
};

// MID SECTION 2 END

// BOTTOM SECTION START

export const bottomSection = {
    display: 'flex',
    height: '10vh',
    // width: '100%',
    flexDirection: 'row',
    gap: '0.5vw',
};

// BOTTOM SECTION END

export const topSectionPanels = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	marginLeft: '1vw',
	marginRight: '1vw',
	border: '2px solid black',
	borderRadius: '1vw',
	borderSizing: 'border-box',
};

export const topSectionPanelsNoBorder = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	marginLeft: '1vw',
	marginRight: '1vw',
	boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
	borderRadius: '10px',
};

export const card = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between', // This will spread out the children to start, center, and end
	alignItems: 'center',
	width: '100%',
	height: '100%', // Make the card take full height
	borderRadius: '10px',
	backgroundColor: '#ffffff',
	border: '1px ' + '#dcdee1' + ' solid',
};

export const sectionQR = {
    display: 'flex',
    flexDirection: 'row', // Change to column for vertical alignment
    justifyContent: 'space-evenly', // Evenly distribute space around items
    alignItems: 'center', // Center items horizontally
    width: '20vw',
    height: '100%', // Ensure the container has a height, adjust as needed
    // borderRadius: '0.5vw',
    // borderSizing: 'border-box',
	borderRadius: '10px',
	backgroundColor: '#ffffff',
	border: '1px ' + '#dcdee1' + ' solid',
};


export const feedsSection = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	width: '100%',
	// border: '2px solid black',
	borderRadius: '0.5vw',
	borderSizing: 'border-box',
};
