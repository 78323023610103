import i18next from 'i18next';

export const addTranslation = (key) => {
	return new Promise((resolve, reject) => {
		var myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');

		var raw = JSON.stringify({
			key: key,
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};

		fetch('/.netlify/functions/addTranslation', requestOptions)
			.then((response) => {
				return response.text();
			})
			.then((result) => {
				console.log(result);
				i18next.emit('translationAdded'); // Emit a custom event
				resolve(result); // Resolve the promise when the translation is added successfully
			})
			.catch((error) => {
				console.log('error', error);
				reject(error); // Reject the promise if there is an error
			});
	});
};
