import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
	statItem: {
		textAlign: 'center',
		// border: '1px solid grey',
		padding: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '10px',
		width: '100%',
	},
	statHeader: {
		fontSize: 10,
		marginBottom: 5,
	},
	statValue: {
		fontSize: 14,
	},
	subtext: {
		fontSize: 8,
		// fontColor: 'grey',
	},
});
