import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { signIn } from '../CustomerHUD/functions/signIn';
import bg_logo from '../../assets/bg_logo.png';
import {
	buttonStyles,
	errorMessageStyle,
	forgotPassword,
	signInContainer,
	signInForm,
} from './styles';
import { OrganizationContext } from '../../context/OrganizationContext';
import { UserLocationContext } from '../../context/UserLocationContext';
import { UserContext } from '../../context/UserContext';

const SignInForm = () => {
	let navigate = useNavigate();
	const { setOrganization } = useContext(OrganizationContext);
	const { setUserLocation } = useContext(UserLocationContext);
	const { setUser } = useContext(UserContext);
	const emailRef = useRef();
	const passwordRef = useRef();
	const [errorMessage, setErrorMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const handleSignIn = async () => {
		try {
			const response = await signIn(emailRef.current.value, passwordRef.current.value);

			if (response.statusCode === 200) {
				setOrganization(response.organization);
				setUserLocation(response.userLocation);
				setUser(response.user);

				if (response.user?.HUDuser === 1) {
					navigate('/hud');
				} else if (response.user?.organization === 'BioGreen360') {
					navigate('/org-select');
				} else if (response.organization?.children?.length > 0) {
					navigate('/aggregateDashboard');
				} else {
					navigate('/dashboard');
				}
			} else if (response.statusCode === 401) {
				setErrorMessage('Invalid password. Please try again.');
			} else if (response.statusCode === 404) {
				setErrorMessage('User not found. Please try again.');
			}
		} catch (error) {
			setErrorMessage('An error occurred. Please try again later.');
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSignIn();
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div style={signInContainer}>
			<form style={signInForm}>
				<img src={bg_logo} alt='' className='glogo' style={{ maxWidth: '80vw' }}></img>

				<div
					style={{
						height: '70%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
					}}>
					<h3>Email</h3>
					<TextField
						type='email'
						variant='outlined'
						placeholder='Enter your email'
						autoComplete='current-email'
						inputRef={emailRef}
						onKeyPress={handleKeyPress}
						fullWidth
					/>
					<h3>Password</h3>
					<TextField
						type={showPassword ? 'text' : 'password'}
						variant='outlined'
						placeholder='Enter your password'
						autoComplete='current-password'
						inputRef={passwordRef}
						onKeyPress={handleKeyPress}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={togglePasswordVisibility} edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					{errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<div style={forgotPassword} onClick={() => navigate('/forgot-password')}>
							Forgot Password?
						</div>
					</div>

					<Button variant='contained' onClick={handleSignIn} sx={buttonStyles}>
						Sign In
					</Button>
				</div>
			</form>
		</div>
	);
};

export default SignInForm;
