/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, IconButton, Stack, Typography, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useDropzone } from 'react-dropzone';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import { getOrganizationImage } from '../../../../functions/getOrganizationImage';
import { uploadOrgLogo } from '../../../../functions/uploadOrgLogo';

const LogoEditor = () => {
	const [currentLogo, setCurrentLogo] = useState(null);
	const [image, setImage] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { organization, setOrganization } = useContext(OrganizationContext);

	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			const file = acceptedFiles[0];
			setImage(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
	});

	const clearImage = () => {
		setImage(null);
		setPreviewUrl(null);
		URL.revokeObjectURL(previewUrl);
	};

	const handleUpload = async () => {
		try {
			await uploadOrgLogo(organization._id, image);
			setIsEditing(false); // Exit editing mode after successful upload
			setOrganization({
				...organization,
				logo: `${image.name}-${organization._id}`, // Update this with the actual path used
			});
		} catch (error) {
			console.error('Error uploading logo:', error);
		}
	};

	useEffect(() => {
		async function fetchData() {
			if (organization.logo) {
				setIsLoading(true);
				const base64Image = await getOrganizationImage(organization.logo);
				setCurrentLogo(`data:image/png;base64,${base64Image}`);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		}
		fetchData();
	}, [organization, isEditing]);

	const toggleEditing = () => {
		setIsEditing(!isEditing);
		clearImage(); // Clear the image when switching to editing mode
	};

	return (
		<Stack
			direction='column'
			sx={{
				width: '100%',
				height: '100%',
				alignItems: 'center',
				padding: '1rem',
			}}>
			<Stack
				direction='column'
				sx={{
					border: '2px dashed #ccc',
					borderRadius: '8px',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					textAlign: 'center',
					position: 'relative',
					padding: '1rem',
				}}>
				{isEditing || !currentLogo ? (
					<>
						<Stack
							{...getRootProps()}
							direction='column'
							sx={{
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
								width: '100%',
								gap: '1rem',
								textAlign: 'center',
								cursor: 'pointer',
								...(isDragActive && { borderColor: '#2196f3' }),
							}}>
							<input {...getInputProps()} />
							{previewUrl ? (
								<Box position='relative'>
									<img
										src={previewUrl}
										alt='Dropped Image'
										style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
									/>
									<IconButton
										onClick={clearImage}
										sx={{
											position: 'absolute',
											top: 0,
											right: 0,
											backgroundColor: 'rgba(255, 255, 255, 0.7)',
										}}>
										<CloseIcon />
									</IconButton>
								</Box>
							) : (
								<>
									<Typography variant='h6' fontWeight='600' sx={{ color: '#aaa' }}>
										Upload New Logo
									</Typography>
									<CloudUploadIcon sx={{ fontSize: 40, color: '#ccc' }} />
									<Typography sx={{ color: '#aaa' }}>
										{isDragActive
											? 'Drop the file here ...'
											: 'Drag & drop a file here, or click to select a file'}
									</Typography>
								</>
							)}
						</Stack>
						<Stack
							direction='row'
							gap='1rem'
							sx={{ width: '100%', justifyContent: 'space-evenly', marginTop: '2rem' }}>
							<Button
								type='submit'
								variant='contained'
								onClick={handleUpload}
								disabled={!image}
								sx={{ width: '100%' }}>
								<Typography>Upload Logo</Typography>
							</Button>
							<Button
								onClick={toggleEditing}
								variant='outlined'
								color='secondary'
								sx={{ width: '100%' }}>
								Cancel
							</Button>
						</Stack>
					</>
				) : (
					<>
						{currentLogo && (
							<img src={currentLogo} alt='orgImage' style={{ width: '100%' }} />
						)}
						{!currentLogo && <Typography>No logo available</Typography>}
						<IconButton
							onClick={toggleEditing}
							sx={{
								position: 'absolute',
								top: 10,
								right: 10,
								backgroundColor: 'rgba(255, 255, 255, 0.7)',
							}}>
							<EditIcon />
						</IconButton>
					</>
				)}
			</Stack>
		</Stack>
	);
};

export default LogoEditor;
