import React, { useContext, useEffect, useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import NavBar from './components/NavBar/NavBar';
import Home from './tabs/Home/Home';
import './styles';
import ZeroFoodWasteEvents from './tabs/ZeroFoodWasteEvents/ZeroFoodWasteEvents';
import Reports from './tabs/Reports/Reports';
import Covers from './tabs/Covers/Covers';
import Settings from './tabs/Settings/Settings';
import Training from './tabs/Training/Training';
import UserManagement from './tabs/UserManagement/UserManagement';
import Accessories from './tabs/Accessories/Accessories';
import { OrganizationContext } from '../../context/OrganizationContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrgById } from './functions/getOrgById';
import Footer from '../../components/Footer/Footer';
import PreLaunch from './tabs/PreLaunch/PreLaunch';
import { getLocationByOrgName } from './functions/getLocationByOrgName';
import { UserLocationContext } from '../../context/UserLocationContext';
import { showPreLaunch } from '../../utils/showPreLaunch';

const bodyStyle = {
	display: 'flex',
	height: '100vh',
};

const mainContentStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	overflowY: 'auto',
};

const CustomerDashboard = () => {
	const { organization, setOrganization } = useContext(OrganizationContext);
	const { setUserLocation } = useContext(UserLocationContext);
	let navigate = useNavigate();

	const [tab, setTab] = useState(!showPreLaunch(organization) ? 'Home' : 'Pre-Launch');
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [isMobile, setIsMobile] = useState(false);
	const [showMobileSidebar, setShowMobileSidebar] = useState(false);

	const getQueryParams = (query) => {
		return new URLSearchParams(query);
	};

	// Consume orgID from URL query parameters, set organization and user location
	useEffect(() => {
		const fetchData = async () => {
			const queryParams = getQueryParams(location.search);
			const orgId = queryParams.get('query');
			const page = queryParams.get('page');

			if (orgId) {
				setIsLoading(true);
				try {
					const data = await getOrgData(orgId);
					setOrganization(data);
					const response = await getLocationByOrgName(data.org);
					setUserLocation(response);
					if (page) {
						setTab(page);
					}
				} catch (error) {
					console.error('Failed to fetch organization data:', error);
				} finally {
					setIsLoading(false);
				}
			} else {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [location, navigate, setOrganization, setUserLocation]);

	useEffect(() => {
		if (!organization) {
			navigate('/sign-in');
		}
	}, [organization, navigate]);

	// check if running on mobile device
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < window.innerHeight);
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (isLoading) {
		return null;
	}

	return (
		organization && (
			<div style={bodyStyle}>
				{(showMobileSidebar || !isMobile) && (
					<Sidebar
						tab={tab}
						setTab={setTab}
						isMobile={isMobile}
						showMobileSidebar={showMobileSidebar}
						setShowMobileSidebar={setShowMobileSidebar}
					/>
				)}
				<div style={mainContentStyle}>
					<NavBar
						tab={tab}
						isMobile={isMobile}
						showMobileSidebar={showMobileSidebar}
						setShowMobileSidebar={setShowMobileSidebar}
					/>
					{tab === 'Home' && <Home />}
					{tab === 'Reports' && <Reports />}
					{tab === 'Covers' && <Covers />}
					{tab === 'Zero Waste Events' && <ZeroFoodWasteEvents />}
					{tab === 'Accessories' && <Accessories />}
					{tab === 'Training' && <Training />}
					{tab === 'Users' && <UserManagement />}
					{tab === 'Settings' && <Settings />}
					{tab === 'Pre-Launch' && <PreLaunch />}
					<Footer />
				</div>
			</div>
		)
	);
};

const getOrgData = async (orgId) => {
	if (orgId) {
		const response = await getOrgById(orgId);
		return response;
	}
};

export default CustomerDashboard;
