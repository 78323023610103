import axios from 'axios';

export const getFoodRecycledYTDByDateRange = async (orgID, year, endDate) => {
	const url = `/.netlify/functions/getFoodRecycledYTDByDateRange?orgID=${orgID}&year=${year}&endDate=${endDate}`;

	var foodRecycledYTD = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				foodRecycledYTD = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return foodRecycledYTD;
};
