import axios from 'axios';

export const updateUser = async (formData) => {
	const url = `/.netlify/functions/updateUser`;

	try {
		const res = await axios.post(url, formData);
		return res.data;
	} catch (err) {
		console.error('Error:', err);
		return { error: err.response?.data || 'An unknown error occurred' };
	}
};
