import axios from 'axios';

export const getWeekliesAllTime = async (organization) => {
	const encodedOrganization = encodeURIComponent(organization);
	const url = `/.netlify/functions/getWeekliesAllTime?organization=${encodedOrganization}`;
	let data = 0;

	try {
		const res = await axios.get(url);
		if (res.data != null && res.data !== false) {
			data = res.data;
		} else {
			console.log('failed');
		}
	} catch (err) {
		console.log(err);
	}

	return data;
};
