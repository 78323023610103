import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getTotalCoversByMonthAndYear } from '../../../../../../functions/getTotalCoversByMonthAndYear';

const TotalCoversByMonthAndYear = () => {
	const theme = useTheme();
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({ labels: [], datasets: [] });

	// Define an array of colors for the lines
	const lineColors = [
		'#FF6384',
		'#36A2EB',
		'#FFCE56',
		'#4BC0C0',
		'#9966FF',
		'#FF9F40',
		'#FF6384',
		'#36A2EB',
		'#FFCE56',
		'#4BC0C0',
		'#9966FF',
		'#FF9F40',
		// Add more colors if you have more than 12 lines
	];

	useEffect(() => {
		async function fetchData() {
			const response = await getTotalCoversByMonthAndYear(organization._id);
			processData(response);
		}

		const processData = (data) => {
			const months = Array.from({ length: 12 }, (_, i) => i + 1); // Months 1-12
			const years = Array.from(new Set(data.map((item) => item.year)));

			const datasets = years.map((year, index) => ({
				label: `Year ${year}`,
				data: months.map((month) => {
					const record = data.find((item) => item.month === month && item.year === year);
					return record ? record.actCovers : 0;
				}),
				borderColor: lineColors[index % lineColors.length], // Cycle through colors
				backgroundColor: 'transparent',
			}));

			setChartData({ labels: months, datasets });
		};

		fetchData();
		// const interval = setInterval(fetchData, 30000);
		// return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organization, theme.palette.primary.main]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,

		plugins: {
			legend: {
				position: 'top',
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: 'Month',
				},
				grid: {
					color: 'rgba(0, 0, 0, 0)', // makes x-axis lines invisible
				},
				ticks: {
					callback: function (value, index, values) {
						return value; // Or format as 'Jan', 'Feb', ... if you prefer
					},
				},
			},
			y: {
				title: {
					display: true,
					text: 'Covers',
				},
				grid: {
					color: 'rgba(0, 0, 0, 0)', // makes y-axis lines invisible
				},
			},
		},
	};

	return (
		<Stack
			sx={{
				height: '25vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h6' fontWeight='600' alignSelf='flex-start'>
				Total Covers By Month and Year
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Line options={options} data={chartData} />
			</Box>
		</Stack>
	);
};

export default TotalCoversByMonthAndYear;
