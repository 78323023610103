import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import KPI from '../KPI';
import { getWasteDivertedLast30Days } from '../../../../../../../../functions/getWasteDivertedLast30Days';

const WasteProcessedLast30Days = () => {
	const [value, setValue] = useState(0);
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getWasteDivertedLast30Days(organization.org);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return <KPI label='Organic Waste Processed' value={value} description='Last 30 Days (lbs)' />;
};

export default WasteProcessedLast30Days;
