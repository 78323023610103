import React, { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import { Paper, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { getMapCoordinatesAggregate } from '../../../../../../functions/getMapCoordinatesAggregate';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { useTheme } from '../../../../../../../../context/ThemeContext';

mapboxgl.accessToken =
	'pk.eyJ1IjoiYnZpc2Nvc2kiLCJhIjoiY2xxNzJqcDV0MGZwNjJrbno3c240cXV0ayJ9.Tz_CaaSh-OTldaLIgs9krg';

const Marker = ({ children }) => {
	return <div style={{ fontSize: '24px' }}>{children}</div>;
};

const UserLocationMap = () => {
	const { theme } = useTheme();
	const { organization } = useContext(OrganizationContext);
	const mapContainer = useRef(null);
	const map = useRef(null);
	const markersRef = useRef([]); // Ref to keep track of markers
	const [locations, setLocations] = useState([]);
	const [showInactive, setShowInactive] = useState(true);

	useEffect(() => {
		async function fetchData() {
			if (organization.children?.length > 0) {
				const response = await getMapCoordinatesAggregate(organization.children);
				setLocations(response);
			}
		}

		fetchData();
	}, [organization]);

	useEffect(() => {
		const mapStyle =
			theme.palette.mode === 'light'
				? 'mapbox://styles/mapbox/light-v10'
				: 'mapbox://styles/mapbox/dark-v10';

		if (map.current) return; // Initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: mapStyle,
			center: [-98.5795, 32],
			zoom: 2.5,
		});
	}, [theme.palette.mode]);

	useEffect(() => {
		if (!map.current) return;

		// Remove existing markers
		markersRef.current.forEach((marker) => marker.remove());
		markersRef.current = [];

		locations.forEach((location) => {
			if (showInactive || location.organization.goLive) {
				if (location.locationLat && location.locationLong) {
					const markerNode = document.createElement('div');
					const root = createRoot(markerNode);
					root.render(<Marker>🌱</Marker>);

					const marker = new mapboxgl.Marker(markerNode)
						.setLngLat([location.locationLong, location.locationLat])
						.addTo(map.current);

					markersRef.current.push(marker);
				}
			}
		});
	}, [locations, showInactive, theme.palette.mode]);

	const handleShowInactiveChange = (event) => {
		setShowInactive(event.target.checked);
	};

	return (
		<Paper
			style={{
				position: 'relative', // Needed to position the checkbox absolutely within
				border: `1px solid ${theme.palette.border.main}`,
				boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)', // More subtle soft shadow

				height: '25vh',
				borderRadius: '1rem',
				overflow: 'hidden', // Ensures nothing spills outside the container
			}}>
			<div ref={mapContainer} className='map-container' />
			<Tooltip title='Hide/Show Inactive properties' placement='top'>
				<FormControlLabel
					control={
						<Checkbox
							checked={showInactive}
							onChange={handleShowInactiveChange}
							name='showInactive'
							color='primary'
						/>
					}
					label='Show Future Properties'
					style={{
						position: 'absolute', // Position over the map
						top: 10, // 10px from the top
						right: 10, // 10px from the right
						backgroundColor: 'rgba(255, 255, 255, 0.75)', // Semi-transparent background for better visibility
						borderRadius: '4px', // Slight rounding of corners for aesthetics
						paddingRight: '10px',
					}}
				/>
			</Tooltip>
		</Paper>
	);
};

export default UserLocationMap;
