import React, { useContext, useEffect, useState } from 'react';
import KPI from './KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getMilesDriven } from '../../../../../functions/getMilesDriven';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Tooltip } from '@mui/material';

const MilesDriven = () => {
	const [value, setValue] = useState();
	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getMilesDriven(organization.org);
			setValue(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Tooltip title='Miles driven by gas passenger vehicle' placement='bottom'>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					margin: '10px',
					textAlign: 'center',
					alignItems: 'center',
				}}>
				<DirectionsCarIcon />
				<KPI label='Miles Driven' value={value} subtext='by gas passenger vehicle'></KPI>
			</div>
		</Tooltip>
	);
};

export default MilesDriven;
