import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

// Define styles
const styles = StyleSheet.create({
	headerSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: '5',
		gap: '1rem',
	},
	logoSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	logo: { width: '100px' },
	titleSection: {
		marginTop: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		// width: '100%',
	},
});

function formatDate(dateString) {
	const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
	const date = new Date(`${dateString}T00:00:00Z`);

	// Append the suffix 'st', 'nd', 'rd', or 'th' to the day
	const day = date.getUTCDate();
	let suffix = 'th';
	if (day % 10 === 1 && day !== 11) suffix = 'st';
	else if (day % 10 === 2 && day !== 12) suffix = 'nd';
	else if (day % 10 === 3 && day !== 13) suffix = 'rd';

	const formattedDate = date.toLocaleDateString('en-US', options);
	const dayWithSuffix = `${day}${suffix}`;

	// Replace the day number in formattedDate with dayWithSuffix
	return formattedDate.replace(day, dayWithSuffix);
}

const Header = ({ event, image, orgLogo, headerTextColor }) => {
	return (
		<div>
			<View style={styles.headerSection}>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: image ? 'space-between' : 'center',
						width: '100%',
						maxHeight: '100px',
					}}>
					{image && <Image style={styles.logo} src={image} />}
					{orgLogo && <Image style={styles.logo} src={orgLogo} />}
				</View>

				<View style={styles.titleSection}>
					<Text
						style={{
							fontSize: 12,
							textAlign: 'center',
							fontWeight: '900',
							color: headerTextColor,
						}}>
						ZERO-FOOD-WASTE EVENT REPORT:
					</Text>
					<Text style={{ fontSize: 12, textAlign: 'center', color: headerTextColor }}>
						{event.name}
					</Text>
					<Text style={{ fontSize: 12, textAlign: 'center', color: headerTextColor }}>
						{formatDate(event.eventStart)} - {formatDate(event.eventEnd)}
					</Text>
				</View>
			</View>
		</div>
	);
};

export default Header;
