import { useContext } from 'react';
import FoodWastePerCoverByMonthAndYear from './components/FoodWastePerCoverByMonthAndYear/FoodWastePerCoverByMonthAndYear';
import TotalCoversByMonthAndYear from './components/TotalCoversByMonthAndYear/TotalCoversByMonthAndYear';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import FoodWasteByMonth from './components/FoodWasteByMonth/FoodWasteByMonth';
import AllTimeMetrics from './components/AllTimeMetrics/AllTimeMetrics';
import { Box, Stack } from '@mui/material';

const AllTimeTrends = () => {
	const { organization } = useContext(OrganizationContext);
	return (
		<Box sx={{ width: '100%', minHeight: '70vh' }}>
			<Stack direction='column' sx={{ gap: '1rem', margin: '1rem', alignItems: 'center' }}>
				<AllTimeMetrics />
				{organization.hasCovers ? (
					<>
						<TotalCoversByMonthAndYear />
						<FoodWastePerCoverByMonthAndYear />
					</>
				) : (
					<>
						<FoodWasteByMonth />
					</>
				)}
				<></>
			</Stack>
		</Box>
	);
};

export default AllTimeTrends;
