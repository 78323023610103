import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import React from 'react';

const ActualTableMessaging = ({ wk, mo, yr, rws }) => {
	// Get the current date (beginning of the month)
	const currentDate = new Date();
	currentDate.setDate(1);

	// Create a date object from the mo and yr props
	const inputDate = new Date(yr, mo - 1, 1); // Subtract 1 from month because JavaScript months are 0-based
	// Conditional rendering based on the props
	return !(wk && mo && yr) ? (
		<Typography
			variant='p'
			fontWeight={'600'}
			sx={{
				display: 'flex',
				color: 'red',
				width: '50%',
				marginBottom: '1rem',
				marginTop: '1rem',
				paddingTop: '0.25rem',
				paddingBottom: '0.25rem',
				flexDirection: 'column',
				textAlign: 'center',
			}}>
			Please specify the timeframe of interest using the selectors above.
		</Typography>
	) : inputDate > currentDate ? (
		<Typography
			variant='p'
			fontWeight={'500'}
			sx={{
				display: 'flex',
				color: 'red',
				width: '50%',
				marginBottom: '1rem',
				marginTop: '1rem',
				paddingTop: '0.25rem',
				paddingBottom: '0.25rem',
				flexDirection: 'column',
				textAlign: 'center',
			}}>
			Actual data cannot be recorded for future months! If you would like to add projections for
			the selected month, please do so in the projections tab.
		</Typography>
	) : !rws ? (
		<Typography
			variant='p'
			fontWeight={'700'}
			sx={{
				display: 'flex',
				color: 'red',
				width: '50%',
				marginBottom: '1rem',
				marginTop: '1rem',
				paddingTop: '0.25rem',
				paddingBottom: '0.25rem',
				flexDirection: 'column',
				textAlign: 'center',
			}}>
			There is no projected data available for the selected month! Please add projections in the
			projected tab before recording the actual data here.
		</Typography>
	) : (
		<Typography
			variant='p'
			fontWeight={'700'}
			sx={{
				display: 'flex',
				color: 'red',
				width: '50%',
				marginBottom: '1rem',
				marginTop: '1rem',
				paddingTop: '0.25rem',
				paddingBottom: '0.25rem',
				flexDirection: 'column',
				textAlign: 'center',
			}}>
			Please make sure the information being uploaded is accurate before saving!
		</Typography>
	);
};

export default ActualTableMessaging;
