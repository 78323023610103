import { Typography } from '@mui/material';
import React from 'react';
import CoverIssueMessaging from '../Actual/CoverIssueMessaging';

const HeaderSection = () => {
	return (
		<div
			style={{
				// border: '1px solid #CD6F00',
				paddingTop: '1rem',
				borderRadius: '1rem',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Typography variant='h6' fontWeight='600' sx={{ textAlign: 'center', color: '#CD6F00' }}>
				Add/Update Projected Cover Information
			</Typography>
			<ul>
				<li>
					<Typography variant='p' fontWeight='600'>
						Select the month/year you wish to input data for from the dropdown selectors below
					</Typography>
				</li>

				<li>
					<Typography variant='p' fontWeight='600'>
						Input data directly into the table
					</Typography>
				</li>

				<li>
					<Typography variant='p' fontWeight='600'>
						Please make sure the dates in the column header match the information being
						uploaded
					</Typography>
				</li>
			</ul>
			<CoverIssueMessaging />
		</div>
	);
};

export default HeaderSection;
