const styles = (theme) => ({
	digestionTankStatusCard: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between', // This will spread out the children to start, center, and end
		alignItems: 'center',
		width: '100%',
		height: '100%', // Make the card take full height
		borderRadius: '10px',
		backgroundColor: theme.palette.foreground.main,
		border: '1px ' + theme.palette.border.main + ' solid',
	},
	cardTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '2.75rem',
		width: '100%',
		borderRadius: '6px 6px 0 0 ',
		backgroundColor: theme.palette.paleGreen.main,
	},
	progressCircle: {
		margin: '1rem',
		height: '10rem',
		width: '10rem',
		background: theme.palette.paleGreen,
	},

	linearProgressBackground: {
		width: '90%',
		height: '20px',
		backgroundColor: theme.palette.grey[200],
		borderRadius: '10px',
		overflow: 'hidden',
		margin: '0 1rem',
	},
	linearProgressBar: {
		height: '100%',
		backgroundColor: theme.palette.lightGreen.main,
	},
	statusMessage: {
		fontSize: '4vh',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	digWeightStyle: {
		fontSize: '3vh',
		fontWeight: 'bold',
		width: '90%',
		margin: '1rem',
		textAlign: 'center',
		padding: '1rem',
		borderRadius: '1rem',
		backgroundColor: theme.palette.orange.main,
		color: theme.palette.primary.main,
	},
});

export default styles;
