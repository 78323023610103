import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import RecycledFoodTrends from './RecycyledFoodTrends';
import CoversAggregate from './CoversAggregate';
// import FWPerCoverAggregate from './FWPerCoverAggregateChart';
import { getCoverCountsAggregate } from '../../../../functions/getCoverCountsAggregate';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
// import { getAvgFwPerCoverAggregate } from '../../../../functions/getAvgFwPerCoverAggregate';

const Aggregate = () => {
	const { organization } = useContext(OrganizationContext);
	const [coverCounts, setCoverCounts] = useState();
	// const [averagesPerMonth, setAveragesPerMonth] = useState();

	useEffect(() => {
		async function fetchData() {
			const response = await getCoverCountsAggregate(organization);
			setCoverCounts(response);
		}
		fetchData();
	}, [organization]);

	// useEffect(() => {
	// 	async function fetchData() {
	// 		const response = await getAvgFwPerCoverAggregate(organization);
	// 		console.log(response);

	// 		setAveragesPerMonth(response);
	// 		// processChartData(averagesPerMonth);
	// 	}
	// 	fetchData();
	// }, [organization]);

	return (
		<Stack direction='column' margin='1rem 1rem 3rem 0' padding='1rem' gap='1rem'>
			<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
				Food Recycled
			</Typography>
			<RecycledFoodTrends />

			<>
				<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
					Cover Counts
				</Typography>
				<CoversAggregate coverCounts={coverCounts} />
			</>

			{/* <>
				<Typography variant='h5' fontWeight='600' sx={{ paddingLeft: '1rem' }}>
					Food Recycled Per Cover
				</Typography>
				<FWPerCoverAggregate averagesPerMonth={averagesPerMonth} />
			</> */}
		</Stack>
	);
};

export default Aggregate;
