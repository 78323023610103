import { Circle } from '@mui/icons-material';
import { Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react';

const Timeline = ({ currentStep, setStep }) => {
	const steps = [
		{ id: 1, name: 'RENDERS APPROVED', due: 'May 27 2024' },
		{ id: 2, name: 'PROGRAM SETUP', due: 'Jun 17 2024' },
		{ id: 3, name: 'NETWORK/IT SETUP', due: 'Jun 24 2024' },
		{ id: 4, name: 'SPACE(S) READY', due: 'Jul 01 2024' },
		{ id: 5, name: 'INSTALL DATE', due: 'Jul 08 2024' },
		{ id: 6, name: 'GO LIVE', due: 'Jul 10 2024' },
	];

	const timelineStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		// padding: '20px',
		marginTop: '2rem',
		listStyleType: 'none',
	};

	const circleStyle = {
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 'bold',
	};

	const lineStyle = {
		height: '2px',
		flex: '1',
		backgroundColor: '#dcdee1',
	};
	return (
		<div style={timelineStyle}>
			{steps.map((step, index) => (
				<Stack
					direction='row'
					key={step.id}
					sx={{
						alignItems: 'center',
						justifyContent: index !== 0 ? 'center' : 'flex-start',
						width: index !== 0 ? '100%' : '60%',
					}}>
					{index !== 0 && <div style={lineStyle}></div>}
					<Stack
						direction='column'
						sx={{
							alignItems: 'center',
							justifyContent: 'space-evenly',
							width: index !== 0 ? '60%' : '100%',
							cursor: 'pointer',
						}}
						onClick={() => {
							setStep(step.id);
						}}>
						<Typography fontSize={12} mb={1} fontWeight='bold'>
							{step.name}
						</Typography>
						<div
							style={{
								...circleStyle,
								backgroundColor: currentStep === step.id ? '#85bd04' : '#dcdee1',
								color: currentStep === step.id ? 'white' : '#8c9d93',
							}}>
							<Typography fontWeight={600} fontSize={14}>
								{step.id}
							</Typography>
						</div>

						<Typography style={{ color: 'grey' }} fontSize={12} mt={1}>
							{step.due}
						</Typography>
					</Stack>
				</Stack>
			))}
		</div>
	);
};

export default Timeline;
