import React from 'react';
import statusHighlight from '../../assets/help/status-highlight.png';
import noActionHighlight from '../../assets/help/no-action-highlight.png';
import actionHighlight from '../../assets/help/action-highlight.png';
import recentFeedsHighlight from '../../assets/help/recent-feeds-highlight.png';
import leaderboardHighlight from '../../assets/help/leaderboard-highlight.png';
import celebrationHighlight from '../../assets/help/celebration-highlight.png';
import remindersHighlight from '../../assets/help/reminders-highlight.png';
import eventsHighlight from '../../assets/help/events-highlight.png';
import helpHighlight from '../../assets/help/help-highlight.png';

const CustomerHUDHelp = () => {

	const sectionStyle = {
		backgroundColor: '#f0f0f0', // Light grey background
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Drop shadow
		borderRadius: '10px', // Rounded corners
		padding: '20px', // Padding around the content
		marginBottom: '20px', // Space between each section
		maxWidth: '90vw', // Maximum width
		textAlign: 'center', // Centered text
	  };

	  const imageStyle = {
		width: '100%',
		height: 'auto',
		border: '1px solid #000000',
		borderRadius: '10px'
	};

  return (
    <div className='body' style={{
		display: 'flex', 
		flexDirection: 'column', 
		alignItems: 'center', 
		padding: '20px',
		backgroundColor: '#FFFFFF', // Adjust the color as needed
		width: '100vw',
		minHeight: '100vh',
		boxSizing: 'border-box'
	  }}>
      <h1>About Your HUD</h1>
      <p style={{ maxWidth: '90vw', textAlign: 'center', marginBottom: '20px', fontSize: '22px' }}>
        Your HUD, or Heads Up Display, is used to check the status of your machine from the kitchen, office, or other remote location. The HUD will tell how much you can feed and notify you if there are any tasks the team must complete to continue processing. The following images will walk you through each section of the HUD and what you need to know about it.
      </p>
      <div style={sectionStyle}>
	  	<h2>Machine Status</h2>
        <p style={{fontSize: '18px'}}>The highlighted section below is the status of your machine. This will tell you how many pounds of food you may feed at a given moment, or tell you that the machine is full.</p>
        <img src={statusHighlight} alt="Status Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Call to Action</h2>
        <p style={{fontSize: '18px'}}>The highlighted section is the Call to Action section. If the machine is running normally, this seciton will be green and just tell you to keep up the good work.</p>
        <img src={noActionHighlight} alt="No Action Highlight"style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Call to Action - Faulted</h2>
        <p style={{fontSize: '18px'}}>If there is a fault on the machine, BioGreen360 service happening, or any other call to action, it will be displayed in the highlighted area below. Below that will be a video going over best practices to handle that Call to Action, as well as a QR code which you may scan to play the video on your phone.</p>
        <img src={actionHighlight} alt="Action Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Recent Feeds</h2>
        <p style={{fontSize: '18px'}}>The highlighted section below summarizes the last feed on your machine, noting who fed, how long ago it was, and how many pounds. It also highlights how many pounds have been fed today.</p>
        <img src={recentFeedsHighlight} alt="Recent Feeds Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Leaderboard</h2>
        <p style={{fontSize: '18px'}}>The highlighted section is your Leaderboard. This highlights the top 3 users by the amount of food fed into the machine over the last 7 days. The goal is to celebrate users that are actively and correctly engaging with the machine.</p>
        <img src={leaderboardHighlight} alt="Leaderboard Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Celebrations</h2>
        <p style={{fontSize: '18px'}}>The highlighted section is your Celebrations card. This will cycle through various organization or user based milestones, achievements, or overall exceptional machine use. Examples could be about an individual's total pounds fed on the machine, or the amount of carbon that the organization has sequestered as a whole while using a BioGreen360 machine.</p>
        <img src={celebrationHighlight} alt="Celebrations Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Reminders</h2>
        <p style={{fontSize: '18px'}}>The highlighted section is your Reminders card. This will cycle through various reminders that apply to the organization, location, or even individual users. One for example might be that the connected container will need to be changed tomorrow. Another example of a reminder could be about a specific initiative your organization is involved in. They can also be as simple as reinforcing best practices, like reminding to clean off the machine after feeding or to check the bins for contaminants.</p>
        <img src={remindersHighlight} alt="Reminders Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Events</h2>
        <p style={{fontSize: '18px'}}>The highlighted section is your Events Card. This section will highlight upcoming events including Zero-Food-Waste Events, service visits by BioGreen360 technicians, or other important upcoming events that would be good for your team to be aware of.</p>
        <img src={eventsHighlight} alt="Events Highlight" style={imageStyle} />
      </div>
      <div style={sectionStyle}>
	  <h2>Help</h2>
        <p style={{fontSize: '18px'}}>The highlighted section is the Help card. You may scan this QR code at any time to bring up this page and refresh your memory on HUD best practices or train new users on the HUD.</p>
        <img src={helpHighlight} alt="Help Highlight" style={imageStyle} />
      </div>
    </div>
  );
};

export default CustomerHUDHelp;
