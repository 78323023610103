import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Skeleton, Tooltip, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getActualAndProjectedCoversAggregate } from '../../functions/getActualAndProjectedCoversAggregate';

const columns = [
	{
		field: 'org',
		headerName: 'Organization',
		flex: 1,
		renderCell: (params) =>
			params.row.org !== undefined ? params.row.org : <Skeleton variant='text' width='100%' />,
	},
	{
		field: 'actualThisMonth',
		headerName: 'Actual (This Month)',
		flex: 1,
		renderCell: (params) =>
			params.row.actualThisMonth !== undefined ? (
				params.row.actualThisMonth
			) : (
				<Skeleton variant='text' width='100%' />
			),
	},
	{
		field: 'projectedThisMonth',
		headerName: 'Projected (This Month)',
		flex: 1,
		renderCell: (params) =>
			params.row.projectedThisMonth !== undefined ? (
				params.row.projectedThisMonth
			) : (
				<Skeleton variant='text' width='100%' />
			),
	},
	{
		field: 'actualLastMonth',
		headerName: 'Actual (Last Month)',
		flex: 1,
		renderCell: (params) =>
			params.row.actualLastMonth !== undefined ? (
				params.row.actualLastMonth
			) : (
				<Skeleton variant='text' width='100%' />
			),
	},
	{
		field: 'projectedLastMonth',
		headerName: 'Projected (Last Month)',
		flex: 1,
		renderCell: (params) =>
			params.row.projectedLastMonth !== undefined ? (
				params.row.projectedLastMonth
			) : (
				<Skeleton variant='text' width='100%' />
			),
	},
];

const skeletonRows = Array.from(new Array(12)).map((_, index) => ({
	id: index,
	org: <Skeleton variant='text' width='60%' />,
	goLive: <Skeleton variant='text' width='60%' />,
	actualThisMonth: <Skeleton variant='text' width='60%' />,
	projectedThisMonth: <Skeleton variant='text' width='60%' />,
	actualLastMonth: <Skeleton variant='text' width='60%' />,
	projectedLastMonth: <Skeleton variant='text' width='60%' />,
}));

const Compliance = () => {
	const { organization } = useContext(OrganizationContext);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			if (organization?.children?.length > 0) {
				const response = await getActualAndProjectedCoversAggregate(organization._id);
				const rowsData = await Promise.all(
					response.map(async (data, index) => {
						return {
							id: index + 1,
							org: data.org,
							_id: data._id,
							actualThisMonth: data.actualThisMonth,
							projectedThisMonth: data.projectedThisMonth,
							actualLastMonth: data.actualLastMonth,
							projectedLastMonth: data.projectedLastMonth,
						};
					})
				);
				setRows(rowsData);
			}
			setLoading(false);
		}

		fetchData();
	}, [organization]);

	return (
		<Box sx={{ minHeight: '800px' }}>
			<Paper elevation={0} sx={{ borderRadius: '1rem', margin: '1rem' }}>
				<Box sx={{ height: '100%', width: '100%' }}>
					<DataGrid
						rows={loading ? skeletonRows : rows}
						columns={columns}
						pageSize={12}
						pageSizeOptions={[12]}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 12,
								},
							},
						}}
						disableSelectionOnClick
						sx={{ borderRadius: '1rem' }}
						components={{
							NoRowsOverlay: () => (
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '100%',
									}}>
									<Skeleton variant='rectangular' width='100%' height={118} />
								</Box>
							),
						}}
					/>
				</Box>
			</Paper>
		</Box>
	);
};

export default Compliance;
