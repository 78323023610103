import { Grid, Paper, Stack, useTheme } from '@mui/material';
import React from 'react';
import KPI from './components/KPI';
import AvgUtilizationLast30Days from './components/AvgUtilizationLast30Days/AvgUtilizationLast30Days';
import WasteProcessedLast30Days from './components/WasteProcessedLast30Days/WasteProcessedLast30Days';
import DiversionByCategoryLast30Days from './components/DiversionByCategoryLast30Days/DiversionByCategoryLast30Days';
import { getStyles } from './styles';

const Card3 = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	return (
		<Grid container spacing='1rem'>
			<Grid item xs={12} lg={9}>
				<Paper style={styles.paper}>
					<DiversionByCategoryLast30Days />
				</Paper>
			</Grid>
			<Grid item xs={12} lg={3}>
				<Stack direction='column' gap='1rem' height='100%'>
					<Paper style={styles.paper}>
						<AvgUtilizationLast30Days />
					</Paper>

					<Paper style={styles.paper}>
						<WasteProcessedLast30Days />
					</Paper>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default Card3;
