export const isValidDate = (date) => {
	if (date instanceof Date) {
		return !isNaN(date);
	}
	if (typeof date === 'string') {
		const parsedDate = new Date(date);
		return !isNaN(parsedDate);
	}
	return false;
};
