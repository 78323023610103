import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import CountdownTimer from './CountdownTimer';
import { OrganizationContext } from '../../../../context/OrganizationContext';

const InstallDate = () => {
	const { organization } = useContext(OrganizationContext);
	return (
		<Stack mt={10} mx={10}>
			<Typography variant='h4' fontWeight='600'>
				Install Date
			</Typography>
			<CountdownTimer
				targetDate={organization.goLiveDate || '2024-9-04'}
				sx={{ marginTop: '10rem' }}
			/>
		</Stack>
	);
};

export default InstallDate;
