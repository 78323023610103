import axios from 'axios';

export const recordHUDConnectionStart = async (orgID, sessionID) => {
	const url = `/.netlify/functions/recordHUDConnectionStart`;
	const body = {
		orgID: orgID,
		sessionID: sessionID,
	};
	let data = 0;


	await axios
		.post(url, body)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
