import { Button, Typography, useTheme } from '@mui/material';
import React from 'react';

const SidebarItem = ({ tab, action, icon, label }) => {
	const theme = useTheme();

	return (
		<div>
			<Button
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					color: tab === label ? '#1aa11b' : '#a9a8a9',
					backgroundColor: tab === label ? theme.palette.accent.main : 'transparent',
					'&:hover': {
						backgroundColor: tab === label ? theme.palette.accent.main : 'transparent',
					},
				}}
				onClick={action}>
				{icon}
				<Typography
					variant='p'
					sx={{
						marginLeft: '0.5rem',
						color: tab === label ? '#000' : '#a9a8a9',
						fontSize: '14px',
						fontWeight: 500,
					}}>
					{label}
				</Typography>
			</Button>
		</div>
	);
};

export default SidebarItem;
