import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SignInForm from './SignInForm';
import Right from './Right';
import './styles.jsx';
import { getOrgById } from '../CustomerDashboard/functions/getOrgById.jsx';
import { OrganizationContext } from '../../context/OrganizationContext.jsx';
import { UserContext } from '../../context/UserContext.jsx';

//test
const SignIn = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < window.innerHeight);
	const navigate = useNavigate();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const { setOrganization } = useContext(OrganizationContext);
	const { setUser } = useContext(UserContext);

	// Function to parse query parameters
	const getQueryParams = (query) => {
		return new URLSearchParams(query);
	};

	// Fetch and set organization on component mount or when the URL changes
	useEffect(() => {
		const queryParams = getQueryParams(location.search);
		const orgID = queryParams.get('query');

		console.log('running from sign in');

		if (orgID) {
			setIsLoading(true); // Set loading to true when starting to fetch data
			getOrgById(orgID)
				.then((organization) => {
					setOrganization(organization); // Update the OrganizationContext with the fetched data
					setUser({ email: 'admin@biogreen360.com', userDept: 'admin' });

					if (organization?.children?.length > 0) {
						navigate('/aggregateDashboard');
					} else if (organization?.goLive) {
						const goLiveDate = new Date(organization?.goLive);
						const currentDate = new Date();
						if (goLiveDate > currentDate) {
							navigate('/onboarding');
						} else {
							navigate('/dashboard');
						}
					} else {
						navigate('/onboarding');
					}
					setIsLoading(false); // Set loading to false once the data is fetched
				})
				.catch((error) => {
					console.error('Failed to fetch organization data:', error);
					setIsLoading(false); // Ensure loading is set to false even on error
				});
		} else {
			setIsLoading(false); // Set loading to false if orgId is not in the query params
		}
	}, [location, navigate, setOrganization]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < window.innerHeight);
		};

		window.addEventListener('resize', handleResize);

		// Cleanup the event listener on component unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
				alignItems: 'center',
				border: '1px solid black',
			}}>
			<SignInForm />
			{!isMobile ? <Right /> : null}
		</div>
	);
};

export default SignIn;
