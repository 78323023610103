export const tab = {
	border: '1px solid red',
};

export const coversContainer = {
	display: 'flex',
	flexDirection: 'column',
	margin: '1rem',
	borderRadius: '1rem',
};
