export const paper = {
	display: 'flex',
	color: '#000',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-evenly',
	height: '100%',
};

export const cloudIcon = {
	fontSize: 40,
};

export const imageContainer = {
	position: 'relative',
};

export const imageStyle = {
	maxWidth: '100%',
	maxHeight: '100%',
};

export const iconButton = {
	position: 'absolute',
	right: 0,
	top: 0,
	color: 'black',
};
