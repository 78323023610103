import React, { createContext, useState, useContext, useEffect } from 'react';

export const ZFWEReportPreferencesContext = createContext();

export const ZFWEReportPreferencesProvider = ({ children }) => {
	const [event, setEvent] = useState(null);
	const [image, setImage] = useState(null);
	const [introText, setIntroText] = useState(
		'Thank you for allowing us to host your recent event. We also want to congratulate you on your environmentally conscious behaviors in making your gathering a truly "Zero-Food-Waste Event". This report highlights the significant and quantifiable impact of your sustainable efforts, take a look!'
	);
	const [outroText, setOutroText] = useState(
		'Take heart knowing that any food not eaten from your event is going towards growing more food! We hope you and your guests had an unforgettable experience, and look forward to hosting your next event.'
	);
	const [reportFontColor, setReportFontColor] = useState('#000000');
	const [reportHeaderBackground, setReportHeaderBackground] = useState('#000000');
	const [reportHeaderText, setReportHeaderText] = useState('#000000');
	const [reportStatisticText, setReportStatisticText] = useState('#000000');
	const [FWperCover, setFWperCover] = useState(0);

	const resetReportPreferences = () => {
		setEvent(null);
		setImage(null);
		setIntroText(
			'Thank you for allowing us to host your recent event. We also want to congratulate you on your environmentally conscious behaviors in making your gathering a truly "Zero-Food-Waste Event". This report highlights the significant and quantifiable impact of your sustainable efforts, take a look!'
		);
		setOutroText(
			'Take heart knowing that any food not eaten from your event is going towards growing more food! We hope you and your guests had an unforgettable experience, and look forward to hosting your next event.'
		);
		setReportFontColor('#000000');
		setReportHeaderBackground('#000000');
		setReportHeaderText('#000000');
		setReportStatisticText('#000000');
		setFWperCover(0);
	};

	return (
		<ZFWEReportPreferencesContext.Provider
			value={{
				event,
				setEvent,
				image,
				setImage,
				introText,
				setIntroText,
				outroText,
				setOutroText,
				reportFontColor,
				setReportFontColor,
				reportHeaderBackground,
				setReportHeaderBackground,
				reportHeaderText,
				setReportHeaderText,
				reportStatisticText,
				setReportStatisticText,
				FWperCover,
				setFWperCover,
				resetReportPreferences,
			}}>
			{children}
		</ZFWEReportPreferencesContext.Provider>
	);
};

export const useZFWEReportPreferences = () => {
	return useContext(ZFWEReportPreferencesContext);
};
