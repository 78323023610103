// styles.js
const styles = (theme) => ({
	digestionTankStatusCard: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between', // This will spread out the children to start, center, and end
		alignItems: 'center',
		width: '100%',
		height: '100%', // Make the card take full height
		borderRadius: '10px',
		backgroundColor: theme.palette.foreground.main,
		border: '1px ' + theme.palette.border.main + ' solid',
	},
	cardTitle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '2.75rem',
		width: '100%',
		borderRadius: '6px 6px 0 0 ',
		backgroundColor: theme.palette.paleGreen.main,
	},
	statusMessage: {
		fontSize: '3vh',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	digWeightStyle: {
		fontSize: '3vh',
		fontWeight: 'bold',
		width: '90%',
		margin: '1rem',
		textAlign: 'center',
		padding: '1rem',
		borderRadius: '1rem',
		backgroundColor: theme.palette.orange.main,
		color: theme.palette.primary.main,
	},
    iconImageContainer: {
        width: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconImage: {
        width: '100%',
        objectFit: 'contain',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column', // Ensure vertical stacking of children
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%', // Ensure it spans the width of its parent
        padding: '10px 0', // Add padding to separate text from other elements
    },
});

export default styles;
