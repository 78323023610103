import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	Tooltip,
} from '@mui/material';
import { getOrgsByIds } from '../../../../functions/getOrgsByIds';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Children = () => {
	const { organization } = useContext(OrganizationContext);
	const [orgNames, setOrgNames] = useState([]);

	useEffect(() => {
		async function fetchData() {
			if (organization?.children?.length > 0) {
				const response = await getOrgsByIds(organization.children, false);
				setOrgNames(response);
			}
		}

		fetchData();
	}, [organization]);

	return (
		<div style={{ width: '100%' }}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow></TableRow>
					</TableHead>
					<TableBody>
						{orgNames?.map((child, index) => (
							<TableRow
								key={index}
								sx={{
									'&:last-child td, &:last-child th': { border: 0 },
									'& td, & th': { py: '6px' }, // Adjust padding to make rows shorter
								}}>
								<TableCell component='th' scope='row'>
									{child.org}
								</TableCell>
								<TableCell align='right'>
									<Tooltip title='Open Dashboard' placement='top'>
										<Button
											onClick={() => {
												console.log(child);
												const childOrgId = child._id;

												const url = child.children
													? `/aggregateDashboard?query=${childOrgId}`
													: `/dashboard?query=${childOrgId}`;
												window.open(url, '_blank');
											}}>
											<OpenInNewIcon />
										</Button>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default Children;
