import React from 'react';
import { Button, Typography } from '@mui/material';

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		width: '100%', // This can be adjusted as needed
		padding: '20px 0', // Add padding at the top and bottom
		marginTop: '3rem',
	},
	button: {
		// Your button styles here
	},
};

const OtherLanguages = () => {
	// Function to handle opening the URL
	const openUrl = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<div style={styles.container}>
			<Typography variant='h6' color='#049669' fontWeight='600'>
				Other Language Playlists:
			</Typography>
			<Button
				style={styles.button}
				variant='contained'
				onClick={() =>
					openUrl('https://www.youtube.com/playlist?list=PLA1Md5KJwcnBrlQpwH6RIY2EzOXoLmPg5')
				}>
				Spanish
			</Button>
			<Button
				style={styles.button}
				variant='contained'
				onClick={() =>
					openUrl('https://www.youtube.com/playlist?list=PLA1Md5KJwcnDK1Ujfq0UKQ3eLgt7bz5vd')
				}>
				Portuguese
			</Button>
			<Button
				style={styles.button}
				variant='contained'
				onClick={() =>
					openUrl('https://www.youtube.com/playlist?list=PLA1Md5KJwcnCxVtFSepe7WJds_pYbM3q-')
				}>
				Vietnamese
			</Button>
			<Button
				style={styles.button}
				variant='contained'
				onClick={() =>
					openUrl('https://www.youtube.com/playlist?list=PLA1Md5KJwcnCsKGEgfCeZm0qzCrXlJyBK')
				}>
				Arabic
			</Button>
		</div>
	);
};

export default OtherLanguages;
