export const getStyles = (theme) => {
	return {
		paper: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			width: '100%',
			padding: '1rem',
			borderRadius: '1rem',
			border: `1px solid ${theme.palette.border.main}`,
		},
		header: {
			display: 'flex',
			color: '#000',
			margin: '1rem',
			borderRadius: '1rem',
			padding: '0.25rem',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-evenly',
		},
	};
};
