import { FormControl, TextField } from '@mui/material';
import React from 'react';

const DinnersServedInput = ({ dinnersServed, setDinnersServed }) => {
	return (
		<div>
			{/* <label>
				Dinners Served *
				<input
					type='number'
					value={dinnersServed}
					onChange={(e) => setDinnersServed(e.target.value)}
					required
				/>
			</label> */}
			<FormControl fullWidth={true}>
				<TextField
					type='number'
					label='Dinners Served'
					value={dinnersServed}
					onChange={(e) => setDinnersServed(e.target.value)}
					required></TextField>
			</FormControl>
		</div>
	);
};

export default DinnersServedInput;
