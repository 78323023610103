export const page = {
	width: '100vw',
	height: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: 'linear-gradient(to bottom, #3b7a4e, #b3b25a)',
};

export const container = {
	// width: '100vw',
	height: '40vh',
	padding: '3rem',
	background: '#f7f7f7',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-evenly',
	borderRadius: '1rem',
};

export const buttonStyles = {
	width: '100%',
	padding: '1rem',
	border: 'none',
	borderRadius: '30px',
	backgroundColor: '#789a3c',
	color: 'white',
	fontSize: '1.25rem',
	cursor: 'pointer',
};

export const inputField = {
	// height: '5rem',
	fontSize: '1.25rem',
	width: '100%',
	padding: '1rem',
	border: '1px solid #ddd',
	borderRadius: '1rem',
};
