import { FormControl, TextField } from '@mui/material';
import React from 'react';

const BreakfastsServedInput = ({ breakfastsServed, setBreakfastsServed }) => {
	return (
		<div className=''>
			{/* <label>
				Breakfasts Served*
				<input
					type='number'
					value={breakfastsServed}
					onChange={(e) => setBreakfastsServed(e.target.value)}
					required
				/>
			</label> */}
			<FormControl fullWidth={true}>
				<TextField
					type='number'
					label='Breakfasts Served'
					value={breakfastsServed}
					onChange={(e) => setBreakfastsServed(e.target.value)}
					required></TextField>
			</FormControl>
		</div>
	);
};

export default BreakfastsServedInput;
