import axios from 'axios';

export const finalizeEvent = async (
	eventID,
	finAttendees,
	finBreakfastsServed,
	finLunchesServed,
	finDinnersServed,
	finTotalCovers
) => {
	const url = `/.netlify/functions/finalizeEvent?eventID=${eventID}&finAttendees=${finAttendees}&finBreakfastsServed=${finBreakfastsServed}&finLunchesServed=${finLunchesServed}&finDinnersServed=${finDinnersServed}&finTotalCovers=${finTotalCovers}`;
	var data = 0;

	// console.log(
	//   eventID,
	//   finAttendees,
	//   finBreakfastsServed,
	//   finLunchesServed,
	//   finDinnersServed
	// );

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return data;
};
