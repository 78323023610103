import axios from 'axios';

export const getTreesPlanted = async (organization) => {
	const encodedOrganization = encodeURIComponent(organization);

	const url = `/.netlify/functions/getTreesPlanted?organization=${encodedOrganization}`;

	var totalTonsFed = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				totalTonsFed = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return totalTonsFed;
};
