import { Stack, Typography } from '@mui/material';
import React from 'react';
import Card3 from './components/Card3/Card3';
import Card2 from './components/Card2/Card2';
import Card1 from './components/Card1/Card1';

const OperatingSnapshot = () => {
	return (
		<Stack direction='column' alignItems={{ xs: 'center', lg: 'flex-start' }}>
			<div style={{ padding: '0.5rem 1rem 1.5rem 1rem' }}>
				{/* Section Title */}
				<Typography
					variant='h5'
					fontWeight='600'
					alignSelf={{ xs: 'center', lg: 'flex-start' }}>
					Operating Snapshot
				</Typography>
			</div>
			{/* Section Body */}
			<Stack direction='column' gap='1rem' sx={{ width: '100%' }}>
				<Card1 />
				<Card2 />
				<Card3 />
			</Stack>
		</Stack>
	);
};

export default OperatingSnapshot;
