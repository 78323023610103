import { useContext, useEffect, useState } from 'react';

import { Paper, Tooltip, useTheme } from '@mui/material';

import { OrganizationContext } from '../../../../../../../../../context/OrganizationContext';
import { getAllTimeWasteDiverted } from '../../../../../../../functions/getAllTimeWasteDiverted';

import KPI from '../../../../WeeklyReports/components/KPI';
import { getStyles } from '../../../../WeeklyReports/components/styles';

const FoodRecycledAllTime = () => {
	const theme = useTheme();
	const styles = getStyles(theme);

	const [foodRecycled, setFoodRecycled] = useState(0);

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeWasteDiverted(organization.org);
			setFoodRecycled(response);
		}
		fetchData();
	}, []);

	return (
		<Paper style={styles.paper}>
			<Tooltip title='Date the machine was installed' placement='bottom'>
				<div style={styles.innerDiv}>
					<KPI label='All Time Food Recycled' value={foodRecycled} unit={'tons'}></KPI>
				</div>
			</Tooltip>
		</Paper>
	);
};

export default FoodRecycledAllTime;
