import { useContext, useEffect } from 'react';

import { useTheme, Stack, Typography, Divider, Box } from '@mui/material';

import LogoEditor from './components/LogoEditor/LogoEditor';
import MachinePhotoEditor from './components/MachinePhotoEditor/MachinePhotoEditor';
import SampleReport from './components/SampleReport/SampleReport';
import { ColorSchemeEditor } from './components/ColorSchemeEditor/ColorSchemeEditor';
import { IntroTextEditor } from './components/IntroTextEditor/IntroTextEditor';
import { OutroTextEditor } from './components/OutroTextEditor/OutroTextEditor';
import SaveButton from './components/SaveButton/SaveButton';
import styles from './styles';

import { getDefaultReportSettings } from '../../functions/getDefaultReportSettings';
import { updateDefaultReportSettings } from '../../functions/updateDefaultReportSettings';

import { OrganizationContext } from '../../../../context/OrganizationContext';
import { ZFWEReportPreferencesContext } from '../../../../context/ZFWEReportPreferencesContext';

const Settings = () => {
	const theme = useTheme();
	const classes = styles(theme);
	const { organization } = useContext(OrganizationContext);

	const {
		introText,
		outroText,
		reportFontColor,
		reportHeaderBackground,
		reportHeaderText,
		reportStatisticText,
		setIntroText,
		setOutroText,
		setReportFontColor,
		setReportHeaderBackground,
		setReportHeaderText,
		setReportStatisticText,
	} = useContext(ZFWEReportPreferencesContext);

	const saveChanges = () => {
		const formData = {
			organization,
			introText,
			outroText,
			reportFontColor,
			reportHeaderBackground,
			reportHeaderText,
			reportStatisticText,
		};

		updateDefaultReportSettings(formData);
	};

	useEffect(() => {
		async function fetchData() {
			const defaultSettings = await getDefaultReportSettings(organization.org);

			if (defaultSettings) {
				setReportHeaderBackground(defaultSettings.reportHeaderBGColor);
				setReportHeaderText(defaultSettings.reportHeaderTextColor);
				setReportStatisticText(defaultSettings.reportStatisticColor);
				setReportFontColor(defaultSettings.reportFontColor);
				setIntroText(defaultSettings.introText || introText);
				setOutroText(defaultSettings.outroText || outroText);
			}
		}

		fetchData();
	}, [organization]);

	return (
		<Box padding={'2rem'}>
			<Typography variant='h4' mx={3} my={3} fontWeight={600}>
				Logo & Machine Photo
			</Typography>
			<Stack direction='row' spacing={3}>
				<LogoEditor />
				<MachinePhotoEditor />
			</Stack>
			<Divider sx={{ my: 5 }} />
			<Typography variant='h4' mx={3} my={5} fontWeight={600}>
				Event Report Settings
			</Typography>

			<Stack spacing='1rem'>
				<Stack direction='row' spacing='1rem'>
					<ColorSchemeEditor />
					<Stack direction='column' width='100%' spacing='1rem'>
						<IntroTextEditor />
						<OutroTextEditor />
					</Stack>
				</Stack>

				<SaveButton saveChanges={saveChanges} />
				<div style={classes.div3}>
					<SampleReport />
				</div>
			</Stack>
		</Box>
	);
};

export default Settings;
