export const getStyles = (theme) => {
	return {
		paper: {
			gap: '1rem',
			borderRadius: '1rem',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-evenly',
			border: `1px solid ${theme.palette.border.main}`,
		},
		header: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			background: theme.palette.cardHeader.background,
			borderRadius: '1rem 1rem 0rem 0rem',
			padding: '0.5rem 1rem 0.5rem 1rem ',
		},
		textArea: {
			padding: '0.5rem',
			margin: '1rem',
			borderRadius: '0.5rem',
			height: '10vh',
			resize: 'none', // Prevents resizing
			overflow: 'auto', // Adds scrollbar if text exceeds the area
			fontFamily: theme.typography.fontFamily, // Sets the font family to match MUI's default
		},
	};
};
