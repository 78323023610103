import React, { useContext, useEffect, useState } from 'react';
import KPI from './KPI';
import { getAllTimeWasteDiverted } from '../../../../functions/getAllTimeWasteDiverted';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import icon from '../../../../../../assets/foodCycling.png';
import { Paper, Tooltip, useTheme } from '@mui/material';
import { getStyles } from './styles';

const FoodDivertedFromLandfill = () => {
	const [wasteDivertedLbs, setWasteDivertedLbs] = useState();
	const { organization } = useContext(OrganizationContext);

	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeWasteDiverted(organization.org);
			setWasteDivertedLbs(response);
		}
		fetchData();
	}, []);

	return (
		<Paper style={styles.paper}>
			<Tooltip title='Total amount of food diverted from landfill in tons' placement='bottom'>
				<div style={styles.innerDiv}>
					<img src={icon} alt='' style={{ width: '42px' }} />
					<KPI label='All Time Food Recycled' value={wasteDivertedLbs} unit='tons'></KPI>
				</div>
			</Tooltip>
		</Paper>
	);
};

export default FoodDivertedFromLandfill;
