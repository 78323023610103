import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { signUp } from '../CustomerDashboard/functions/signUp';
import bg_logo from '../../assets/bg_logo.png';
import {
	buttonStyles,
	errorMessageStyle,
	forgotPassword,
	signInContainer,
	signInForm,
} from './styles';
import { OrganizationContext } from '../../context/OrganizationContext';
import { UserLocationContext } from '../../context/UserLocationContext';
import { UserContext } from '../../context/UserContext';

const SignUpForm = ({ token }) => {
	let navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const { organization, setOrganization } = useContext(OrganizationContext);
	const { setUserLocation, userLocation } = useContext(UserLocationContext);
	const { setUser } = useContext(UserContext);

	const handleSignUp = async () => {
		if (password !== confirmPassword) {
			setErrorMessage("Passwords don't match.");
			return;
		}
		try {
			const response = await signUp(token, password);
			if (response.statusCode === 200) {
				setOrganization(response.organization);
				setUserLocation(response.userLocation);
				setUser(response.user);
				navigate('/sign-in');
			} else {
				// Use the message from the response for user feedback
				setErrorMessage(response.message || 'An error occurred. Please try again later.');
			}
		} catch (error) {
			setErrorMessage('An error occurred. Please try again later.');
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSignUp();
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<div style={signInContainer}>
			<form style={signInForm} onSubmit={(e) => e.preventDefault()}>
				<img src={bg_logo} alt='Logo' className='glogo' style={{ maxWidth: '80vw' }} />

				<div
					style={{
						height: '70%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
					}}>
					<h3>Password</h3>
					<TextField
						type={showPassword ? 'text' : 'password'}
						variant='outlined'
						placeholder='Choose password'
						autoComplete='new-password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onKeyPress={handleKeyPress}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={togglePasswordVisibility} edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						type={showConfirmPassword ? 'text' : 'password'}
						variant='outlined'
						placeholder='Confirm password'
						autoComplete='new-password'
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						onKeyPress={handleKeyPress}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={toggleConfirmPasswordVisibility} edge='end'>
										{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					{errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}

					<Button variant='contained' onClick={handleSignUp} sx={buttonStyles}>
						Continue
					</Button>
				</div>
			</form>
		</div>
	);
};

export default SignUpForm;
