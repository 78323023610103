import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useContext, useState } from 'react';
import { ZFWEReportPreferencesContext } from '../../../../../../../../../../context/ZFWEReportPreferencesContext';
import { styles } from './styles';

const CarbonSequestered = ({ totalAvoidedFoodWaste, reportStatisticText }) => {
	return (
		<View style={styles.statItem}>
			<Text style={{ ...styles.statHeader, color: reportStatisticText }}>
				Carbon Sequestered
			</Text>
			<Text style={{ ...styles.statValue, color: reportStatisticText }}>
				{(totalAvoidedFoodWaste * 1.17).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} lbs
			</Text>
		</View>
	);
};

export default CarbonSequestered;
