import axios from 'axios';

export const getOrganizationImage = async (path) => {
	const url = `/.netlify/functions/getOrganizationImage?path=${path}`;

	try {
		const response = await axios.get(url);
		if (response) {
			const base64Image = response.data;
			return base64Image;
		} else {
			console.log('No data received');
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
};
