import { FormControl, TextField } from '@mui/material';
import React from 'react';

const EndDatePicker = ({ endDate, setEndDate }) => {
	return (
		<div>
			<FormControl fullWidth={true}>
				<TextField
					label='End Date'
					type='date'
					value={endDate}
					onChange={(e) => setEndDate(e.target.value)}
					required
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</FormControl>
		</div>
	);
};

export default EndDatePicker;
