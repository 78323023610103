import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

const CountdownTimer = ({ targetDate, sx }) => {
	const calculateTimeLeft = () => {
		const difference = +new Date(targetDate) - +new Date();
		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearTimeout(timer);
	});

	const timerComponents = [];

	Object.keys(timeLeft).forEach((interval) => {
		if (!timeLeft[interval]) {
			return;
		}

		timerComponents.push(
			<Grid item key={interval}>
				<Paper
					elevation={3}
					sx={{
						padding: '32px 16px 32px 16px',
						textAlign: 'center',
						minWidth: '160px',
						backgroundColor: '#f0f0f0',
					}}>
					<Typography variant='h2'>{timeLeft[interval]}</Typography>
					<Typography variant='subtitle1'>{interval}</Typography>
				</Paper>
			</Grid>
		);
	});

	return (
		<Box sx={{ textAlign: 'center', padding: '20px', ...sx }}>
			{timerComponents.length ? (
				<Grid container spacing={2} justifyContent='center'>
					{timerComponents}
				</Grid>
			) : (
				<Typography variant='h5'>Time's up!</Typography>
			)}
		</Box>
	);
};

export default CountdownTimer;
