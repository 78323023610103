import { Button, Divider, Stack } from '@mui/material';
import React from 'react';
import Renders from './components/Renders/Renders';
import ITPreferences from './components/Network/Network';
import MachineCutsheet from './components/MachineCutsheet/MachineCutsheet';
import SpaceReady from './components/SpaceReady/SpaceReady';
import ContainerCutsheet from './components/ContainerCutsheet/ContainerCutsheet';

const PreLaunch = () => {
	return (
		<Stack sx={{ m: '1rem' }}>
			<Renders />
			<Divider sx={{ my: '5rem' }} />
			<MachineCutsheet />
			<Divider sx={{ my: '5rem' }} />
			<ContainerCutsheet />
			<Divider sx={{ my: '5rem' }} />
			<ITPreferences />
			<Divider sx={{ my: '5rem' }} />
			<SpaceReady />
			<Divider sx={{ my: '5rem' }} />
		</Stack>
	);
};

export default PreLaunch;
