import { Paper, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ZFWEReportPreferencesContext } from '../../../../../../../../context/ZFWEReportPreferencesContext';
import { getFWperCoverZFWE } from '../../../../../../functions/getFWperCoverZFWE';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';

export const FoodWastePerCover = () => {
	const theme = useTheme();
	const { FWperCover, setFWperCover, event } = useContext(ZFWEReportPreferencesContext);
	const { organization } = useContext(OrganizationContext);
	const [formattedWeekStart, setFormattedWeekStart] = useState('');
	const [formattedWeekEnd, setFormattedWeekEnd] = useState('');
	const [timeframeAccurate, setTimeframeAccurate] = useState(false);
	const [month, setMonth] = useState();
	const [year, setYear] = useState();

	// Array mapping month numbers to month names
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	function formatDate(dateString) {
		const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
		const date = new Date(`${dateString}T00:00:00Z`);

		// Append the suffix 'st', 'nd', 'rd', or 'th' to the day
		const day = date.getUTCDate();
		let suffix = 'th';
		if (day % 10 === 1 && day !== 11) suffix = 'st';
		else if (day % 10 === 2 && day !== 12) suffix = 'nd';
		else if (day % 10 === 3 && day !== 13) suffix = 'rd';

		const formattedDate = date.toLocaleDateString('en-US', options);
		const dayWithSuffix = `${day}${suffix}`;

		// Replace the day number in formattedDate with dayWithSuffix
		return formattedDate.replace(day, dayWithSuffix);
	}

	useEffect(() => {
		async function fetchData() {
			const response = await getFWperCoverZFWE(
				organization._id,
				event.eventEnd,
				event.eventStart
			);

			setTimeframeAccurate(
				event.eventStart > response.weekStart && event.eventStart < response.weekEnd
			);
			setFWperCover(response.FWPerCover);
			setYear(response.year);
			setMonth(monthNames[response.month - 1]); // Adjust month for zero-based index

			const weekStartDate = new Date(response.weekStart);
			const weekEndDate = new Date(response.weekEnd);

			// Format dates
			setFormattedWeekStart(
				weekStartDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })
			);

			setFormattedWeekEnd(
				weekEndDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })
			);
			// console.log(weekStartDate, weekEndDate);
		}
		fetchData();
	}, [organization]);

	return (
		<Paper
			style={{
				gap: '1rem',
				borderRadius: '1rem',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				border: `1px solid ${theme.palette.border.main}`,
				margin: '1rem',
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					background: theme.palette.cardHeader.background,
					borderRadius: '1rem 1rem 0rem 0rem',
					padding: '0.5rem 1rem 0.5rem 1rem ',
				}}>
				<Typography variant='h5'>Food Waste Per Cover</Typography>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					marginBottom: '1rem',
				}}>
				<Typography variant='p' fontWeight='600'>
					As of {month} {year}
				</Typography>
				<Typography variant='h4'>{Number(FWperCover).toFixed(2)} oz</Typography>
				<Typography variant='p'>
					Based on cover data from Week of ({formattedWeekStart} - {formattedWeekEnd})
				</Typography>
				{!timeframeAccurate && (
					<Typography mt={1} color='error'>
						More recent data unavailable, please update covers
					</Typography>
				)}
			</div>
		</Paper>
	);
};
