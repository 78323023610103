import axios from 'axios';

export const getAvgUptimeLast30Days = async (organization) => {
	const url = `/.netlify/functions/getAvgUptimeLast30Days`;
	let avgUptimeLast30Days = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				avgUptimeLast30Days = res.data;
			} else {
				console.log('Failed to fetch avg utilization last 30 days');
			}
		})
		.catch((err) => {
			console.error('Error fetching avg utilization last 30 days:', err);
		});

	return avgUptimeLast30Days;
};
