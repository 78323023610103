import { Button, Paper, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ZFWEReportPreferencesContext } from '../../../../../../../../../../context/ZFWEReportPreferencesContext';

export const OutroTextEditor = () => {
	const theme = useTheme();
	const { outroText, setOutroText } = useContext(ZFWEReportPreferencesContext);
	const [inputValue, setInputValue] = useState(outroText);

	useEffect(() => {
		if (outroText) {
			setInputValue(outroText);
		}
	}, [outroText]);

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
		setOutroText(event.target.value);
	};

	return (
		<Paper
			style={{
				gap: '1rem',
				borderRadius: '1rem',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
				border: `1px solid ${theme.palette.border.main}`,
			}}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: theme.palette.cardHeader.background,
					borderRadius: '1rem 1rem 0 0',
					padding: '0.5rem 1rem',
				}}>
				<Typography variant='h5'>Outro Text</Typography>
			</div>
			<textarea
				value={inputValue}
				onChange={handleInputChange}
				style={{
					padding: '0.5rem',
					margin: '1rem',
					borderRadius: '0.5rem',
					height: '10vh',
					resize: 'none', // Prevents resizing
					overflow: 'auto', // Adds scrollbar if text exceeds the area
					fontFamily: theme.typography.fontFamily, // Sets the font family to match MUI's default
				}}
			/>
		</Paper>
	);
};
