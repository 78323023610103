export const getStyles = (theme) => ({
	paper: {
		gap: '1rem',
		borderRadius: '1rem',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		border: `1px solid ${theme.palette.border.main}`,
		boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// background: theme.palette.cardHeader.background,
		borderRadius: '1rem 1rem 0rem 0rem',
		padding: '1rem 1rem 1rem ',
	},
});
