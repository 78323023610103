import axios from 'axios';

export const getAllTimeCarbonOffsets = async (organization) => {
	const encodedOrganization = encodeURIComponent(organization);

	const url = `/.netlify/functions/getAllTimeCarbonOffsets?organization=${encodedOrganization}`;
	var totalCarbonOffsets = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				totalCarbonOffsets = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return totalCarbonOffsets;
};
