import axios from 'axios';

export const getCoverCountsAggregate = async (organization) => {
	const url = `/.netlify/functions/getCoverCountsAggregate`;
	let coverCounts = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				coverCounts = res.data;
			} else {
				console.log('Failed to fetch cover counts last 30 days');
			}
		})
		.catch((err) => {
			console.error('Error fetching cover counts last 30 days:', err);
		});

	return coverCounts;
};
