import { Paper, Typography, useTheme } from '@mui/material';
import React from 'react';

const DeleteConfirmationMessage = ({ eventName, attendees, startDate, endDate }) => {
	const theme = useTheme();
	console.log(`EventName: ${eventName}`);
	console.log(`attendees: ${attendees}`);
	console.log(`startDate: ${startDate}`);
	console.log(`endDate: ${endDate}`);

	return (
		<Paper
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				color: '#000',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '1rem',
				margin: '0 1rem 1rem 1rem',
				border: `1px solid ${theme.palette.border.main}`,
				padding: '0.25rem',
				borderRadius: '0 0 0.5rem 0.5rem',
			}}>
			<Typography variant='h5' fontWeight='600'>
				Are you sure you wish to delete the following event?
			</Typography>
			<Typography>`{eventName}`</Typography>
			<Typography>Attendees: {attendees}</Typography>
			<Typography>
				From {startDate} - {endDate}
			</Typography>
		</Paper>
	);
};

export default DeleteConfirmationMessage;
