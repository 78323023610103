import { Box, Paper, useTheme } from '@mui/material';
import React from 'react';

const MetricCard = ({ children, sx }) => {
	const theme = useTheme();

	return (
		<Paper
			sx={{
				gap: '1rem',
				borderRadius: '1rem',
				border: `1px solid ${theme.palette.border.main}`,
				boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -2px rgba(0, 0, 0, 0.05)',
				...sx,
			}}>
			<Box
				sx={{
					padding: '0.5rem 1rem 0.5rem 1rem ',
				}}>
				{children}
			</Box>
		</Paper>
	);
};

export default MetricCard;
