import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FoodRecycledAllTime from './components/FoodRecycledAllTime';
import EquivalentC02 from './components/EquivalentCO2';
import CompostCreated from './components/CompostCreated';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getInstallDate } from '../../../../../../functions/getInstallDate';

const AllTimeMetrics = () => {
	const [installDate, setInstallDate] = useState('N/A');

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getInstallDate(organization.org);
			setInstallDate(response);
		}
		fetchData();
		const interval = setInterval(fetchData, 30000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box sx={{ width: '100%' }}>
			<Stack
				direction='row'
				gap='1rem'
				sx={{
					justifyContent: 'space-between',
					alignItems: 'center',
					margin: '1rem',
				}}>
				<Typography variant='h5' fontWeight='600'>
					All-Time Metrics
				</Typography>
				<Typography variant='p' sx={{ textAlign: 'center' }}>
					Since the Install Date of <strong>{installDate === 0 ? 'N/A' : installDate}</strong>
				</Typography>
			</Stack>
			<Stack direction='row' gap='1rem' width='100%'>
				<FoodRecycledAllTime />
				<EquivalentC02 />
				<CompostCreated />
			</Stack>
		</Box>
	);
};

export default AllTimeMetrics;
