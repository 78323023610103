import React, { useEffect, useState } from 'react';
import { getStyles } from './styles';
import { getCoverIssues } from '../../../functions/getCoverIssues';
import { Typography, IconButton, useTheme, Box } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { monthNames } from '../../../../../constants/constants';
import { getCoverChampions } from '../../../functions/getCoverChampions';

const CoversStatus = ({ org }) => {
	const [missingMonths, setMissingMonths] = useState([]);
	const [coversChampion, setCoversChampion] = useState();

	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchCoverIssues() {
			const response = await getCoverIssues(org._id);

			if (response && response.length > 0) {
				const uniqueDates = new Set(
					response.map((missingMonthYearPair) => {
						return `${monthNames[missingMonthYearPair.month - 1]} ${
							missingMonthYearPair.year
						}`;
					})
				);
				const sortedDates = Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
				setMissingMonths(sortedDates);
			}
		}
		if (org._id) {
			fetchCoverIssues();
		}
	}, [org._id]);

	useEffect(() => {
		async function fetchCoversChampions() {
			const response = await getCoverChampions(org.org);
			setCoversChampion(response);
		}
		if (org.org) {
			fetchCoversChampions();
		}
	}, [org]);

	return (
		<Box style={styles.gridItemStyle} position='relative'>
			<IconButton
				color='primary'
				onClick={() => {
					window.open(
						`/dashboard?query=${org._id}&page=Covers`,
						'_blank',
						'noopener,noreferrer'
					);
				}}
				aria-label='open in new tab'
				style={{ position: 'absolute', top: 0, right: 0 }}>
				<OpenInNewIcon />
			</IconButton>
			<Typography variant='h5'>Covers</Typography>
			<Typography variant='h4' fontWeight={600} padding={'1rem'}>
				{!org.hasCovers ? (
					'N/A'
				) : missingMonths.length > 0 ? (
					<span style={{ color: 'red' }}>Missing Months</span>
				) : (
					'Up To Date'
				)}
			</Typography>
			{coversChampion?.length ? (
				<Typography>
					Covers Champion: {`${coversChampion[0].FIRST} ${coversChampion[0].LAST}`}
				</Typography>
			) : null}
		</Box>
	);
};

export default CoversStatus;
