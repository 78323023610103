import axios from 'axios';

export const resetPassword = async (token, password) => {
	try {
		const response = await axios.post('/.netlify/functions/resetPassword', {
			token,
			password,
		});

		// Expecting the lambda to return a JSON response
		return {
			statusCode: response.status,
			message: response.data.message,
		};
	} catch (error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			return {
				statusCode: error.response.status,
				message: error.response.data.message,
			};
		} else if (error.request) {
			// The request was made but no response was received
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		}
		return {
			statusCode: 500,
			message: 'Network error',
		};
	}
};
