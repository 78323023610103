import { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import Report from './components/Report';

import { getMonthYearsAllTime } from '../../../../functions/getMonthYearsAllTime';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
import YearSelector from './components/YearSelector';
import MonthSelector from './components/MonthSelector';

const MonthlyReports = () => {
	const { organization } = useContext(OrganizationContext);

	const [months, setMonths] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState(null);

	const [years, setYears] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);

	const [monthYears, setMonthYears] = useState([]);
	const [selectedMonthYear, setSelectedMonthYear] = useState(null);

	const [loading, setLoading] = useState(true);

	// get list of reports to populate report selector list
	useEffect(() => {
		async function fetchData() {
			const response = await getMonthYearsAllTime(organization._id);

			setMonthYears(response);
			// Extract unique years
			const uniqueYears = new Set();
			response.forEach((item) => uniqueYears.add(item.year));

			// Convert set to sorted array
			const sortedYears = Array.from(uniqueYears).sort((a, b) => b - a);

			setYears(sortedYears);
			setSelectedYear(sortedYears[0]);
			setLoading(false); // Set loading to false once the data is loaded
		}
		fetchData();
	}, [organization]);

	// Update months based on selectedYear
	useEffect(() => {
		if (selectedYear) {
			const filteredMonths = monthYears
				.filter((item) => item.year === selectedYear)
				.map((item) => item.month)
				.sort((a, b) => b - a);
			setMonths(filteredMonths);
			setSelectedMonth(filteredMonths[0]);
		}
	}, [selectedYear, monthYears]);

	// Update selectedMonthYear whenever selectedMonth or selectedYear changes
	useEffect(() => {
		if (selectedMonth && selectedYear) {
			const monthYear = monthYears.find(
				(item) => item.year === selectedYear && item.month === selectedMonth
			);
			setSelectedMonthYear(monthYear);
		}
	}, [selectedMonth, selectedYear, monthYears]);

	return loading ? (
		<>Loading..</>
	) : (
		<Box sx={{ width: '100%', minHeight: '70vh' }}>
			<Stack direction='column' sx={{ margin: '1rem', gap: '2rem' }} alignItems={'flex-start'}>
				<Stack direction='row' gap='1rem' sx={{ width: '100%' }}>
					<YearSelector
						years={years}
						setSelectedYear={setSelectedYear}
						selectedYear={selectedYear}
					/>
					<MonthSelector
						months={months}
						selectedMonth={selectedMonth}
						setSelectedMonth={setSelectedMonth}
					/>
				</Stack>

				<Report selectedMonthYear={selectedMonthYear} />
			</Stack>
		</Box>
	);
};

export default MonthlyReports;
