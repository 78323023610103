import React, { useEffect, useState } from 'react';
import { getStyles } from './styles';
import { Typography, useTheme } from '@mui/material';
import { getTimeSinceLastFeed } from '../../../functions/getTimeSinceLastFeed';

const TimeSincelastFeed = ({ orgID }) => {
	const [timeSinceLastFeed, setTimeSinceLastFeed] = useState('');
	const theme = useTheme();
	const styles = getStyles(theme);

	useEffect(() => {
		async function fetchData() {
			const response = await getTimeSinceLastFeed(orgID);
			if (response && response.timestamp) {
				// Convert the timestamp string to a Date object
				const lastFeedDate = new Date(response.timestamp);
				const now = new Date();
				// Calculate the difference in milliseconds
				const difference = now - lastFeedDate;
				// Convert the difference to hours and minutes
				const diffHours = Math.floor(difference / (1000 * 60 * 60));
				const diffMinutes = Math.floor((difference / (1000 * 60)) % 60);
				// Format the time since the last feed
				const formattedTime = `${diffHours} Hrs ${diffMinutes} Mins`;
				setTimeSinceLastFeed(formattedTime);
			}
		}
		if (orgID) {
			fetchData();
		}
	}, [orgID]);

	return (
		<div style={styles.gridItemStyle}>
			<Typography variant='h5'>Time Since Last Feed</Typography>
			<Typography variant='h4' fontWeight={600} padding={'1rem'}>
				{timeSinceLastFeed ? timeSinceLastFeed : 'Calculating...'}
			</Typography>
		</div>
	);
};

export default TimeSincelastFeed;
