import { FormControl, TextField } from '@mui/material';
import React from 'react';

const TotalProjectedCovers = ({ totalCovers }) => {
	return (
		<div>
			<FormControl fullWidth={true}>
				<TextField
					label='Total Projected Covers'
					type='number'
					value={totalCovers}
					disabled
					variant='outlined'
					InputProps={{
						readOnly: true,
					}}
				/>
			</FormControl>
		</div>
	);
};

export default TotalProjectedCovers;
