import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SignUpForm from './SignUpForm';

const SignUp = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('token');

	useEffect(() => {
		if (token) {
			// You now have access to the token
		} else {
			// if no token or token cannot be found in globaUsers, return to sign in page or say you do not have permission to access this page click to return to sign in
		}
	}, [token]);

	// Use the token for signup process

	return (
		<div>
			<SignUpForm token={token} />
		</div>
	);
};

export default SignUp;
