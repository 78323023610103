import axios from 'axios';

export const getDailyWeightByDateRange = async (orgID, year, month) => {
	const url = `/.netlify/functions/getDailyWeightByDateRange?orgID=${orgID}&month=${month}&year=${year}`;

	var foodRecycled = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				foodRecycled = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return foodRecycled;
};
