export const getStyles = (theme) => ({
	paper: {
		gap: '1rem',
		borderRadius: '1rem',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		border: `1px solid ${theme.palette.border.main}`,
		boxShadow: 'none',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// background: theme.palette.cardHeader.background,
		borderRadius: '1rem 1rem 0rem 0rem',
		padding: '1rem 1rem 1rem ',
	},
});
