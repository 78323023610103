import React, { useContext, useEffect, useState } from 'react';
import KPI from '../../../../../components/Cards/KPI';
import { OrganizationContext } from '../../../../../../../context/OrganizationContext';
import { getAllTimeCarbonOffsetsAggregate } from '../../../../../functions/getAllTimeCarbonOffsetsAggregate';
import icon from '../../../../../../../assets/carbonSequestered.png';
import { Stack } from '@mui/material';

const AllTimeCarbonOffsets = () => {
	const [carbonOffsets, setCarbonOffsets] = useState();

	const { organization } = useContext(OrganizationContext);

	useEffect(() => {
		async function fetchData() {
			const response = await getAllTimeCarbonOffsetsAggregate(organization._id);
			setCarbonOffsets(response);
		}
		fetchData();
	}, [organization]);

	return (
		<Stack direction='column' alignItems='center'>
			<img src={icon} alt='' style={{ width: '42px', marginBottom: '0.5rem' }} />

			<KPI label='Equivalent CO₂ Sequestered' value={carbonOffsets} subtext='tons'></KPI>
		</Stack>
	);
};

export default AllTimeCarbonOffsets;
