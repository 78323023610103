import { useContext, useState } from 'react';
import TabSelector from './components/TabSelector';
import Aggregate from './tabs/Aggregate/Aggregate';
import Individual from './tabs/Individual/Individual';
import trends1 from '../../../../assets/examples/aggScreenshots/trends1.png';
import trends2 from '../../../../assets/examples/aggScreenshots/trends2.png';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { showPreLaunch } from '../../../../utils/showPreLaunch';
import ExamplePageWarning from '../../../CustomerDashboard/components/ExamplePageWarning/ExamplePageWarning';

const Trends = () => {
	const [currentTab, setCurrentTab] = useState('Aggregate');
	const { organization } = useContext(OrganizationContext);

	return (
		<>
			<TabSelector currentTab={currentTab} setCurrentTab={setCurrentTab} />
			{showPreLaunch(organization) ? (
				<>
					<ExamplePageWarning />
					<img src={trends1} alt='trends' />
					<img src={trends2} alt='trends' />
				</>
			) : (
				<>
					{currentTab === 'Individual' && <Individual />}
					{currentTab === 'Aggregate' && <Aggregate />}
				</>
			)}
		</>
	);
};

export default Trends;
