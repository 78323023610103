import { TextField } from '@mui/material';
import React, { useState } from 'react';

const Space = () => {
	const [space, setSpace] = useState('');

	const handleChange = (event) => {
		setSpace(event.target.value);
	};

	const handleSubmit = () => {
		console.log(`Event space: ${space}`);
	};

	return (
		<TextField
			label='Event Space (sq. ft)'
			variant='outlined'
			type='number'
			fullWidth
			value={space}
			onChange={handleChange}
		/>
	);
};

export default Space;
