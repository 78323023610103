import axios from 'axios';

export const getFoodRecycledByDateRange = async (orgID, startDate, endDate) => {
	const url = `/.netlify/functions/getFoodRecycledByDateRange?orgID=${orgID}&startDate=${startDate}&endDate=${endDate}`;

	var foodRecycled = 0;

	await axios
		.get(url)
		.then((res) => {
			if (res.data != null && res.data !== false) {
				foodRecycled = res.data;
			} else {
				console.log('failed');
			}
		})
		.catch((err) => {
			console.log(err);
		});

	return foodRecycled;
};
