import { Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { OrganizationContext } from '../../../../../../../../../../context/OrganizationContext';
import { getDiversionbyCategoryLast30Days } from '../../../../../../../../functions/getDiversionbyCategoryLast30Days';

const DiversionByCategoryLast30Days = () => {
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Percentage',
				data: [],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
					'rgba(33, 150, 243, 0.2)',
					'rgba(255, 99, 132, 0.2)',
					// Add more colors if needed
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
					'rgba(33, 150, 243, 1)',
					'rgba(255, 99, 132, 1)',
					// Add more colors if needed
				],
				borderWidth: 1,
			},
		],
	});
	const { organization } = useContext(OrganizationContext);
	useEffect(() => {
		async function fetchData() {
			const response = await getDiversionbyCategoryLast30Days(organization._id);
			if (response && response.departments) {
				const labels = [];
				const data = [];
				const backgroundColor = [];
				const borderColor = [];

				Object.keys(response.departments).forEach((key) => {
					const department = response.departments[key];
					labels.push(department.title);
					data.push(parseFloat(department.percentage));
					// Prepare color - assume color is in HEX format, add 'rgba' wrapper
					const color = `#${department.color}`;
					backgroundColor.push(color);
					borderColor.push(color);
				});

				setChartData((prevState) => ({
					...prevState,
					labels: labels,
					datasets: [
						{
							...prevState.datasets[0],
							data: data,
							backgroundColor: backgroundColor,
							borderColor: borderColor,
						},
					],
				}));
			}
		}
		fetchData();
	}, [organization]);

	return (
		<Stack direction='column' sx={{ padding: '1rem', alignItems: 'center' }}>
			<Typography variant='h5'>Diversion By Category (Last 30 Days)</Typography>
			<Pie data={chartData} />
		</Stack>
	);
};

export default DiversionByCategoryLast30Days;
