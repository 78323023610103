import axios from 'axios';

export const getWeekliesAllTimeAggregate = async (organization) => {
	const url = `/.netlify/functions/getWeekliesAllTimeAggregate`;
	let data = 0;

	await axios
		.post(url, { organization })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				data = res.data;
			} else {
				console.log('Failed to fetch miles driven');
			}
		})
		.catch((err) => {
			console.error('Error fetching miles driven:', err);
		});

	return data;
};
