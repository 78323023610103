import React, { useContext, useEffect, useState } from 'react';
import EventNameInput from './components/EventNameInput';
import CategorySelector from './components/CategorySelector';
import AttendeesInput from './components/AttendeesInput';
import StartDatePicker from './components/StartDatePicker';
import EndDatePicker from './components/EndDatePicker';
import DemographicSelector from './components/DemographicSelector';
import BreakfastsServedInput from './components/BreakfastsServedInput';
import LunchesServedInput from './components/LunchesServedInput';
import DinnersServedInput from './components/DinnersServedInput';
import TotalProjectedCovers from './components/TotalProjectedCovers';
import SubmitButton from './components/SubmitButton';
import { Typography } from '@mui/material';
import { addNewEvent } from '../../../../functions/addNewEvent';
import { OrganizationContext } from '../../../../../../context/OrganizationContext';
// import HowToSell from './components/HowToSell';

const AddNewEvent = () => {
	const { organization } = useContext(OrganizationContext);

	const [eventName, setEventName] = useState('');
	const [category, setCategory] = useState('');
	const [attendees, setAttendees] = useState();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [demographic, setDemographic] = useState('');
	const [breakfastsServed, setBreakfastsServed] = useState(0);
	const [lunchesServed, setLunchesServed] = useState(0);
	const [dinnersServed, setDinnersServed] = useState(0);
	const [totalCovers, setTotalCovers] = useState(0);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

	// Handler for form submission
	const handleSubmit = (event) => {
		event.preventDefault();
		async function sendData() {
			const response = await addNewEvent(
				organization.org,
				eventName,
				category,
				attendees,
				startDate,
				endDate,
				demographic,
				breakfastsServed,
				lunchesServed,
				dinnersServed,
				totalCovers
			);

			if (response === 'success') {
				setEventName('');
				setCategory('');
				setAttendees(0);
				setStartDate('');
				setEndDate('');
				setDemographic('');
				setBreakfastsServed(0);
				setLunchesServed(0);
				setDinnersServed(0);
				setTotalCovers(0);
			}
		}

		sendData();
	};

	useEffect(() => {
		setTotalCovers(
			parseInt(attendees || 0) *
				(parseInt(breakfastsServed || 0) +
					parseInt(lunchesServed || 0) +
					parseInt(dinnersServed || 0))
		);
	}, [breakfastsServed, lunchesServed, dinnersServed, attendees]);

	useEffect(() => {
		if (
			eventName &&
			category &&
			attendees &&
			startDate &&
			endDate &&
			demographic &&
			totalCovers
		) {
			// console.log('setting submit disabled to false');
			setSubmitButtonDisabled(false);
		} else {
			// console.log(eventName, category, attendees, startDate, endDate, demographic, totalCovers);
		}
	}, [eventName, category, attendees, startDate, endDate, totalCovers, demographic]);

	return (
		<div className='column aic jcse w100' style={{ height: '75vh' }}>
			<div style={{ width: '98%', margin: '1rem' }}>
				<Typography variant='h5' fontWeight='600'>
					Add New Event Plan
				</Typography>
				<Typography variant='p'>
					Please fill out all of the Projected Event Details with the most accurate information
					available.
				</Typography>
			</div>

			<form onSubmit={handleSubmit} className='w100 column h100' style={{ gap: '2rem' }}>
				<div className='row w100 h100 jcse' style={{}}>
					<div className='column h100 w100' style={{ gap: '2rem', margin: '1rem' }}>
						<EventNameInput eventName={eventName} setEventName={setEventName} />
						<CategorySelector category={category} setCategory={setCategory} />
						<AttendeesInput attendees={attendees} setAttendees={setAttendees} />
					</div>
					<div className='column h100 w100' style={{ gap: '2rem', margin: '1rem' }}>
						<StartDatePicker startDate={startDate} setStartDate={setStartDate} />
						<EndDatePicker endDate={endDate} setEndDate={setEndDate} />
						<DemographicSelector demographic={demographic} setDemographic={setDemographic} />
					</div>
				</div>
				<div className='column h100 w100 aic' style={{}}>
					<Typography variant='h6' fontWeight='600'>
						Projected Meals Served
					</Typography>

					<div className='row w100 jcse aic' style={{ margin: '1rem' }}>
						<BreakfastsServedInput
							breakfastsServed={breakfastsServed}
							setBreakfastsServed={setBreakfastsServed}
						/>
						<Typography variant='p'>+</Typography>
						<LunchesServedInput
							lunchesServed={lunchesServed}
							setLunchesServed={setLunchesServed}
						/>
						<Typography variant='p'>+</Typography>
						<DinnersServedInput
							dinnersServed={dinnersServed}
							setDinnersServed={setDinnersServed}
						/>
						<Typography variant='p'>=</Typography>
						<TotalProjectedCovers totalCovers={totalCovers} />
					</div>

					<SubmitButton submitButtonDisabled={submitButtonDisabled} />
				</div>
			</form>
			{/* <HowToSell /> */}
		</div>
	);
};

export default AddNewEvent;
