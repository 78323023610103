import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../context/OrganizationContext';
import { useLocation, useNavigate } from 'react-router-dom';

import Home from './tabs/Home/Home';
import Trends from './tabs/Trends/Trends';
import Compliance from './tabs/Compliance/Compliance';
import Locations from './tabs/Locations/Locations';
import Reports from './tabs/Reports/Reports';
import Celebrations from './tabs/Celebrations/Celebrations';
import Users from './tabs/Users/Users';
import Covers from './tabs/Covers/Covers';

import Sidebar from './components/Sidebar/Sidebar';
import NavBar from './components/NavBar/NavBar';
import { Watermark } from './components/Watermark/Watermark';
import Footer from '../../components/Footer/Footer';

import { getOrgById } from '../CustomerDashboard/functions/getOrgById';
import { bodyStyle, mainContentStyle } from './styles';
import PreLaunch from '../CustomerDashboard/tabs/PreLaunch/PreLaunch';
import { showPreLaunch } from '../../utils/showPreLaunch';

const AggregateDashboard = () => {
	const { organization, setOrganization } = useContext(OrganizationContext);

	// const [tab, setTab] = useState('Covers');
	const [tab, setTab] = useState(!showPreLaunch(organization) ? 'Home' : 'Pre-Launch');

	const [isMobile, setIsMobile] = useState(false);
	const [showMobileSidebar, setShowMobileSidebar] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	// Function to parse query parameters
	const getQueryParams = (query) => {
		return new URLSearchParams(query);
	};

	// Fetch and set organization on component mount or when the URL changes
	useEffect(() => {
		const queryParams = getQueryParams(location.search);
		const orgId = queryParams.get('query');

		if (orgId) {
			setIsLoading(true); // Set loading to true when starting to fetch data
			getOrgData(orgId)
				.then((data) => {
					setOrganization(data); // Update the OrganizationContext with the fetched data\
					setIsLoading(false); // Set loading to false once the data is fetched
				})
				.catch((error) => {
					console.error('Failed to fetch organization data:', error);
					setIsLoading(false); // Ensure loading is set to false even on error
				});
		} else {
			setIsLoading(false); // Set loading to false if orgId is not in the query params
		}
	}, [location, setOrganization]);

	// return to sign in page if no organization stored in context (this happens on sign in)
	useEffect(() => {
		if (!organization) {
			navigate('/sign-in');
		}
	}, [organization, navigate]);

	// Hide / Show sidebar if mobile / desktop
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < window.innerHeight);
		};

		handleResize(); // Call it once on initial load

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (isLoading) {
		return null; // or return <LoadingSpinner />; if you have a loading spinner component
	}
	return (
		organization && (
			<div style={bodyStyle}>
				<Watermark />
				{(showMobileSidebar || !isMobile) && (
					<Sidebar
						tab={tab}
						setTab={setTab}
						isMobile={isMobile}
						showMobileSidebar={showMobileSidebar}
						setShowMobileSidebar={setShowMobileSidebar}
					/>
				)}
				<div style={mainContentStyle}>
					<NavBar
						tab={tab}
						isMobile={isMobile}
						showMobileSidebar={showMobileSidebar}
						setShowMobileSidebar={setShowMobileSidebar}
					/>
					{tab === 'Home' && <Home />}
					{tab === 'Trends' && <Trends />}
					{tab === 'Compliance' && <Compliance />}
					{tab === 'Covers' && <Covers />}
					{tab === 'Celebrations' && <Celebrations />}
					{tab === 'Users' && <Users />}
					{tab === 'Locations' && <Locations />}
					{tab === 'Reports' && <Reports />}
					{tab === 'Pre-Launch' && <PreLaunch />}
					<Footer />
				</div>
			</div>
		)
	);
};

const getOrgData = async (orgId) => {
	async function fetchData() {
		if (orgId) {
			const response = await getOrgById(orgId);
			return response;
		}
	}

	return fetchData();
};

export default AggregateDashboard;
