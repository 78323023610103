import axios from 'axios';

export const getRenderings = async (location) => {
	const url = `/.netlify/functions/getRenderings`;
	let renderings = 0;

	await axios
		.post(url, { location })
		.then((res) => {
			if (res.data != null && res.data !== false) {
				renderings = res.data;
			} else {
				console.log('Failed to fetch renders');
			}
		})
		.catch((err) => {
			console.error('Error fetching fetch renders:', err);
		});

	return renderings;
};
