import React, { useRef, useState } from 'react';
import { buttonStyles, container, inputField, page } from './styles';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../CustomerDashboard/functions/forgotPassword';

const ForgotPassword = () => {
	const emailRef = useRef();
	let navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState('');

	const handleContinue = async () => {
		try {
			// Get the email from the ref
			const email = emailRef.current.value;
			const response = await forgotPassword(email);

			console.log(response.status);

			// Check if the response indicates success
			if (response.status === 200) {
				navigate('/forgot-password-confirmation'); // Navigate to confirmation page
			} else {
				setErrorMessage('An error occurred. Please try again later.');
			}
		} catch (error) {
			setErrorMessage('An error occurred. Please try again later.');
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleContinue();
		}
	};

	return (
		<div style={page}>
			<div style={container}>
				<Typography variant='h3'>Forgot Password</Typography>
				<Typography variant='h6'>Enter your email address</Typography>
				<input
					type='email'
					style={inputField}
					placeholder='Enter your email'
					autoComplete='current-email'
					ref={emailRef}
					onKeyPress={handleKeyPress} // Added keypress event handler
				/>
				<Button variant='contained' onClick={handleContinue} sx={buttonStyles}>
					Continue
				</Button>
			</div>
		</div>
	);
};

export default ForgotPassword;
