import axios from 'axios';

export const getMapCoordinatesAggregate = async (organizationIds) => {
	// Join the array of organization IDs into a comma-separated string
	const organizationIdsString = organizationIds.join(',');

	const url = `/.netlify/functions/getMapCoordinatesAggregate?organizationIds=${encodeURIComponent(
		organizationIdsString
	)}`;
	let data = [];

	try {
		const res = await axios.get(url);
		if (res.data) {
			data = res.data;
		} else {
			console.log('No data received');
		}
	} catch (err) {
		console.log(err);
	}

	return data;
};
