import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { OrganizationContext } from '../../../../../../../../context/OrganizationContext';
import { getFoodWastePerCoverByMonthAndYear } from '../../../../../../functions/getFoodWastePerCoverByMonthAndYear';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const lineColors = [
	'#FF6384',
	'#36A2EB',
	'#FFCE56',
	'#4BC0C0',
	'#9966FF',
	'#FF9F40',
	'#FF6384',
	'#36A2EB',
	'#FFCE56',
	'#4BC0C0',
	'#9966FF',
	'#FF9F40',
	// Add more colors if you have more than 12 lines
];
const FoodWastePerCoverByMonthAndYear = () => {
	const theme = useTheme();
	const { organization } = useContext(OrganizationContext);
	const [chartData, setChartData] = useState({ labels: [], datasets: [] });

	useEffect(() => {
		async function fetchData() {
			const response = await getFoodWastePerCoverByMonthAndYear(organization._id);
			processData(response);
		}

		const processData = (response) => {
			const { monthAndYear, avgFWPerCover } = response;
			const dataByYear = {};
			const monthsSet = new Set();

			monthAndYear.forEach((item, index) => {
				const [month, year] = item.split('/');
				if (!dataByYear[year]) {
					dataByYear[year] = Array(12).fill(null); // Initialize with 12 months
				}
				dataByYear[year][parseInt(month) - 1] = avgFWPerCover[index];
				monthsSet.add(month);
			});

			const months = [...monthsSet].sort((a, b) => parseInt(a) - parseInt(b)); // Sort month numbers
			const datasets = Object.keys(dataByYear)
				.sort()
				.map((year, index) => ({
					label: `Year ${year}`,
					data: dataByYear[year],
					fill: false,
					borderColor: lineColors[index % lineColors.length], // Cycle through colors
					backgroundColor: 'transparent',
				}));

			setChartData({
				labels: months,
				datasets,
			});
		};

		fetchData();
	}, [organization, theme.palette.primary.main, theme.palette.primary.light]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Food Recycled Per Cover By Month and Year',
			},
			datalabels: {
				display: true,
				color: 'black',
				formatter: (value) => value.toFixed(1), // Limit to 1 decimal place
			},
		},

		scales: {
			y: {
				title: {
					display: true,
					text: 'Avg Food Recycled Per Cover (Ounces)',
				},
				beginAtZero: true,
			},
			x: {
				title: {
					display: true,
					text: 'Month',
				},
			},
		},
	};

	return (
		<Stack
			sx={{
				height: '25vh',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Typography variant='h6' fontWeight='600' alignSelf='flex-start'>
				Food Recycled Per Cover By Month and Year
			</Typography>
			<Box sx={{ height: '100%', width: '100%' }}>
				<Line options={options} data={chartData} />
			</Box>
		</Stack>
	);
};

export default FoodWastePerCoverByMonthAndYear;
