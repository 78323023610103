import React from 'react';
import Utilization from '../AggregateDashboard/tabs/Compliance/components/Utilization';

const Playground = () => {
	return (
		<div>
			<Utilization orgID='6554f786a8c64cf4f5945863' />
		</div>
	);
};

export default Playground;
